import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';

@Component({
    selector: 'app-courses-grid-style-one-columns-four-page',
    templateUrl: './courses-grid-style-one-columns-four-page.component.html',
    styleUrls: ['./courses-grid-style-one-columns-four-page.component.scss']
})
export class CoursesGridStyleOneColumnsFourPageComponent implements OnInit {

    isToggled = false;
    activeContent: string = 'default'; // Default content on page load
    selectedTab = 'tab1'; // Default selected tab
    constructor(
        public themeService: ThemeCustomizerService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }
    setContent(content: string) {
        this.activeContent = content;
      }
      selectTab(tab: string) {
        this.selectedTab = tab;
    }
    ngOnInit(): void {}

}