import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';

@Component({
    selector: 'app-blog-details-page',
    templateUrl: './blog-details-page.component.html',
    styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

    isToggled = false; // Tracks the current theme toggle state
    selectedTab = 'tab1'; // Default selected tab

    constructor(
        public themeService: ThemeCustomizerService // Injects the ThemeCustomizerService
    ) {
        // Subscribes to the theme toggle state from the service
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    // Toggles the theme using the ThemeCustomizerService
    toggleTheme() {
        this.themeService.toggleTheme();
    }

    // Updates the selected tab when a tab is clicked
    selectTab(tab: string) {
        this.selectedTab = tab;
    }

    ngOnInit(): void {
        // Additional initialization logic (if needed) can be added here
    }
}
