<div class="custom-container">
<app-header-style-seven></app-header-style-seven>
<div class="top-banner">
    <div class="banner-image">
      <img src="assets/img/health-coaching/main-banner1.jpg" alt="About Us Banner">
    </div>
    <div class="banner-content">
      <h1 style="color: #001f3f; font-size: 18px; text-align: left;">தொட்டனைத் தூறும் மணற்கேணி மாந்தர்க்குக்<br>
        கற்றனைத் தூறும் அறிவு</h1>
      
    </div>
  </div>
  
 <!--aboutus section--> 
 <h2 class="management-header"> About Us</h2>
<section>

<div class="about-us-container">
   
    <div class="right-section">

      <div class="content">
        <p>
            The Legacy of Excellence

The foundation of St. Michael Educational and Charitable Trust was laid in 1993 by the visionary Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA). With a steadfast commitment to empowering rural communities, Dr. Michael dedicated his life to making quality education accessible to the underprivileged. His dream was to uplift society through technical and professional education, instilling values, creativity, and innovation in young minds. His relentless efforts and unwavering dedication transformed the educational landscape in Sivagangai and beyond, leaving an indelible mark on the field of education.<br>

Carrying forward this remarkable legacy, his son, Ln. Dr. M. Stalin Arockiaraj, Chairman of the St. Michael Group of Institutions, has expanded the vision into a dynamic reality. Since establishing Fatima Michael College of Engineering and Technology in 2009, Dr. Arockiaraj has fostered an environment of excellence, blending modern infrastructure with a student-centric approach. Under his leadership, the institution has become a hub of academic innovation, nurturing students to achieve their fullest potential and contribute meaningfully to society.<br>

Together, their combined vision and dedication have created a lasting legacy of educational empowerment, inspiring generations to dream big, achieve greatness, and make a positive impact on the world.
        </p>
      
      </div>
    </div>
    <div class="left-section">
        <img src="assets/img/motivation-course/motivation-mission.png" alt="About Us Image" />
        <p class="image-caption">Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA)</p>
      </div>
      
  </div>
</section> 
<!--Quality Policy-->
<section> <div class="quality-policy">
  <div class="quality-policy-header">
    <h2>Our Quality Policy</h2>
    <p>FMCET is committed to be a leader to impart quality education, training & research in Science, Engineering,Technology & Management with professional staff &  Infrastructure for developing skilled manpower to meet the growing needs of socio-economic progress of our nation.</p>
  </div>
  <div class="quality-policy-points">
    <div class="points-column">
      <div class="point">1. Providing world class Infrastructure facilities.</div>
      <div class="point">2. Equipping the facilities with vibrant learning forum.</div>
      <div class="point">3. Empowering youth with technical skills & leadership.</div>
    </div>
    <div class="points-column">
      <div class="point">4. Providing exuberant teaching & research ambience.</div>
      <div class="point">5. Networking with global institutions of excellence, industries & research organizations.</div>
      <div class="point">6. Engaging through outreach, community development and enterpreneurial initiatives.</div>
    </div>
  </div>
  <p>We shall ensure its continuing suitability by reviewing the quality policy periodically in every Management review meeting.</p>
</div>
</section>
<!--vision mission section-->
<h2 class="management-header"> Our Vision & Mission</h2>
<section>


  <div class="vision-mission-wrapper">
      <!-- Vision Box -->
      <div class="vision-box">
        <div class="vision-content">
          <h2>Vision</h2>
          <p style="text-align: justify;">
              To be one of the leading and preferred engineering colleges in education, research and ethics, and achieve greater recognition for our efforts to make the world look to us for technology and methodology for future trends and innovations.
          </p>
        </div>
        <span class="vision-icon">
          <img src="assets/img/vision-icon.png" alt="Vision Icon" />
        </span>
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <!-- Mission Box -->
      <div class="mission-box">
        <div class="mission-content">
          <h2>Mission</h2>
          <p style="text-align: justify;">
              To develop high-quality technical education and technocrats with a sound grip on basic engineering principles, technical, and managerial skills.<br>

              To innovate research capabilities, and exemplary professional conduct to lead and to use technology for the progress of humanity.<br>
              
              To adaptthemselves to changing technological conditions with the highest ethical values.
          </p>
        </div>
        <span class="mission-icon">
          <img src="assets/img/mission-icon.png" alt="Mission Icon" />
        </span>
      </div>
    </div>
 </section>
 <h2 class="management-header"> Our Management Team</h2>

<section>
    <div class="management-message-wrapper">
      <div class="message-row">
        <div class="message-card" *ngFor="let message of topMessages">
          <div class="message-card-content">
            <div class="message-photo">
              <img [src]="message.photo" alt="Message Photo">
              <p class="photo-caption">{{ message.caption }}</p> <!-- Photo Caption -->
            </div>
            <div class="message-text" style="text-align: justify;">
              <h3>{{ message.title }}</h3>
              <p>{{ message.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="management-header">Our Visionary Leaders
      </h2>
      <div class="message-row">
        <div class="message-card" *ngFor="let message of bottomMessages">
          <div class="message-card-content">
            <div class="message-photo">
              <img [src]="message.photo" alt="Message Photo">
              <p class="photo-caption">{{ message.caption }}</p> <!-- Photo Caption -->
            </div>
            <div class="message-text" style="text-align: justify;">
              <h3>{{ message.title }}</h3>
              <p>{{ message.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 

  
<!--<app-homethirteen-about></app-homethirteen-about>
<app-homeeight-about></app-homeeight-about>
<app-homefourteen-about></app-homefourteen-about>
<app-free-trial-form></app-free-trial-form>-->
</div>









