import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ThemeCustomizerService } from '../../../common/theme-customizer/theme-customizer.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-homenine-main-banner',
  templateUrl: './homenine-main-banner.component.html',
  styleUrls: ['./homenine-main-banner.component.scss']
})
export class HomenineMainBannerComponent implements OnInit, AfterViewInit {
  isToggled = false;
  currentEventIndex = 0;
  marqueeList: string[] = []; // For marquee data
  eventList: any[] = []; // For dynamic event data

  selectedEventImage: string | null = null;
  selectedEventDescription: string | null = null;

  // Owl Carousel Options for Main Banner
  gymHomeSlides: OwlOptions = {
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoHeight: true,
    autoplay: true,
    dots: true,
    loop: true,
    nav: true,
    items: 1,
    navText: [
      "<i class='flaticon-arrows'></i>",
      "<i class='flaticon-right-arrow'></i>"
    ]
  };

  apiUrlEvents: string = 'https://fmcet.ac.in/backend/getEvent.php'; // Event data API URL
  apiUrlMarquee: string = 'https://fmcet.ac.in/backend/getMarquee.php'; // Marquee data API URL

  constructor(private http: HttpClient, public themeService: ThemeCustomizerService) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit(): void {
    this.startEventRotation();
    this.loadMarqueeData();  // Fetch marquee data
    this.loadEventData();    // Fetch event data
  }

  ngAfterViewInit() {
    // No additional action needed here
  }

  startEventRotation() {
    setInterval(() => {
      this.currentEventIndex = (this.currentEventIndex + 1) % this.eventList.length;
    }, 2000); // Interval for event rotation
  }

  // Function to load Marquee data
  loadMarqueeData(): void {
    this.http.get<string[]>(this.apiUrlMarquee).subscribe({
      next: (data) => {
        this.marqueeList = data.length ? data : ['No marquee content available'];
      },
      error: (error) => {
        console.error('Error loading marquee:', error);
        this.marqueeList = ['Error loading marquee data.'];
      }
    });
  }

  // Function to load Events data
  loadEventData(): void {
    this.http.get<any[]>(this.apiUrlEvents).subscribe({
      next: (data) => {
        this.eventList = data.length ? data : [];
      },
      error: (error) => {
        console.error('Error loading events:', error);
        this.eventList = [];
      }
    });
  }

  // Show the popup with the event image and description when clicked
  showPopup(index: number): void {
    const event = this.eventList[index];
    this.selectedEventImage = event.event_image;
    this.selectedEventDescription = event.event_description || 'No description available';
  }

  // Close the popup
  closePopup(): void {
    this.selectedEventImage = null;
    this.selectedEventDescription = null;
  }
}
