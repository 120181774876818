<app-header-style-seven></app-header-style-seven>
<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                    <ul>
                
                    </ul>
                    <h2>Embedded System Technologies</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                    <ul>
                
                    </ul>
                    <h2>Embedded System Technologies</h2>
                </div>
            </div>
        </div>
        <div class="courses-details-desc pt-100 pb-70">
            <h3>About the Department</h3>
             <p>The program Embedded System Technologies provides students with the basis for studying the other courses of the modules “Embedded Microprocessor Systems”. It forms basic skills in embedded systems design. Those skills are usable in designing digital control units for Consumer Electronics, Industrial Automation, and Telecommunication Systems etc. This course includes lecture, laboratory works and individual project.</p>
        </div>
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                    <ul>
                
                    </ul>
                    <h2>Embedded System Technologies</h2>
                </div>
            </div>
        </div>
        <section 
 
>
    <div class="container pt-100 pb-70">

            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>The Department of Electrical and Elecrtonics Engineering is providing programs of the highest
                        quality to produce globally competent technocrats who can address challenges of the
                        millennium to achieve sustainable socio-economic development.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                        To provide quality education to the under graduate and post graduate students and make them expert in cutting edge technology in Computer science engineering.<br>
      
                        To share and apply knowledge in interdisciplinary areas that extends the scope of computer science.<br>
                       To promote research based innovative projects and activities in the emerging technology areas.<br>
                     
                          </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                    <ul>
                
                    </ul>
                    <h2>Embedded System Technologies</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>To provide students good foundation in mathematical, scientific,
                            engineering fundamentals and hardware-software programming intelligence.</li><br>
                        <li>To develop among students, the ability to develop embedded systems
                            based smart solutions for purpose of system automation</li><br>
                        <li>To promote student awareness, for life-long learning and introduce
                            them to professional ethics and code of practice.</li><br>
                        <li>To encourage students, to work in interdisciplinary groups.</li><br>
                    </ul>
                </div>
            </div>
        </section> 
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p></p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>An ability to independently carry out research/investigation and
                            development work to solve practical problems</li><br>
                        <li>An ability to write and present a substantial technical report/document.</li><br>
                        <li>Students should be able to demonstrate a degree of mastery over the area
                            as per the specialization of the program. The mastery should be at a level
                            higher than the requirements in the appropriate bachelor program</li><br>
                        <li>Be able to design and develop Embedded system automation based
                            on dedicated ICs that have computation, networking and control capacity.</li><br>
                        <li>Skill to work on professional software languages, standard modeling and
                            analysis tools &amp; commercial packages with communication protocols and
                            computation platforms for analysis and design of system automation.</li><br>
                        <li>To involve in research on an industrial problem or develop an innovative
                            smart system with automation as a consumer product through project
                            management and finance with due concerned for socio economic values</li><br>
                     <br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    </mat-tab-group>



