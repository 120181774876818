<div class="custom-container">
  <app-header-style-seven></app-header-style-seven>
  
  <h2 class="management-header"> DEPARTMENT OF MECHANICAL ENGINEERING  </h2>
  <div class="marquee-container">
    <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()" class="marq-one">
        <a style="color: #757474;" routerLink="/login" target="_blank">
            <span>Admissions open for - 2025 : B.E(CIVIL), B.E(CSE), B.E(CSE- AI & ML), B.E(CSE - CS), B.E(ECE), B.E(EEE), B.E(MECH), MBA, M.E(CSE), M.E(Embedded System Technologies), M.E(Thermal Engineering).</span>
        </a>
     
    </marquee>
  </div>
  <div class="container">
      
      <div class="tab-menu">
        <ul>
            <li [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">About the Department</li>
            <li [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">Vision & Mission</li>
            <li [class.active]="selectedTab === 'tab3'" (click)="selectTab('tab3')">PEO's</li>
            <li [class.active]="selectedTab === 'tab4'" (click)="selectTab('tab4')">POs & PSOs</li>
            <li [class.active]="selectedTab === 'tab14'" (click)="selectTab('tab14')">Programme Offered</li>
            <li [class.active]="selectedTab === 'tab5'" (click)="selectTab('tab5')">Laboratory Facilities
            <li [class.active]="selectedTab === 'tab6'" (click)="selectTab('tab6')">Association Activities</li>
            <li [class.active]="selectedTab === 'tab7'" (click)="selectTab('tab7')">MoU's</li>
            <li [class.active]="selectedTab === 'tab8'" (click)="selectTab('tab8')">Placement Record</li>
            <li [class.active]="selectedTab === 'tab9'" (click)="selectTab('tab9')">Industrial Visit</li> 
            <li [class.active]="selectedTab === 'tab11'" (click)="selectTab('tab11')">Value Added Courses</li>
           <!-- <li [class.active]="selectedTab === 'tab12'" (click)="selectTab('tab12')">Alumini</li>-->
            <li [class.active]="selectedTab === 'tab13'" (click)="selectTab('tab13')">Gallery</li>
            <li [class.active]="selectedTab === 'tab10'" (click)="selectTab('tab10')">Research Centre</li>
  
        </ul>
      </div>
      <div class="tab-content">
         
  
  
  <div *ngIf="selectedTab === 'tab1'">
    <div class="department-about">
      <!-- Full-width Image Box -->
      <div class="image-container">
        <img src="assets/img/page-title/page-title11.jpg"   srcset="high-res.jpg 2x, ultra-res.jpg 3x"  alt="Department Image">
      </div>
  
      <!-- Content Box -->
      <div class="text-container">
        <h1>About the Department</h1>
        <p>
          Department of Mechanical Engineering, at Fatima Michael College of Engineering and Technology, was established along with the institute in 2009 with the intake of 60 students. Post graduate Programme in Thermal Engineering was started with the intake of 18 students during the academic year 2011-2012. The department is affiliated to Anna University, Chennai and approved by AICTE, New Delhi. This department is one of the approved Research Centre from Anna University – Chennai. The department consistently maintains good academic records and produces quality Mechanical Engineers.
        </p>
      </div>
    </div>
  </div>
  
        <div *ngIf="selectedTab === 'tab2'">
          <div class="vision-mission-wrapper">
              <!-- Vision Box -->
              <div class="vision-box">
                <div class="vision-content">
                  <h2>Our Vision</h2>
                  <p style="text-align: justify;">
                    To provide world class engineering education and scientific research to build up mechanical engineers for globalized social development.
                  </p>
                </div>
                <span class="vision-icon">
                  <img src="assets/img/vision-icon.png" alt="Vision Icon" />
                </span>
              </div>
          
              <!-- Mission Box -->
              <div class="mission-box">
                <div class="mission-content">
                  <h2>Our Mission</h2>
                  <p style="text-align: justify;">
                    To impart quality technical education through eminent faculty to create efficient Mechanical Engineers.<br><br>
                    To promote effective collaboration with esteemed institutes / industries for technical knowledge transfer and research activities.<br><br>
                    To develop skills with recent technological movements and promote co and extra - curricular activities to enhance employability and entrepreneurship.<br><br>
                    To develop responsible citizenship through awareness and acceptance of ethical values.
                  </p>
                </div>
                <span class="mission-icon">
                  <img src="assets/img/mission-icon.png" alt="Mission Icon" />
                </span>
              </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'tab3'">
          <div class="peo-section">
            <h2>Program Educational Objectives (PEO)</h2>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 1</div>
                    <div class="peo-description">Effectuating success in careers by exploring with the design, digital and computational analysis of engineering systems, experimentation and teCsting, smart manufacturing, technical services, and research.
                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 2</div>
                    <div class="peo-description">Amalgamating effectively with stakeholders to update and improve their core competencies and abilities to ethically compete in the ever-changing multicultural global enterprise.
                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 3</div>
                    <div class="peo-description">To encourage multi-disciplinary research and development to foster advanced technology,and to nurture innovation and entrepreneurship in order to compete successfully in the global economy.
                    </div>
                </div>
            </div>
            <div class="peo-card">
              <div class="peo-card-item">
                  <div class="peo-no"> 4</div>
                  <div class="peo-description">To globally share and apply technical knowledge to create new opportunities that proactively advances our society through team efforts and to solve various challenging technical, environmental and societal problems
  
                  </div>
              </div>
          </div>
          <div class="peo-card">
            <div class="peo-card-item">
                <div class="peo-no"> 5</div>
                <div class="peo-description">To create world class mechanical engineers capable of practice engineering ethically with a solid vision to become great leaders in academia, industries and society.
  
                </div>
            </div>
        </div>
        </div>
        
        </div>
        <div *ngIf="selectedTab === 'tab4'">
          <div class="outcomes-section">
            <h2 class="section-title">Program Outcomes (POs)</h2>
        
            <div class="outcomes-list">
                <div class="outcome-item">
                    <h3 class="outcome-title">Engineering Knowledge</h3>
                    <p class="outcome-description">Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Problem Analysis</h3>
                    <p class="outcome-description">Identify, formulate, review research literature, and analyze complex Engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Design/Development of Solutions</h3>
                    <p class="outcome-description">Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and Environmental considerations.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Conduct Investigations of Complex Problems</h3>
                    <p class="outcome-description">Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Modern Tool Usage</h3>
                    <p class="outcome-description">Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</p>
                </div>
                <div class="outcome-item">
                  <h3 class="outcome-title">The engineer and society</h3>
                  <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                  </p>
              </div>
              <div class="outcome-item">
                <h3 class="outcome-title">The engineer and society</h3>
                <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                </p>
            </div>
            <div class="outcome-item">
              <h3 class="outcome-title">Environment and sustainability</h3>
              <p class="outcome-description">Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
  
              </p>
          </div>
          <div class="outcome-item">
            <h3 class="outcome-title">Ethics</h3>
            <p class="outcome-description">Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
  
            </p>
        </div>
        <div class="outcome-item">
          <h3 class="outcome-title">Individual and teamwork</h3>
          <p class="outcome-description">Function effectively as an individual, and as a member Or leader in diverse teams, and in multidisciplinary settings.
  
  
          </p>
      </div>
      <div class="outcome-item">
        <h3 class="outcome-title">Communication</h3>
        <p class="outcome-description">Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give And receive clear instructions.
  
  
        </p>
    </div>
    <div class="outcome-item">
      <h3 class="outcome-title">Project management and finance</h3>
      <p class="outcome-description">Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member And leader in a team, to manage projects and in multi disciplinary environments.
  
  
      </p>
  </div>
  <div class="outcome-item">
    <h3 class="outcome-title">Life-long learning</h3>
    <p class="outcome-description">Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
  
  
  
    </p>
  </div>
                <!-- Additional POs continue here -->
            </div>
        
            <h2 class="section-title">Program Specific Outcomes (PSOs)</h2>
        
            <div class="outcomes-list">
              <p>On successful completion of the Mechanical Engineering Degree programme, the Graduates shall exhibit the following:
  
              </p>
                <div class="outcome-item">
                
                    
                    <p class="outcome-description">Apply the knowledge gained in Mechanical Engineering for design and development and manufacture of engineering systems.
                    </p>
                </div>
                <div class="outcome-item">
                   
                    <p class="outcome-description">Apply the knowledge acquired to investigate research-oriented problems in mechanical engineering with due consideration for environmental and social impacts.
                    </p>
                </div>
                <div class="outcome-item">
                    
                    <p class="outcome-description">Use the engineering analysis and data management tools for effective management of multidisciplinary projects.
  
                    </p>
                </div>
            </div>
        </div>
        
       
        
        
        </div>
        <div *ngIf="selectedTab === 'tab5'">
          <div class="labs-container">
            <div class="lab">
              <img src="assets/img/mech/l1.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Manufacturing Technology Lab</h3>
                <p>Manufacturing Technology Laboratory is a well equipped 	laboratory which provides hands-on training in manufacturing  	processes.
                  </p>
                  <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Lathe Machines , Surface Grinding Machine,Cylindrical Grinding Machine,Centerless Grinding Machine,	Universal Drilling Machine,	Universal Milling Machine,	Shaper Machine,	Tool and Cutter Machine      </p>
                
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/mech/l2.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Strength of Materials Lab </h3>
            <p >Strength of materials lab is a place where students learn about the mechanical behaviour of materials under load, and perform experiments to measure their properties
              </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Universal Testing Machine , Shear Test Attachment,Torsion Machine,Rock Well Hardness,Brinell Hardness,Spring Machine,Pendulum Impact Machine     </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/mech/l3.png" alt="Lab 1 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Fluid mechanics and Machinery laboratory</h3>
                <p>The primary objective of the Fluid Machinery Lab is to provide students with hands-on experience and practical knowledge related to fluid machines' design, operation, and performance. and develop essential engineering skills.</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  Venturi meter test rig,
                  Orifice meter test rig,
                  Rotameter test rig,
                  Centrifugal Pump test rig,
                  Submersible pump test rig,
                  Reciprocating pump test rig,
                  Gear pump test rig.
      
      
                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/mech/l4.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>I.C. Engines laboratory</h3>
            <p>The IC Engine Lab enhances understanding of internal combustion engines, focusing on mechanics, performance, and emissions. It bridges theory and practice through experiments and research, fostering innovation for efficient, sustainable combustion technology</p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
              Four Stroke Diesel Engine-Cut section model,
  Two Stroke Petrol Engine Cut section model,
  Red Wood Viscometer,
  Flash and Fire point Apparatus (Closed cup Apparatus),
  4 stroke diesel Engine with Retardation test.
  
              
              
              </p>
              </div>
            </div>
            <!-- Add more labs as needed -->
            <div class="lab">
              <img src="assets/img/mech/l5.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Heat transfer laboratory</h3>
                <p>The objective of this lab is to understand the concepts of conduction, convection and radiation practically in various thermal systems to under graduate and Post graduate students through a series of experiments.   </p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  Thermal Conductivity apparatus (2 Slab guarded hot plate),
                  Stefan Boltzman apparatus,
                  Lagged pipe,
                  Heat transfer by forced convection apparatus,
                  Natural convection apparatus.
                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/mech/l6.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>  Metrology and Measurements laboratory</h3>
                <p>Metrology & Measurement Laboratory is a well equipped laboratory which provides ideas on hands on experience with various measuring instruments to utilize in industries.</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>Tool Maker‟s Microscope, 
                  Comparator ,
                 Sine Bar ,
                 Gear Tooth Vernier Caliper,
                 Floating gauge Micrometer.
                  
                  </p>
              </div>
            </div>
  
            <div class="lab">
              <img src="assets/img/mech/l7.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Mechatronics laboratory</h3>
            <p>Mechatronics Laboratory typically focuses on the integration of mechanical, electrical, computer, and control engineering systems. The objectives of such a laboratory generally aim to give students or researchers hands-on experience in designing, analyzing, and implementing mechatronic systems</p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Basic Electro Hydraulic Trainer Kit,
              Basic Electro Pneumatic Trainer Kit,
              Basic Electro Pneumatic Trainer Kit with PLC,
              Basic Pneumatic Trainer Kit,
              AC Servo Motor with PID Controller.
              </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/mech/l8.png" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>CAD/CAM laboratory</h3>
            <p >To gain practical experience in handling 2D drafting and 3D modeling software systems.     
              </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> CNC trainer milling machine , Creo/Elements pro ,A3 Plotter</p>
              </div>
            </div>
      
     
  
          
          
   
  
  
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab6'">
          <div class="tab6-table-container">
            <table class="tab6-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Seminar on Recent Advances in Industrial Design</td>
                  <td>19.09.24</td>
                  <td>As part of Engineers day celebration Seminar organized by department association
                    Resource Person – Mr,R.Gnaeshpandian, Asst.Manager, Product Development, Royal Enfield India Tech Centre, Chennai
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Paper presentation</td>
                  <td>19.09.24</td>
                  <td>As part of Engineers day celebration paper presentation event organized by department association
                    Participation – Third and Final year Mechanical engineering students
                    </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Career Opportunities for Mechanical Engineers</td>
                  <td>08.03.24</td>
                  <td>Career in mechanical engineering offers diverse opportunities to work in design, manufacturing and maintenance of mechanical systems.
                    Resource person - Mr.M.R.Hariharan, Senior Cad Engineer, Business Support, CADD CENTRE Training Services Pvt Ltd, Chennai
                    </td>
                </tr>
              
              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab7'">
          <div class="tab7-table-wrapper">
            <table class="tab7-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Organization</th>
                  <th>Date of Signing</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Focus Technologies
                    114 – A Vignesh Complex
                    PP Chavadi, Maduari.
                    </td>
                  <td>29.10.2021</td>
                  <td>1. To conduct Skill development course<br>
                    2. To Provide Internship Training 
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>MechTech Research Foundation
                    6/9, Kamaraja puram, 17TH street, Pudukootai
                    </td>
                  <td>06.03.2021</td>
                  <td>1. To conduct Value Added course <br>
                    2. To conduct Skill development course
                    </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Dimension Technologies
                    No. 4/6, Pandi Kovil Street
                    Maduari
                    </td>
                  <td>03.01.2020</td>
                  <td>1. To conduct Skill development course<br>
                    2. To Provide Internship Training & Indstrial Visit
                    
                    </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>MADITSSIA
                    1A-4A, Dr.Ambadkar raod
                    Maduari
                    
                    </td>
                  <td>27.02.2019</td>
                  <td>1. To conduct Skill development course<br>
                    2. To Provide Internship Training & Indstrial Visit
                    
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab8'">
    
          <div class="tab8-table-container">
            <table class="tab8-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Student Name</th>
                  <th>Company</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>KAVIMANI S</td>
                  <td>TVS Sundaram Motors-Fasteners Pvt Ltd </td>
                  <td>2025-01-10</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>RONAN CLEMENTS</td>
                  <td>TVS Sundaram Motors-Fasteners Pvt Ltd </td>
                  <td>2025-02-15</td>
                  
                </tr>
                <tr>
                  <td>3</td>
                  <td>THIYAGARAJAN M</td>
                  <td>TVS Sundaram Motors-Fasteners Pvt Ltd </td>
                  <td>2025-03-20</td>
               
                </tr>
                <tr>
                  <td>4</td>
                  <td>BALAGURUNATHAN  K</td>
                  <td>TVS Sundaram Motors-Fasteners Pvt Ltd </td>
                  <td>2025-03-20</td>
               
                </tr>
                <tr>
                  <td>5</td>
                  <td>DIPTANSHU SAHOO</td>
                  <td>TVS Sundaram Motors-Fasteners Pvt Ltd </td>
                  <td>2025-03-20</td>
               
                </tr>
         
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedTab === 'tab9'">
          <div class="industrial-visit-container">
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/mech/iv1.png" alt="Industrial Visit 1">
              </div>
              <div class="visit-content">
                <h3>Visit to ISRO Propulsion Complex, Mahendragiri </h3>
                <p>ISRO Propulsion Complex (IPRC), Mahendragiri is equipped with the state-of-the-art facilities necessary for realizing the cutting edge propulsion technology products for the Indian space programme

                  An Industrial Visit to ISRO is an inspiring and educational experience to the students. Students gained a deeper understanding of ISRO missions, and contributions to space exploration and national development. 
                  </p>
              </div>
            </div>
          
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/mech/iv2.png" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>Ponmalai Railway Workshop</h3>
                <p>The Ponmalai Railway Workshop, also known as the Golden Rock Railway Workshop, is a prominent facility of Indian Railways located in Tiruchirappalli (Trichy), Tamil Nadu. Established in 1928, it is part of the Southern Railway zone and specializes in:
                  Our students are Learned about
                  1.	Maintenance and Repairs: Overhauling and maintenance of broad-gauge diesel locomotives and wagons.
                  2.	Manufacturing: Production of components like wheels, axles, and parts for locomotives and rolling stock.
                  3.	Special Projects: Refurbishment and conversion of old coaches into upgraded variants, including bio-toilets and air-conditioned coaches.
                  </p>
              </div>
            </div>
                 
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/mech/iv3.png" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>Visit to CIPET, Maduari</h3>
                <p>CIPET : CSTS - Madurai is offering Technical Services in various disciplines of Design, Tooling, Processing, Testing & Inspection to the local Plastics Industries and playing a major role in the development of Plastic Industries in & around Tamil Nadu.

                  The visit to CIPET provides valuable insights into cutting edge technologies and practices driving the plastics petrochemical industries.
                  
                  </p>
              </div>
            </div>
                       
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/mech/iv4.png" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>Visit to Sun Pressing Private Ltd , Maduari</h3>
                <p>Sun Pressing Private Ltd , one of the pioneers at India Manufacturing automotive components. We manufacture metal components for automotive applications like Control Arms,oil seal shells, bushing, pads,flanges, grommets, buffers, and engine mounting and silent blocks For rubber compounded components, sub assemblies and fabricated item with modern technologies.

                  By the Visit to Sun pressing Industries, The students understanding the application of forged components in automotive, aerospace and industrial machinery.   
                  
                  
                  </p>
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab10'">
          <div class="supervisors-container">
            <h2>Anna University Recognized Supervisors</h2>
            <table class="supervisors-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Supervisor Reference No.</th>
                  <th>Supervisor Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Specialization</th>
                  <th>Contact Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2520033</td>
                  <td>Dr.P. Nelson Raja. BE, ME, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Principal & Professor</td>
                  <td>Industrial engineering, Quality engineering, Energy Engineering, Manufacturing Engineering.</td>
                  <td>9942982321</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>2820030</td>
                  <td>Dr.P. Paul Pandian, BE, M.Tech, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Professor</td>
                  <td>Flexible Manufacturing, scheduling, composite materials, natural fibers.</td>
                  <td>9843481546</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>3020029</td>
                  <td>Dr.B. Raja Mohammed Rabi, BE, M.E, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Professor</td>
                  <td>CAD.</td>
                  <td>9842792718</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="research-certificate-container">
            <h2 class="research-certificate-heading">Research Certificate</h2>
            <div class="research-certificate-content">
              <img src="assets/img/mech/cr.png" alt="Research Certificate" class="certificate-image">
              
            </div>
          </div>
          
          
          
          
        </div>
        <div *ngIf="selectedTab === 'tab11'">
          <div class="tab12-table-container">
            <table class="tab12-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>COURSE NAME</th>
                  <th>COURSE DETAILS</th>
                  <th>COMMENCE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Industrial Internet of Things (IIoT)</td>
                  <td>To understand the concepts of smart sensors and actuators to enhance manufacturing and industrial process.</td>
                  <td>05.02.2025</td>
                </tr>
         
             </tbody>
            </table>
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab12'">
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="alumni.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">John Doe</h2>
              <h4 class="alumni-batch">Batch of 2010</h4>
              <p class="alumni-description">
                John Doe is one of the most distinguished alumni of our institution. Currently serving as the CTO of a leading tech firm, John has been instrumental in driving innovations in artificial intelligence and machine learning. His contributions have not only brought accolades to the company but have also inspired countless individuals to pursue excellence in the tech industry.
              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab13'">
          <div class="single-department-gallery">
            <h2 class="department-title">Department of Mechanical Engineering</h2>
            <div class="gallery-grid">
              <div class="photo-item">
                <img src="assets/img/mech/gallery/1.jpg" alt="Lab 1">
                <div class="caption"> </div>
               
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/2.jpg" alt="Lab 2">
                <div class="caption"> </div>
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/3.jpg" alt="Lab 3">
                <div class="caption"> </div>
               
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/4.jpg" alt="Seminar Hall">
                <div class="caption"> </div>
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/5.jpg" alt="Classroom">
                <div class="caption"></div>
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/6.jpg" alt="Classroom">
                <div class="caption"></div>
                <!--<div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>-->
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab14'">
          <div class="programmes-offered">
            <h2>Programmes Offered</h2>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Programme Name</th>
                  <th>Duration</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>B.E. Mechanical Engineering</td>
                  <td>4 Years</td>
                 
                </tr>
                <tr>
                  <td>2</td>
                  <td>M.E. Thermal Engineering</td>
                  <td>2 Years</td>
                
                </tr>
                <tr>
                  <td>3</td>
                  <td>Ph.D</td>
                  <td>Part Time / Full Time</td>
                
                </tr>
              </tbody>
            </table>
          </div>
          
          
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-section">
       <h2>Contact</h2>
       
           <p>Head of Department, <br> Department of Mechanical Engineering,<br> Fatima Michael College of Engineering & Technology,<br> Madurai.  <br>Email: mechhod&#64;fmcet.ac.in</p>
     
      
      </div>
      <div class="right-section">
        <h3>Why Choose Mechanical Engineer</h3>
        <p>Choosing mechanical engineering means embarking on a journey filled with challenges and opportunities, where you can make a tangible impact on the world while building a successful and rewarding career</p>
        <a href="https://fmcet.ac.in/enquiry/enquiry.html" target="_blank" class="apply-button">Apply Now</a>
      </div>
    </div>
    
  </div>