<section
    class="feedback-area-two pt-100"
    [class.dark-feedback-area-two]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
       
        </div>
        <div class="feedback-slides">
            <owl-carousel-o [options]="studentsFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>I personally feel, getting placement is one of the toughest things for any engineering graduate. But for the students of our college, it is like a bed of roses. The college gave a platform to build my personality, team work, leadership skills and can grow for higher heights. The placement cell has expanded my career possibilities by giving me the knowledge towards a focused career path based on specialized skills.</p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user4.jpg" alt="image">
                                <div class="title">
                                    <h3>K. Nivetha Jessintha</h3>
                                    <span>Infosys, Mysore</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>The college regularly organizes workshops and competitions to help students learn new skills. Faculty members are very supportive and take great initiative to inculcate knowledge and skills in students. Our college provides facilities like free Wi-Fi , library, labs with all modern equipment. The college also has an EDC (Entrepreneurship Development Cell) for the students aspiring to start their businesses.</p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user5.jpg" alt="image">
                                <div class="title">
                                    <h3>M. Saravana kumar</h3>
                                    <span>Sr. System Analyst, Deloitte, Hyderabad</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>When I was studying in FMCET, I have witnessed a tremendous change in my life both in academic and personal level. The campus, surrounded by beautiful greenery provided an ideal platform for growth and improvement. In a short span of time, the college has already produced exceptional results and continues to improve the quality of education.</p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user6.jpg" alt="image">
                                <div class="title">
                                    <h3>K. Ajith kumar</h3>
                                    <span>Software Developer, Lowe's India, Bangalore</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="feedback-image">
        <img src="assets/img/feedback-img.png" alt="image">
    </div>
</section>