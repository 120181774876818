import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';

@Component({
    selector: 'app-about-page-one',
    templateUrl: './about-page-one.component.html',
    styleUrls: ['./about-page-one.component.scss']
})
export class AboutPageOneComponent implements OnInit {
    isToggled = false;
    activeContent: string = 'default'; // Default content on page load
    selectedImage: string | null = null; // For modal image
    selectedCaption: string | null = null; // For modal caption
    isModalVisible: boolean = false; // Modal visibility state
    selectedTab = 'tab1'; // Default selected tab
    constructor(public themeService: ThemeCustomizerService) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    setContent(content: string) {
        this.activeContent = content;
    }
    selectTab(tab: string) {
        this.selectedTab = tab;
    }
    

    // Show modal with selected image and caption
    openModal(imageSrc: string, caption: string): void {
        this.selectedImage = imageSrc;
        this.selectedCaption = caption;
        this.isModalVisible = true;
    }

    // Close modal
    closeModal(): void {
        this.isModalVisible = false;
        this.selectedImage = null;
        this.selectedCaption = null;
    }

    ngOnInit(): void {}
}
