<div class="health-coaching-bg-image">
    <div class="col-lg-12 " >
      <div class="event-marquee">
        <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()">
          <span *ngFor="let item of marqueeList; let i = index">
            <ng-container *ngIf="i === 1; else normalText">
              <!-- Add the hyperlink to the second item -->
              <a href="https://www.youtube.com/watch?v=_yXQpi2juuY" target="_blank">
                {{ item }}
              </a>
            </ng-container>
            <ng-template #normalText>
              <!-- Normal text for other items -->
              {{ item }} &nbsp;&nbsp;|&nbsp;&nbsp;
            </ng-template>
          </span>
        </marquee>
      </div>
      
    </div>
    <!-- Main Banner Area -->
    <section>
        <div class="gym-home-slides">
            <owl-carousel-o [options]="gymHomeSlides">
                <!-- First Carousel Slide -->
                <ng-template carouselSlide>

                    <div class="gym-main-banner twinkle  bg-1">
                        <div class="container ">
                            <div class="gym-banner-content ">
                                
                                <h1 style="color: white ; font-size: 20px;"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>“FMCET achieves Accreditation by NAAC with A+ Grade! <br>
                                    A Proud Milestone in Our Journey of Excellence in Education.”</h1>
                                
                                
                            </div>
                        </div>
                        <!-- Left Logo Container -->
                        <div class="logo-container left-container">
                            <img src="assets/img/15yearst.png" alt="Logo 1">                  
                            <img src="assets/img/iict.png" alt="Logo 2">
                        </div>
                        <!-- Right Logo Container -->
                        <div class="logo-container right-container">
                            <img src="assets/img/aplus.png" alt="Logo 3">
                            <img src="assets/img/qrot.png" alt="Logo 4">
                        </div>
                    </div>
                </ng-template>
    
                <!-- Second Carousel Slide -->
                <ng-template carouselSlide>
                    <div class="gym-main-banner bg-2">
                        <div class="container">
                            <div class="gym-banner-content">
                                
                                <h1>Admission Open - 2025</h1>
                                
                                <a routerLink="/programme" class="default-btn"><span class="label">View Courses</span></a>
                            </div>
                        </div>
                        <!-- Left Logo Container -->
                        <div class="logo-container left-container">
                            <div class="twinkle"> <img src="assets/img/15yearst.png" alt="Logo 1"></div>
                            <img src="assets/img/iict.png" alt="Logo 2">
                        </div>
                        <!-- Right Logo Container -->
                        <div class="logo-container right-container">
                            <div class="twinkle"> <img src="assets/img/aplus.png" alt="Logo 3"></div> <!-- Add star overlay -->
                            <img src="assets/img/qrot.png" alt="Logo 4">
                        </div>
                    </div>
                </ng-template>
    
                <!-- Third Carousel Slide -->
                <ng-template carouselSlide>
                    <div class="container">
                        <div class="gym-banner-content">
                            
                            <h1 style="color: white;"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>10th Graduation Day</h1>
                            
                            <a href="https://fmcet.ac.in/enquiry/enquiry.html" target="_blank" class="default-btn"><span class="label">Admission Enquiry</span></a>
                        </div>
                    </div>
                    <div class="gym-main-banner bg-3">
                        <!-- Left Logo Container -->
                        <div class="logo-container left-container">
                            <div class="twinkle"> <img src="assets/img/15yearst.png" alt="Logo 1"></div>
                            <img src="assets/img/iict.png" alt="Logo 2">
                        </div>
                        <!-- Right Logo Container -->
                        <div class="logo-container right-container">
                            <div class="twinkle"> <img src="assets/img/aplus.png" alt="Logo 3"></div> <!-- Add star overlay -->
                            <img src="assets/img/qrot.png" alt="Logo 4">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </section>
    
    
    

    <!-- Featured Area -->
    <header class="main-header">
        <h1 style="font-size: 22px">WELCOME TO FATIMA MICHAEL COLLEGE OF ENGINEERING & TECHNOLOGY</h1>
    </header>

    <section class="main-layout">
        <div class="row-layout">
            <!-- Left Container (Welcome Section) -->
            <div class="left-container">
                <div class="welcome-section">
                    <h2>About FMCET</h2>
                    <p style="font-size: 14px ">
                        "Step Into a World of Possibilities at Fatima Michael College of Engineering & Technology, Madurai!"
                        <br><br>At FMCET, learning isn’t just about books—it’s about exploring, discovering, and growing in a place that inspires greatness.
                        Founded in 2009 by Ln. Dr. M. Stalin Arockiaraj, Chairman of St. Michael Group of Institutions, our mission is clear: to provide top-quality education in a modern, supportive environment.
                        With cutting-edge facilities and a passion for excellence, we shape students into creative thinkers and smart problem-solvers who make a difference in the world.<br><br>
                        From experienced faculty and vibrant libraries to advanced labs and spacious playgrounds, every corner of FMCET is designed to make learning exciting and unforgettable. 
                    </p>
                </div>
         <br><br>
<!-- Event Rotation -->
<div class="upcoming-events">
  <h2>Upcoming Events & Announcements</h2>
  <div *ngFor="let event of eventList; let i = index" [ngClass]="{ 'blinking': i === currentEventIndex }">
    <p (click)="showPopup(i)">{{ event.event_date }} - {{ event.event_title }}</p>
  </div>
</div>

  
  <!-- Popup Modal -->
  <div *ngIf="selectedEventImage" class="popup-overlay">
    <div class="popup-content">
      <button class="close-btn" (click)="closePopup()">✖</button>
      <img [src]="selectedEventImage" alt="Event Image">
      <p>{{ selectedEventDescription }}</p>
    </div>
  </div>
  
          
         
            </div>
    
            <!-- Right Container (Courses Section) -->
            <div class="right-container">
                <div class="courses-section ug-courses">
                    <h2>UG Courses</h2>
                    <ul>
                        <li routerLink="/civil" class="course-item">B.E.  Civil Engineering</li>
                        <li routerLink="/cse" class="course-item">B.E.  Computer Science & Engineering</li>
                        <li routerLink="/ai" class="course-item">B.E.  CSE (Artificial Intelligence & Machine Learning)<li>
                        <li routerLink="/cs" class="course-item">B.E.  CSE (Cyber Security) </li>
                        <li routerLink="/ece" class="course-item">B.E.  Electronics & Communication Engineering </li>
                        <li routerLink="/eee" class="course-item">B.E.  Electrical & Electronics Engineering </li>
                        <li routerLink="/mech" class="course-item">B.E.  Mechanical Engineering </li>
            
                      
                    </ul>
                </div>
                
                <!-- PG Courses -->
                <div class="courses-section pg-courses">
                    <h2>PG Courses</h2>
                    <ul>
                        <li  class="course-item">M.B.A  Masters of Business Administration</li>
                        <li   class="course-item">M.E.  Computer Science & Engineering</li>
                        <li   class="course-item">M.E. Embedded System Technologies</li>
                        <li   class="course-item">M.E. Thermal Engineering</li>
            
                    </ul>
                </div>
       
         
            </div>
        </div>
    
   </section>

<section> <div class="quality-policy">
    <div class="quality-policy-header">
      <h2>Our Quality Policy</h2>
      <p>FMCET is committed to be a leader to impart quality education, training & research in Science, Engineering,Technology & Management with professional staff &  Infrastructure for developing skilled manpower to meet the growing needs of socio-economic progress of our nation.</p>
    </div>
    <div class="quality-policy-points">
      <div class="points-column">
        <div class="point">1. Providing world class Infrastructure facilities.</div>
        <div class="point">2. Equipping the facilities with vibrant learning forum.</div>
        <div class="point">3. Empowering youth with technical skills & leadership.</div>
      </div>
      <div class="points-column">
        <div class="point">4. Providing exuberant teaching & research ambience.</div>
        <div class="point">5. Networking with global institutions of excellence, industries & research organizations.</div>
        <div class="point">6. Engaging through outreach, community development and enterpreneurial initiatives.</div>
      </div>
    </div>
    <p>We shall ensure its continuing suitability by reviewing the quality policy periodically in every Management review meeting.</p>
  </div>
  </section>
<section>
    <div class="vision-mission-wrapper">
        <!-- Vision Box -->
        <div class="vision-box">
          <div class="vision-content">
            <h2>Vision</h2>
            <p style="text-align: justify;">
                To be one of the leading and preferred engineering colleges in education, research and ethics, and achieve greater recognition for our efforts to make the world look to us for technology and methodology for future trends and innovations.
            </p>
          </div>
          <span class="vision-icon">
            <img src="assets/img/vision-icon.png" alt="Vision Icon" />
          </span>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- Mission Box -->
        <div class="mission-box">
          <div class="mission-content">
            <h2>Mission</h2>
            <p style="text-align: justify;">
                To develop high-quality technical education and technocrats with a sound grip on basic engineering principles, technical, and managerial skills.<br>

                To innovate research capabilities, and exemplary professional conduct to lead and to use technology for the progress of humanity.<br>
                
                To adaptthemselves to changing technological conditions with the highest ethical values.
            </p>
          </div>
          <span class="mission-icon">
            <img src="assets/img/mission-icon.png" alt="Mission Icon" />
          </span>
        </div>
      </div>
   </section>
<section>
    <!--<div class="events-section">
        <div class="events-header">
          <h2>Latest Events</h2>
        </div>
        <div class="events-container">
          <div class="event-box">
            <img src="assets/img/event1.jpg" alt="Event 1" class="event-image" />
            <p class="event-footnote">2025 - New Year Celebration</p>
          </div>
          <div class="event-box">
            <img src="assets/img/event2.jpg" alt="Event 2" class="event-image" />
            <p class="event-footnote">03/01/2025 : Awareness about Incubation Center</p>
          </div>
          <div class="event-box">
            <img src="event3.jpg" alt="Event 3" class="event-image" />
            <p class="event-footnote">Event 3 Description</p>
          </div>
       
        </div>
      </div>-->
       <!-- UG Courses -->

      
 </section>
            

           
                 
                
  
 
    

