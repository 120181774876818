<div class="custom-container">
<app-header-style-seven></app-header-style-seven>
<div class="page-title-area item-bg1">
    <!-- Start Popup -->
    <div *ngIf="showStartPopup" id="startPopup" class="popup">
      <h2 style="color:wheat">Shall We Play!</h2>
      <h2 style="color: wheat;">Catch Your Course</h2>
      <button id="startButton" (click)="startMatrix()">Start</button>
    </div>
  
    <!-- End Popup -->
    <div *ngIf="showEndPopup" id="endPopup" class="popup">
      <h2 style="color: wheat;">Congrats! You Selected {{ selectedWord }}</h2>
      <button (click)="restartGame()">Restart</button>
    </div>
  
    <!-- Matrix Canvas -->
    <canvas id="matrixCanvas"></canvas>
  </div>
  
  
  
<div class="container " >

        <div class="row" >
            <div class="col-lg-8 col-md-12">
                <div class="row" >
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30" >
                            <br><br>
                            <h1 class="widget-title" style="color: #FF6F61;">  Courses Offered</h1>
                            <br>
                            <div class="user-actions">
                                
                                <h1 style="color: #001F3F;">Under Graduate Courses </h1>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col"style="color: #FF6F61;">S.NO</th>
                                            <th scope="col"style="color: #FF6F61;">Programme Name</th>
                                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr>
                                            <td class="product-name">
                                                <a >1.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/civil">B.E. Civil Engineering</a>
                                            </td>
                                        
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >2.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/cse">B.E. Computer Science & Engineering</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >3.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/ai">B.E. Computer Science & Engineering (AI & ML)</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >4.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/cs">B.E. Computer Science & Engineering (CS)</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >5.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/ece">B.E. Electronics & Communication Engineering</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >6.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/eee">B.E. Electrical & Electronics Engineering</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          <tr>
                                            <td class="product-name">
                                                <a >7.</a>
                                            </td>
                                            <td class="product-name">
                                                <a routerLink="/mech">B.E Mechanical Engineering</a>
                                            </td>
                                          
                                            
                                          </tr>
                                          </tbody>
                                          </table>
                                    
                                </div> 
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="user-actions">
                            <br><br>
                            <h1 style="color: #001F3F;">Post Graduate Courses </h1>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col"style="color: #FF6F61;">S.NO</th>
                                        <th scope="col"style="color: #FF6F61;">Programme Name</th>
                                     
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">MBA ( Master of Business Administration)</a>
                                           
                                        </td>
                                    
                                        
                                      </tr>
                                      <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.E. Computer Science & Engineering</a>
                                        </td>
                                      
                                        
                                      </tr>
                                      <tr>
                                        <td class="product-name">
                                            <a >3.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.E. Embedded System & Technolgoies</a>
                                        </td>
                                      
                                        
                                      </tr>
                                      <tr>
                                        <td class="product-name">
                                            <a >4.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.E. Thermal Engineering</a>
                                        </td>
                                      
                                        
                                      </tr>
                          
                          
                            
                                      </tbody>
                                      </table>
                                
                            </div> 
                        </div>
                    </div>
          
         
             
          
   
                </div>
            </div>
            <div class="col-lg-4 col-md-12" >
                <aside class="widget-area" >
                    <div class="widget widget_ednuv_posts_thumb">
                        <br><br>
                       
                    </div>
       
          
                 <!--   <div class="widget widget_instagram">
                        <h3 class="widget-title" style="color: #001F3F;" >Admission Brochures</h3>
                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog1.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog2.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog3.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog4.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog5.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog6.jpg" alt="image"></a></li>
                        </ul>
                    </div>-->
                    <div class="widget ">
                        <h3 class="widget-title" style="color: #001F3F;" >Admission Co-ordinators</h3> 
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col"style="color: #FF6F61;">S.NO</th>
                                    <th scope="col"style="color: #FF6F61;">Name</th>
                                    <th scope="col"style="color: #FF6F61;">Phone Number</th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td class="product-name">
                                        <a >1.</a>
                                    </td>
                                    <td class="product-name">
                                        <a >Dr. S. Saravana Kumar</a>
                                    </td>
                                    <td class="product-name">
                                        <a >9025024968</a>
                                    </td>
                                
                                    
                                  </tr>
                                  <tr>
                                    <td class="product-name">
                                        <a >2.</a>
                                    </td>
                                    <td class="product-name">
                                        <a >Mr. Jeyakumar</a>
                                    </td>
                                    <td class="product-name">
                                        <a>8122338567</a>
                                    </td>
                                  
                                    
                                  </tr>
                                  <tr>
                                    <td class="product-name">
                                        <a >3.</a>
                                    </td>
                                    <td class="product-name">
                                        <a >Mrs. Jebani</a>
                                    </td>
                                    <td class="product-name">
                                        <a >8220980318</a>
                                    </td>
                                    
                                  </tr>
                      
                      
                      
                        
                                  </tbody>
                                  </table>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <div class="text">
                            <a target="_blank" href="https://fmcet.ac.in/enquiry/enquiry.html"  ><button style="color:wheat" style="background-color: #001F3F;" type="submit" class="btn btn-primary">Apply Now</button></a>
                                                                              </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>

</div>