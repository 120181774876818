<div class="custom-container">
<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            
        </div>
    </div>
</div>

<div
    class="contact-info-area pt-100 pb-70"
    [class.dark-contact-info-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <a href="">principal&#64;fmcet.ac.in</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                 
                    <h3>Location Here</h3>
                    <p><a href="https://goo.gl/maps/vpK16J7zFHHrzPJR8" target="_blank">Madurai Sivagangai Main Road, 
                        Madurai - 625 020.
                        Tamil Nadu
                        India.</a></p>
                        <h3>Bus Routes</h3>
                        <p> Mattuthavani <br> Bus Terminal to Poovanthi  </p>
                        <p>  Periyar  <br> Bus Terminal to Poovanthi  </p>
                        <p> Sivagangai <br> Bus Terminal to Poovanthi  </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Admission Enquiry</h3>
                    <p><a href="tel:91 98421 44567">+91 99429 82311</a></p>
                    <p><a href="tel:91 98421 44567">+91 99429 82321</a></p>
                    <h3>Online Exam Enquiry </h3>
                    <p><a href="tel:91 98421 44567">+91 97866 56660</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="contact-area pb-100"
    [class.dark-contact-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us Message for any Query</h2>
            
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      required
                      minlength="3"
                      maxlength="50"
                      ngModel
                      name="name"
                      type="text"
                      #name="ngModel"
                      class="form-control"
                      id="name"
                      placeholder="Your name"
                    />
                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                      <div *ngIf="name.errors.required">Name is required.</div>
                      <div *ngIf="name.errors.minlength">
                        Name must be at least {{ name.errors.minlength.requiredLength }} characters.
                      </div>
                      <div *ngIf="name.errors.maxlength">
                        Name cannot exceed 50 characters.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      required
                      email
                      ngModel
                      name="email"
                      type="email"
                      #email="ngModel"
                      class="form-control"
                      id="email"
                      placeholder="Your email address"
                    />
                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">
                      <div *ngIf="email.errors.required">Email is required.</div>
                      <div *ngIf="email.errors.email">Please enter a valid email address.</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      required
                      pattern="^[0-9]{10}$"
                      ngModel
                      name="number"
                      type="text"
                      #number="ngModel"
                      class="form-control"
                      id="number"
                      placeholder="Your phone number"
                    />
                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">
                      <div *ngIf="number.errors.required">Phone number is required.</div>
                      <div *ngIf="number.errors.pattern">Please enter a valid 10-digit phone number.</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      required
                      ngModel
                      name="subject"
                      type="text"
                      #subject="ngModel"
                      class="form-control"
                      id="subject"
                      placeholder="Your subject"
                    />
                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">
                      Subject is required.
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      required
                      ngModel
                      #message="ngModel"
                      name="message"
                      id="message"
                      cols="30"
                      rows="5"
                      class="form-control"
                      placeholder="Your message..."
                    ></textarea>
                    <div class="alert alert-danger" *ngIf="message.touched && !message.valid">
                      Message is required.
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
                        <div class="form-group">
                          <!-- Form Fields for name, email, etc. go here -->
                        </div>
                      
                        <button
                          type="submit"
                          class="default-btn"
                          [class.disabled]="!contactForm.valid"
                          [disabled]="!contactForm.valid"
                        >
                          <i class="bx bx-paper-plane icon-arrow before"></i>
                          <span class="label">Send Message</span>
                          <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>
                      </form>
                      
                </div>
              </div>
            </form>
          </div>
          
    </div>
    <div class="contact-bg-image">
        <img src="assets/img/map.png" alt="image">
    </div>
</div>
<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15703889.85866086!2d67.755781087162!3d16.067695853589544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c2b4dd324e27%3A0x3cb4022e24382992!2sFatima%20Michael%20College%20of%20Engineering%20%26%20Technology!5e0!3m2!1sen!2sin!4v1691426272519!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
</div>