import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-six',
  templateUrl: './home-page-six.component.html',
  styleUrls: ['./home-page-six.component.scss']
})
export class HomePageSixComponent implements OnInit {
  selectedTab = 'tab1'; // Default selected tab
  activeContent: string = 'default'; // Default content on page load
  constructor() { }

  ngOnInit(): void {
  }

  setContent(content: string) {
    this.activeContent = content;
  }
  selectTab(tab: string) {
    this.selectedTab = tab;
}
}
