<section
    class="distance-learning-area bg-C0C0C0 pt-100"
    [class.dark-distance-learning-area]="themeService.isDark()"
>
    <div class="container">
        <div class="divider bg-C0C0C0"></div>
        <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-6">
                <div class="services-box">
                    <div>
                        <i ></i>
                    </div>
                    <h3>Vision</h3>
                    <p>To be one of the leading and preferred engineering colleges in education, research and ethics, and achieve greater recognition for our efforts to make the world look to us for technology and methodology for future trends and innovations.</p>
                </div>
            </div>
            <div class="col-lg-12 col-md-6 col-sm-6">
                <div class="services-box">
                    <div>
                        <i ></i>
                    </div>
                    <h3>Mission</h3>
                    <p>To develop high-quality technical education and technocrats with a sound grip on basic engineering principles, technical, and managerial skills.</p>

                       <p>To innovate research capabilities, and exemplary   professional conduct to lead and to use technology for the progress of humanity.</p> 
                        
                       <p>To adaptthemselves to changing technological conditions with the highest ethical values.
                        </p>
                </div>
            </div>
           
          </div>
    </div>
   
</section>