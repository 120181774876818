import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  isToggled = false;
  activeContent: string = 'default'; // Default content on page load
  selectedTab = 'tab1'; // Default selected tab
  constructor(public themeService: ThemeCustomizerService) {
    // Subscribe to theme toggle changes
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  toggleTheme() {
    // Toggle between light and dark theme
    this.themeService.toggleTheme();
  }

  setContent(content: string) {
    this.activeContent = content;
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
}
  ngOnInit(): void {
    // Any additional initialization logic
  }
}
