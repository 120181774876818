<section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container-fluid">
        
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 section-title">
                <h2 style=" color:#FF6F61; font-size: 22px;">Placement Record</h2>
              
                    <span>FMCET takes great pride in its impressive placement record, with a strong track record of placing students in top companies across various industries. Through dedicated training, workshops, and industry collaborations, our students are equipped with the skills and knowledge required to succeed in their careers. Over the years, we have achieved outstanding placement results, with many of our graduates securing positions in reputed multinational corporations, startups, and government organizations.</span>
                </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2018-2019</h3>
                   <p>59.87% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2019-2020</h3>
                    <p>72.86% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2020-2021</h3>
                    <p>84.07% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2021-2022</h3>
                    <p>72.53% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2022-2023</h3>
                    <p>76.47% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>Last Five Years Record</h3>
                    <p>72.837% of Students Placed</p>
                </div>
            </div>
    
        </div>
    </div>
    <div class="divider bg-e4feff"></div>
</section>