import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})


export class ContactPageComponent implements OnInit {

  isToggled = false;
  
  constructor(
    public themeService: ThemeCustomizerService,
    private http: HttpClient 
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {}

  submit(form) {
    const { name, email, number, subject, message } = form;
    const formData = { name, email, number, subject, message };

    this.sendEmail(formData).subscribe(
      (response) => {
        console.log('Email sent successfully', response);
        alert('Message sent successfully!');
      },
      (error) => {
        console.error('Error sending email', error);
        alert('There was an error sending your message. Please try again.');
      }
    );
  }

  sendEmail(formData: any): Observable<any> {
    const backendUrl = 'http://localhost:3000/send-email';
    return this.http.post(backendUrl, formData).pipe(
      catchError((error) => {
        console.error('Error sending email:', error);
        return throwError(error);
      })
    );
  }
}
