<!--<div
    class="motivation-mission-area"
    [class.dark-motivation-mission-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-content">
                    <h4><span class="sub-title">Management Profile</span></h4>
                   <p>Taking education to the marginalized classes of society across the state, especially in Sivagangai district and with an intuition to instill world class quality education, the man of stupendous efforts our founder Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA) has established "ST. MICHAEL EDUCATIONAL AND CHARITABLE TRUST" in the year 1993.</p>
                  <ul>
                    <li>1993 - St. Michael Educational and Charitable Trust.</li><br>
                    <li>1998 - St. Michael College of Engineering & Technology, Kalayarkovil.</li><br>
                    <li>2004 - St. Michael Matriculation School, Sivagangai.</li><br>
                    <li>2005 - Roseline College of Education (B.Ed.,), Sivagangai.</li><br>
                    <li>2006 - St. Michael Matriculation School, Madurai.</li><br>
                    <li>2008 - St. Michael Polytechnic College, Kalayarkovil.</li><br>
                    <li>2009 - Fatima Michael College of Engineering & Technology, Madurai.</li><br>
                    <li>2015 - St.Michael Matriculation School,Kalayarkovil.</li><br>

                  </ul>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-image"></div>
            </div>
        </div>
    </div>
    
</div>-->

<div class="growth-container">
    <h1>STUDENTS PATH IN FMCET</h1>
    <div class="growth-steps">

        <div class="step">
            <div class="step-year">1st Yr</div>
            <div class="step-content">
                <h3>"Student Entry"</h3>
                <p>"Welcome to our college! Here, students undergo an orientation program to familiarize themselves with the campus, facilities, and curriculum. They also meet their mentors."</p><br>
                <h3> "Foundation Learning"</h3>
                <p>"During the first year, students focus on building a strong foundation in their respective courses with basic subjects and interactive workshops."</p>
                <P>Services: Foundation courses, Workshops, Seminars, Personality Development Programs.</P>
            </div>
        </div>
        <div class="step">
            <div class="step-year">2nd Yr</div>
            <div class="step-content">
                <h3>"Advanced Learning"</h3>
                <p>"In the second year, students dive into advanced subjects related to their field. Lab sessions and projects are a key part of the learning process."</p>
                <P>Services: Advanced technical courses, Industry exposure through internships, Club and societies participation.
                </P>
            </div>
        </div>
        <div class="step">
            <div class="step-year">3rd Yr</div>
            <div class="step-content">
                <h3>"Internship & Industry Exposure"</h3>
                <p>"Third-year students are encouraged to undertake internships with industry partners, gaining valuable real-world experience and professional networking."</p>
                <P>Services: Internship programs, Industrial visits, Skill-building workshops.</P>
            </div>
        </div>
        <div class="step">
            <div class="step-year">4th Yr</div>
            <div class="step-content">
                <h3> "Pre-Placement Training"</h3>
                <p> "The final year focuses on preparing students for the job market, with dedicated pre-placement training, mock interviews, resume-building sessions, and communication skill workshops."</p>
                <P>Services: Pre-placement training, Mock interviews, Resume building, Guest lectures by industry professionals.</P>
            </div>
        </div>
        <div class="step">
            <div class="step-year">4th Yr</div>
            <div class="step-content">
                <h3> "Graduation & Degree Awarded"</h3>
                <p>  "Upon successful completion of the course, students are awarded their degree. This is followed by the graduation ceremony and celebrations."</p>
                <P>Services: Degree Award Ceremony, Alumni network initiation, Career counseling for higher studies.</P>
            </div>
        </div>
        <div class="step">
            <div class="step-year">4th Yr</div>
            <div class="step-content">
                <h3>"Placement"</h3>
                <p> "Students are then ready to step into the workforce. Placement cells connect students with top companies through campus recruitment drives and job fairs."</p>
                <P>Services: Campus recruitment, Job placement, Career counseling, Alumni connections.</P>
            </div>
        </div>
    </div>
</div>