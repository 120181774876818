<div class="custom-container">
    <app-header-style-seven></app-header-style-seven>



<!--<section class="courses-area ptb-100">
     <div class="user-actions">
        <i class='bx bx-log-in'></i>
        <span>Undergraduate Programme  </span>
        
    </div>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/civil" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                 
                  <p><a routerLink="/civil" class="d-inline-block">B.E Civil Engineering</a></p>
                      
                    </div>


                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/cse" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
               
                        <p><a routerLink="/cse" class="d-inline-block">B.E Computer Science and Engineering</a></p>
                        
                    </div>
              
                
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/ece" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                    
                       <p> <a routerLink="/ece" class="d-inline-block">B.E Electronics and Communication Engineering</a></p>
                        
                    </div>
              
                   
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/eee" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                    
                       <p><a routerLink="/eee" class="d-inline-block">B.E Electrical and Electronics Engineering</a></p>
                        
                    </div>

           
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/ai" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
       
                     <p>   <a routerLink="/ai" class="d-inline-block">B.E. Computer Science and Engineering (Artificial Intelligence & Machine Learning)</a></p>
                        
                    </div>

             
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/cs" class="d-block"><img src="assets/img/courses/courses7.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
      
                        <p><a routerLink="/cs" class="d-inline-block">B.E. Computer Science and Engineering (Cyber Security)</a></p>
                      
                    </div>

              
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/mech" class="d-block"><img src="assets/img/courses/courses8.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
          
                        <p><a routerLink="/mech" class="d-inline-block">B.E Mechanical Engineering</a></p>
                        
                    </div>

                </div>
            </div>
         
         
        </div>
        
    </div>
    
    
</section>
<div class="user-actions">
    <i class='bx bx-log-in'></i>
    <span>Postgraduate Programme  </span>
    
</div>
<section >
    
   <div class="container-fluid">
       <div class="row">

        <div class="col-lg-3 col-md-6">
            <div class="single-courses-item mb-30">
                <div class="courses-image">
                    <a routerLink="/mba" class="d-block"><img src="assets/img/courses/courses12.jpg" alt="image"></a>
                </div>

                <div class="courses-content">
                
                   <p> <a routerLink="/mba" class="d-inline-block">MBA Master of Business Administration</a></p>
                    
                </div>
  

               
            </div>
        </div>
           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/thermal" class="d-block"><img src="assets/img/courses/courses10.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                      
                <p>  <a routerLink="/thermal" class="d-inline-block">M.E Thermal Engineering</a></p>
                      
                   </div>


                
               </div>
           </div>

           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/mecse" class="d-block"><img src="assets/img/courses/courses9.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                
                 <p><a routerLink="/mecse" class="d-inline-block">M.E Computer Science Engineering</a></p>
                     
                   </div>
 

               </div>
           </div>

           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/embedded" class="d-block"><img src="assets/img/courses/courses11.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
              
                       <p><a routerLink="/" class="d-inline-block">M.E Embedded System Technologies</a></p>
                       
                   </div>
  
               
               </div>
           </div>

     

         

        
       </div>
       
   </div>
   
   
</section>
<div class="user-actions">
    <i class='bx bx-log-in'></i>
    <span>Ph.D Programme  </span>
    
</div>
<section >
    
   <div class="container-fluid">
       <div class="row">
           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/phd" class="d-block"><img src="assets/img/courses/courses13.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                      
                <p>  <a routerLink="/" class="d-inline-block">Ph.D - Mechanical Engineering</a></p>
                      
                   </div>
                 

                
               </div>
           </div>




        
       </div>
       
   </div>
   
   
</section>-->
<div class="program-container">
    <header class="program-header">
        <h1>PROGRAMME OFFERED</h1>
    </header>
    <section class="program-section">
        <div class="program-category">
            <h2>UNDERGRADUATE PROGRAMMES (UG)</h2>
            <h3>Bachelor of Engineering</h3>
            <div class="program-boxes">
                <div class="program-box"><a routerLink="/civil">Civil Engineering</a> </div>
                <div class="program-box"><a routerLink="/cse">Computer Science & Engineering</a></div>
                <div class="program-box"><a routerLink="/ai">Computer Science (Artificial Intelligence & Machine Learning)</a></div>
                <div class="program-box"><a routerLink="/cs">Computer Science (Cyber Security)</a></div>
                <div class="program-box"><a routerLink="/ece">Electronics & Communication Engineering</a></div>
                <div class="program-box"><a routerLink="/eee">Electrical & Electronics Engineering</a> </div>
                <div class="program-box"><a routerLink="/mech">Mechanical Engineering</a></div>
                
                
            </div>
        </div>
        <div class="program-category">
            <h2>POSTGRADUATE PROGRAMMES (PG)</h2>
            <h3>Master of Engineering</h3>
            <div class="program-boxes">
                
                <div class="program-box"><a >Computer Science</a></div>
                <div class="program-box"><a>Embedded System Technologies</a> </div>
                <div class="program-box"><a >Thermal Engineering</a></div>
                
            </div>
        </div>
        <div class="program-category">
            <h2>POSTGRADUATE PROGRAMME IN MANAGEMENT (PG)</h2>
            <h3></h3>
            <div class="program-boxes">
                <div class="program-box"><a >Master of Business Administration</a></div>
                
                
            </div>
        </div>
        <div class="program-category">
            <h2>RESEARCH PROGRAMME </h2>
            <h3>PART TIME & FULL TIME</h3>
            <div class="program-boxes">
                <div class="program-box"><a >Ph.D - Mechanical Engineering</a></div>
                
                
            </div>
        </div>
    </section>
</div>
</div>