<div class="custom-container">
<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg9">
    <div class="container">
        <div class="page-title-content">
      
        </div>
    </div>
</div>
<!--
<section style=" background: #B1B1B1">
    <div class="section-title" >
           <br><br>
    <h2><a>Campus Life</a></h2>
        
    </div>
<div 
    class="motivation-featured-area bg-with-black-color"
    [class.dark-motivation-featured-area]="themeService.isDark()"
>
    <div class="container-fluid" >
        <div class="row justify-content-center">
         
       
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about11.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br><br><br>
                    <h3>
                        <a >Internet Facilities</a>
                    </h3>
                    
                    <p>The campus gets connected to the World Wide Web through high speed 110 MBPS internet connectivity. The connection is distributed through the campus network to an internet connecting various laboratories and subnets inside each labs, departments, workshop, library and office. After college working hours (from 04:00 pm to 06:00 pm), Computer Laboratory is available for the hostel students to access study material from internet.</p>
                    
                </div>
            </div>

<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br><br><br>
                    <h3>
                        <a >Association Activities</a>
                    </h3>
                    <p>Technical skills of the students are evaluated by various Association activities which are organized by each department. Association activities include Technical Quiz, Paper Presentations, Science and Technology Exhibitions and Project Contests. Along with, Crimson English Club which gives training in English speaking skills as well as Soft Skills, Personality Development Skills exclusively for second and third year students regularly.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about12.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>

            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about13.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br><br><br>
                    <h3>
                       <a >Industrial Visits</a>
                    </h3>
                    <p>Our Students are taken for industrial visits to face future challenges of quality, competition, efficiency and excellence. In-Plant Training is provided an industrial exposure to the students as well as develops their career in the high tech industrial requirements. Our students have attended In-Plant Training in reputed companies in and around Madurai.</p>
                    
                </div>
            </div>


            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br>
                    <h3>
                        <a >Mentors</a>
                    </h3>
                    <p>Recently, Mentoring System has emerged as a strong response to the plight of students at-risk. We offer a highly-efficient Mentoring system through which a group of students consisting of 10-15 students are assigned to a faculty member at the commencement of the program. Mentors meet their students and guide them with their studies and extra-curricular activities. Mentors coordinate with the parents regarding the progress of the students as well. In necessary occasions the international youth trainers and counselors are invited for the betterment of students’ psychology.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about14.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>

            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about15.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br>
                    <h3>
                       <a >YRC & RRC
                    </a>
                    </h3>
                    <p>Youth Red Cross (YRC) & Red Ribbon Club (RRC) provides opportunities to the youth – the makers of the future, to contribute their values uplift our society. We offer YRC and RRC Programs to inculcate our students in humanitarian actions. We have organized the following Awareness Programmes:</p>
                    <ul>
                        <li>National Voters’ Day</li>
                        <li>Homeopathy Healthcare</li>
                        <li>Covid – 19’ / Corona Virus</li>
                    </ul>
                    
                </div>
            </div>




<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br><br><br>
                    <h3>
                        <a >Auditorium</a>
                    </h3>
                    <p>FMCET has a well-equipped Auditorium with a seating capacity of 1000 people which is often utilized for cultural activities and celebrations. Our fully centralized AC auditorium is used as a venue for department conferences and seminars. We have a Board Room which is Air-conditioned and well-furnished for the Management Meetings and FDPs of faculty members.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about17.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>

            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about18.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br><br><br>
                    <h3>
                       <a >Hostel</a>
                    </h3>
                    <p>Our college provides separate hostel for boys and girls constructed in a calm and serene location. The hostel provides complete residential facilities to the students. The hostel rooms are spacious, adequately ventilated, well maintained and furnished with necessary accessories. Our hostels make students feel at home.</p>
                    
                </div>
            </div>


            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br>
                    <h3>
                        <a >Transport</a>
                    </h3>
                    <p>Transportation is to facilitate easy travel for the students and staff residing outside the college campus. The buses travel through the city and suburbs; routes are planned properly to be punctual and convenient for students and staff. The busses are functioned from Madurai, Manamadurai, Kalayar kovil, Sivagangai and Melur. On special occasions, trips are arranged perfectly - for hostellers during holidays and for industrial visits and social service activities. A special transport schedule has been prepared and will be published in our college website during Anna University Examinations.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about19.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>

    
         

            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about16.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                   
                    <h3>
                        <a >Sports & Games</a>
                    </h3>
                    <p>Sports help in building the values of leadership and team spirit among the students. It also inculcates the habit of winning in the students which is very important in today’s competitive world. It enhances physical and mental health and develops spirit of sportsmanship among students. Our campus encompasses state-of-the-art playing grounds for football, cricket, hand ball, kabaddi and volley ball and have facilities for indoor games like Carom and Chess. Our students are trained by Professional Trainers. Sports Day is organized by the College in second term of every academic year, which helps the students for their inclusive development. Our college students represent university level in various sports events.</p>
                    
                </div>
            </div>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <br><br><br>
                    <h3>
                        <a >Library</a>
                    </h3>
                    <p>Our college library has a built-up area of 10,000 sq.ft. The fully computerized library is well-equipped with modern facilities and a stock of 14, 000 Books (as per Anna University norms) and other resources in the form of Printed and Electronic Journals, CD-ROMs, Online Databases and Project Report etc. Open Access System is being followed to access the Books and Journals. All newspapers, magazines and journals are provided to the students, in the hostel to update their knowledge on the current affairs.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <img src="assets/img/about/about10.jpg" class="shadow" alt="image">
                    </div>
                    
                </div>
            </div>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
               
                    <img src="assets/img/about/about35.jpg" class="shadow" alt="image">
                  
                    
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        
                        <h3>
                            <a >NSS</a>
                        </h3>
                        <p>The main aim of National Service Scheme (NSS) is personality development through social service or community service. National Service Scheme is functioning very effectively in our college. Camps are being conducted to involve our students in social activities through NSS. The services carried out by our NSS students are as follows:</p>
                            <ul>
                            <li >Adoption of Village</li>
                            <li>Medical Camp</li>
                            <li>Blood Donation Camp</li>
                            <li>Eye Donation Awareness Camp</li>
                            <li>Vaigai River Cleaning</li>
                            <li>Rally</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        
        </div>
     
    </div>
    
</div>

<br><br>

</section>-->
<div class="container">
    <!-- Row 1 -->
    <div class="row">
        <div class="column">
            <div class="header-box">
                <h2>Internet Facilities</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about11.jpg" alt="Image 1">
            </div>
            <div class="content-box">
                <p>The campus gets connected to the World Wide Web through high speed 300 MBPS internet connectivity. The connection is distributed through the campus network to an internet connecting various laboratories and subnets inside each labs, departments, workshop, library and office. After college working hours (from 04:00 pm to 06:00 pm), Computer Laboratory is available for the hostel students to access study material from internet.</p>
            </div>
        </div>
        <div class="column">
            <div class="header-box">
                <h2>Association Activities</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about12.jpg" alt="Image 2">
            </div>
            <div class="content-box">
                <p>Technical skills of the students are evaluated by various Association activities which are organized by each department. Association activities include Technical Quiz, Paper Presentations, Science and Technology Exhibitions and Project Contests. Along with, Crimson English Club which gives training in English speaking skills as well as Soft Skills, Personality Development Skills exclusively for second and third year students regularly.</p>
            </div>
        </div>
        <div class="column">
            <div class="header-box">
                <h2>Industrial Visits</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about13.jpg" alt="Image 3">
            </div>
            <div class="content-box">
                <p>Our Students are taken for industrial visits to face future challenges of quality, competition, efficiency and excellence. In-Plant Training is provided an industrial exposure to the students as well as develops their career in the high tech industrial requirements. Our students have attended In-Plant Training in reputed companies in and around Madurai.</p>
            </div>
        </div>
    </div>

    <!-- Row 2 -->
    <div class="row">
        <div class="column">
            <div class="header-box">
                <h2>Mentors</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about14.jpg" alt="Image 4">
            </div>
            <div class="content-box">
                <p>Recently, Mentoring System has emerged as a strong response to the plight of students at-risk. We offer a highly-efficient Mentoring system through which a group of students consisting of 10-15 students are assigned to a faculty member at the commencement of the program. Mentors meet their students and guide them with their studies and extra-curricular activities. Mentors coordinate with the parents regarding the progress of the students as well. In necessary occasions the international youth trainers and counselors are invited for the betterment of students’ psychology.</p>
            </div>
        </div>
        <div class="column">
            <div class="header-box">
                <h2>YRC & RRC</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about15.jpg" alt="Image 5">
            </div>
            <div class="content-box">
                <p>Youth Red Cross (YRC) & Red Ribbon Club (RRC) provides opportunities to the youth – the makers of the future, to contribute their values uplift our society. We offer YRC and RRC Programs to inculcate our students in humanitarian actions. We have organized the following Awareness Programmes:</p>
                    <ul>
                        <li>National Voters’ Day</li>
                        <li>Homeopathy Healthcare</li>
                        <li>Covid – 19’ / Corona Virus</li>
                    </ul>
            </div>
        </div>
        <div class="column">
            <div class="header-box">
                <h2>Auditorium</h2>
            </div>
            <div class="image-box">
                <img src="assets/img/about/about17.jpg" alt="Image 6">
            </div>
            <div class="content-box">
                <p>FMCET has a well-equipped Auditorium with a seating capacity of 1000 people which is often utilized for cultural activities and celebrations. Our fully centralized AC auditorium is used as a venue for department conferences and seminars. We have a Board Room which is Air-conditioned and well-furnished for the Management Meetings and FDPs of faculty members.</p>
            </div>
        </div>
    </div>
        <!-- Row 4 -->
        <div class="row">
            <div class="column">
                <div class="header-box">
                    <h2>NSS</h2>
                </div>
                <div class="image-box">
                    <img src="assets/img/about/about35.jpg" alt="Image 4">
                </div>
                <div class="content-box">
                    <p>The main aim of National Service Scheme (NSS) is personality development through social service or community service. National Service Scheme is functioning very effectively in our college. Camps are being conducted to involve our students in social activities through NSS. The services carried out by our NSS students are as follows:</p>
                    <ul>
                    <li >Adoption of Village</li>
                    <li>Medical Camp</li>
                    <li>Blood Donation Camp</li>
                    <li>Eye Donation Awareness Camp</li>
                    <li>Vaigai River Cleaning</li>
                    <li>Rally</li>
                </ul>
                </div>
            </div>
            <div class="column">
                <div class="header-box">
                    <h2>Library</h2>
                </div>
                <div class="image-box">
                    <img src="assets/img/about/about10.jpg" alt="Image 5">
                </div>
                <div class="content-box">
                    <p>Our college library has a built-up area of 10,000 sq.ft. The fully computerized library is well-equipped with modern facilities and a stock of 14, 000 Books (as per Anna University norms) and other resources in the form of Printed and Electronic Journals, CD-ROMs, Online Databases and Project Report etc. Open Access System is being followed to access the Books and Journals. All newspapers, magazines and journals are provided to the students, in the hostel to update their knowledge on the current affairs.</p>
                </div>
            </div>
            <div class="column">
                <div class="header-box">
                    <h2>Sports & Games</h2>
                </div>
                <div class="image-box">
                    <img src="assets/img/about/about16.jpg" alt="Image 6">
                </div>
                <div class="content-box">
                    <p>Sports help in building the values of leadership and team spirit among the students. It also inculcates the habit of winning in the students which is very important in today’s competitive world. It enhances physical and mental health and develops spirit of sportsmanship among students. Our campus encompasses state-of-the-art playing grounds for football, cricket, hand ball, kabaddi and volley ball and have facilities for indoor games like Carom and Chess. Our students are trained by Professional Trainers. Sports Day is organized by the College in second term of every academic year, which helps the students for their inclusive development. Our college students represent university level in various sports events.</p>
                </div>
            </div>
            
        </div>

                <!-- Row 3 -->
                <div class="row">
                    <div class="column">
                        <div class="header-box">
                            <h2>Holtel</h2>
                        </div>
                        <div class="image-box">
                            <img src="assets/img/about/about18.jpg" alt="Image 4">
                        </div>
                        <div class="content-box">
                            <p>Our college provides separate hostel for boys and girls constructed in a calm and serene location. The hostel provides complete residential facilities to the students. The hostel rooms are spacious, adequately ventilated, well maintained and furnished with necessary accessories. Our hostels make students feel at home.</p>
                        </div>
                    </div>
                    <div class="column">
                        <div class="header-box">
                            <h2>Transport</h2>
                        </div>
                        <div class="image-box">
                            <img src="assets/img/about/about19.jpg" alt="Image 5">
                        </div>
                        <div class="content-box">
                            <p>Transportation is to facilitate easy travel for the students and staff residing outside the college campus. The buses travel through the city and suburbs; routes are planned properly to be punctual and convenient for students and staff. The busses are functioned from Madurai, Manamadurai, Kalayar kovil, Sivagangai and Melur. On special occasions, trips are arranged perfectly - for hostellers during holidays and for industrial visits and social service activities. A special transport schedule has been prepared and will be published in our college website during Anna University Examinations.</p>
                        </div>
                    </div>
                  
                    
                </div>
</div>

</div>