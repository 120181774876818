<div class="custom-container">
    <app-header-style-seven></app-header-style-seven>
    <div class="placement-overview">
        <h1>Introduction</h1>
        <p>
            The Placement Cell at Fatima Michael College of Engineering & Technology (FMCET), Madurai, is dedicated to providing students with ample opportunities to kick-start their professional careers. Our Placement Cell plays a pivotal role in bridging the gap between academic excellence and the industry’s requirements.
        </p>
      </div>
    
      <div class="tabs">
        <button class="tab-button" [class.active]="activeTab === 'overview'" (click)="setActiveTab('overview')">Overview</button>
        <button class="tab-button" [class.active]="activeTab === 'statistics'" (click)="setActiveTab('statistics')">Placement Statistics</button>
        <button class="tab-button" [class.active]="activeTab === 'training'" (click)="setActiveTab('training')">Training and Support</button>
        <button class="tab-button" [class.active]="activeTab === 'recruiters'" (click)="setActiveTab('recruiters')">Our Recruiters</button>
        <button class="tab-button" [class.active]="activeTab === 'ineternship'" (click)="setActiveTab('internship')">Internship</button>
        <button class="tab-button" [class.active]="activeTab === 'contact'" (click)="setActiveTab('contact')">Contact</button>

      </div>
    
      
      <div *ngIf="activeTab === 'overview'" class="tab-content">
        <h3>Overview</h3>
        <p>Placement Cell Overview:

            The Placement Cell at Fatima Michael College of Engineering & Technology (FMCET) is a dedicated and dynamic entity that works relentlessly to offer students a seamless transition from academia to industry. Our primary objective is to bridge the gap between theoretical knowledge and the practical requirements of the industry. By facilitating a variety of training, career counseling, internships, and direct recruitment drives, we aim to equip our students with the skills, knowledge, and confidence to secure placements in top companies.</p>
      </div>
      <div *ngIf="activeTab === 'statistics'" class="tab-content">
        <h3>Placement Statistics</h3>
        <p>Placement Statistics at FMCET reflect our commitment to quality education and the trust industry leaders place in our graduates. Our consistent placement record showcases the demand for FMCET students across various sectors, with an increasing number of students being placed in reputable companies each year.</p>
        <ul>
          <li><strong>Placement Rate:</strong> Over 90% of students placed annually.</li>
          <li><strong>Top Sectors:</strong> IT, Engineering, Manufacturing, and Consulting.</li>
          <li><strong>Average Salary Package:</strong> ₹5 LPA (Highest recorded salary: ₹15 LPA).</li>
          <li><strong>Number of Recruiters:</strong> 100+ top companies, including global leaders in technology and engineering.</li>
        </ul>
        <h4>Placement Record (Year-wise):</h4>
      
        <!-- New Table Container with Scroll for Mobile Compatibility -->
        <div class="placement-statistics-table-container">
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Placement Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2018-2019</td>
                <td>59.87%</td>
              </tr>
              <tr>
                <td>2019-2020</td>
                <td>72.86%</td>
              </tr>
              <tr>
                <td>2020-2021</td>
                <td>84.07%</td>
              </tr>
              <tr>
                <td>2021-2022</td>
                <td>72.53%</td>
              </tr>
              <tr>
                <td>2022-2023</td>
                <td>76.47%</td>
              </tr>
              <tr>
                <td><strong>Last Five Years Record</strong></td>
                <td><strong>72.837%</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      

      <div *ngIf="activeTab === 'training'" class="tab-content">
        <h3>Training and Support</h3>
        <p>Training and Support are integral parts of the placement process. FMCET offers a range of in-house training programs designed to improve students’ technical and soft skills, making them industry-ready.

            Technical Training: Focus on enhancing programming, problem-solving, and domain-specific knowledge.
            Soft Skills Development: Communication, leadership, and teamwork training to improve interpersonal skills.
            Mock Interviews & Resume Building: Preparing students for real-world interviews and ensuring they present themselves effectively to employers.</p>
      </div>
      <div *ngIf="activeTab === 'recruiters'" class="tab-content">
        <h3>Our Recruiters</h3>
        <p>FMCET has partnered with a wide array of esteemed recruiters who actively seek our graduates for various roles. Our placement team works closely with these recruiters to organize on-campus interviews, ensuring that students are placed in positions that align with their skills and career aspirations.</p>
       
          
            <!-- Table displaying recruiters -->
            <table class="recruiter-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Company Name</th>
                  <th>Place</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Infosys</td>
                  <td>Bangalore</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Cognizant</td>
                  <td>Chennai</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>TCS</td>
                  <td>Mumbai</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Wipro</td>
                  <td>Bangalore</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Accenture</td>
                  <td>Chennai</td>
                </tr>
                <!-- Add more rows as needed -->
               
              </tbody>
            </table>
         
          
      </div>
      <div *ngIf="activeTab === 'internship'" class="tab-content">
        <h3>Internship Program at Fatima Michael College of Engineering & Technology</h3>
        <p>Our Internship Program offers students valuable practical experience, allowing them to work with industry professionals on real-world projects. This program is designed to help students gain industry-specific skills and knowledge, making them better prepared for their future careers.</p>
        <h3>Registration for Internship:</h3>
        <p>Interested students can register for internships through the online form provided. The registration process includes submitting personal details, department, and academic information to match students with suitable internship programs based on their field of study and semester.</p>

           
            <h2>Internship Registration</h2>
  <form (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name">Student Name</label>
      <input type="text" id="name" class="form-control" [(ngModel)]="student.name" name="name" required />
      <div *ngIf="!student.name && formSubmitted" class="error-message">Name is required</div>
    </div>

    <div class="form-group">
      <label for="email">Student Email</label>
      <input type="email" id="email" class="form-control" [(ngModel)]="student.email" name="email" required />
      <div *ngIf="!student.email && formSubmitted" class="error-message">Email is required</div>
    </div>

    <div class="form-group">
      <label for="department">Department</label>
      <input type="text" id="department" class="form-control" [(ngModel)]="student.department" name="department" required />
      <div *ngIf="!student.department && formSubmitted" class="error-message">Department is required</div>
    </div>

    <div class="form-group">
      <label for="grade">Grade (5th Semester)</label>
      <input type="text" id="grade" class="form-control" [(ngModel)]="student.grade" name="grade" required />
      <div *ngIf="!student.grade && formSubmitted" class="error-message">Grade is required</div>
    </div>

    <button type="submit" [disabled]="!isFormValid()">Submit</button>
  </form>
      </div>
      <div *ngIf="activeTab === 'contact'" class="tab-content">
        <h3>Contact</h3>
        <p>For any queries related to placements, internships, or recruitment drives, students and employers can contact the Placement Cell Coordinator:<br>

            Name: Mr. Praveen Kumar<br>
            Email: placement&#64;fmcet.ac.in<br>
            Phone: +91 99429 82321<br>
            </p>
      </div>
  </div>
  