<div class="container">
    <br><br>
    <div class="section-title ">
        <h2  style="color: #FF6F61;">Testimonials</h2>
    
       
    </div>
</div>
<div
    class="container-fluid"
    [class.dark-testimonials-area]="themeService.isDark()"
>
    <div class="testimonials-wrap-slides">
        <owl-carousel-o [options]="testimonialsWrapSlides">
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Er. J. Venkateshwaran</h3>
                            <span>The Truthway Constructions</span>
                        </div>
                    </div>
                    <p>I am very glad to share my four years of B.E Civil Engineering journey at FMCET, we didn’t just learn book lessons, we learned life lessons too. A place which gave us countless memories for lifetime. I will be forever grateful to Fatima Michael College of Engineering & Technology for making me both the engineer and individual I am today.</p>
                 
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>S.K. Lemisha Reva</h3>
                            <span>Sr. Software Engineer</span>
                            <span>CTS</span>
                        </div>
                    </div>
                    <p>As a student of Fatima Michael College of Engineering & Technology, I am grateful to have been given several opportunities, which helped me to gain confidence and enough expertise to prove myself in any professional environment. Apart from learning, we have had an exposure to tremendous events that go on in and out of the college, which helps nurture our talents too. The quality of placements offered by some of the best companies in our country at this institution proves that Fatima Michael College of Engineering & Technology not only cares about your present education but also for security of your future. A special mention to teaching department who are proactively involved to provide the best for their students.</p>
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Er. Joel Vargees</h3>
                            <span>NISSAN</span>
                        </div>
                    </div>
                    <p>At FMCET, we didn’t just learn book lessons, we learned life lessons too. A place which gave us countless memories for lifetime. I will be forever grateful to Fatima Michael College of Engineering & Technology for making me both the engineer and individual I am today.</p>
                   
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user4.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>K. Nivetha Jessintha </h3>
                            <span>Sr. Software Engineer </span>
                            <span>Infosys, Chennai</span>
                        </div>
                    </div>
                    <p>One of my best experiences in life was studying atFatima Michael College of Engineering & Technology. For me, FMCET was not only the college that shaped my career and helped me succeed. Interacting with a lot of like-minded people and a group of extraordinarily talented professors was the most valuable experience. The coursework, internships, and extracurricular activities had a major impact on my career. In terms of the college’s infrastructure, Fatima Michael College of Engineering & Technology is one of the best in Southern parts of India. Fatima Michael College of Engineering & Technology has remarkable faculty and at last I would like to thank FMCET to have introduced me with the best people of my life, my friends.</p>
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user5.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>M. Saravana kumar</h3>
                            <span>Sr. System Analyst, Deloitte, Hyderabad </span>
                        </div>
                    </div>
                    <p>I personally feel, getting placement is one of the toughest things for any engineering graduate. But for the students of our college, it is like a bed of roses. The college gave a platform to build my personality, team work, leadership skills and can grow for higher heights. The placement cell has expanded my career possibilities by giving me the knowledge towards a focused career path based on specialized skills.</p>
                
                </div>
            </ng-template>
            
      
      
        </owl-carousel-o>
    </div>
</div>