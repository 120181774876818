import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-fourteen',
  templateUrl: './home-page-fourteen.component.html',
  styleUrls: ['./home-page-fourteen.component.scss']
})
export class HomePageFourteenComponent implements OnInit {

  // Define the messages data, divided into top and bottom
  topMessages = [
    {
      title: 'Chairman\'s Message',
      content: 'Fatima Michael College of Engineering & Technology stands ahead of others in the pursuit of excellence, aiming to develop the untapped potential of students. With a focus on technical expertise and soft skills, the college strives to create self-motivated agents of social change. By offering a blend of theoretical knowledge, practical exercises, and real-world interactions, we prepare students to meet the challenges of the competitive world. Our commitment to quality and innovation ensures that our students gain the skills needed to achieve their goals and make a lasting impact on society.',
      photo: 'assets/img/instructor2.jpg',
      caption: 'Chairman, Ln. Dr. M.Stalin Arockiaraj, B.E., M.S.,'
    },
    {
      title: 'CEO\'s Message',
      content: 'Fatima Michael College of Engineering & Technology fosters a learning environment that encourages high aims and the development of great minds. By integrating social responsibility with advanced technology and promoting ethical reasoning, our institution inspires students to emulate the knowledge and values learned in the classroom. We focus on understanding students\' motivations, interests, and aptitudes to create a student-centric education that enhances their grasp of engineering principles and concepts. Our goal is to shape professional excellence and prepare students to make meaningful contributions to society. The vision is to create a world-class institution that empowers students to excel in every aspect of their lives and careers.',
      photo: 'assets/img/instructor3.jpg',
      caption: 'CEO, Dr. S.Bridget Nirmala, M.E., Ph.D.,'
    }
  ];

  bottomMessages = [
    {
      title: 'Secretary\'s Message',
      content: '"Thinking well is wise, planning well is wiser, and doing well is the wisest." Fatima Michael College of Engineering & Technology stands as a beacon of quality education, embodying the essence of excellence. Since its establishment, the college has rapidly emerged as a leading academic institution, with its vision and values guiding it toward becoming a renowned university. Our institution embraces innovative strategies to bridge the gap between academia and the corporate world, ensuring our students are equipped with the knowledge and skills required for success. The courses offered at FMCET present students with a challenging yet rewarding learning experience, fostering both technical expertise and essential soft skills. FMCET is the ideal choice for those seeking excellence in engineering education.',
      photo: 'assets/img/instructor4.jpg',
      caption: 'Secretary, Ln. Mrs. M. Fatima Mary.,'
    },
    {
      title: 'Principal\'s Message',
      content: '"Education is not the learning of facts, but the training of the mind to think." - Albert Einstein. We owe our heartfelt gratitude to the Almighty and to our visionary founder, Ln. Dr. M. Stalin Arockiaraj, whose leadership has been the guiding force in our pursuit of excellence. At Fatima Michael College of Engineering & Technology, we are dedicated to transforming ordinary students into extraordinary achievers through a comprehensive and transformative educational experience. I take great pride in leading a team of dedicated educators who work tirelessly to ensure students not only excel academically but also adapt to global trends, think critically, and develop as responsible global citizens. Engineering is more than just a profession—it’s a passion for solving problems, driving innovation, and creating a better future. With this vision, FMCET is committed to providing an outstanding academic environment supported by modern infrastructure that fosters advanced learning. I invite students from all backgrounds to join the FMCET community, where they will spend four years acquiring the knowledge and skills needed to become the engineers of tomorrow.',

      photo: 'assets/img/instructor1.jpg',
      caption: 'Principal, Dr. P. Nelson Raja, M.E., Ph.D.,'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
