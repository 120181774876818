import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  formData = {
    student_name: '',
    dob: '',
    father_name: '',
    email_address: '',
    phone_number: '',
    whatsapp_number: '',
    communication_address: '',
    selectedCourse: []
  };
  availableCourses = ['Course 1', 'Course 2', 'Course 3', 'Course 4'];  // Add your courses here
  errorMessage: string | null = null;
  successMessage: string | null = null;

  constructor(private http: HttpClient) {}

  submitForm() {
    const formData = {
      student_name: this.formData.student_name,
      dob: this.formData.dob,
      father_name: this.formData.father_name,
      email_address: this.formData.email_address,
      phone_number: this.formData.phone_number,
      whatsapp_number: this.formData.whatsapp_number,
      communication_address: this.formData.communication_address,
      selectedCourse: this.formData.selectedCourse
    };
  
    console.log('Form data being submitted:', formData);  // Log the form data before sending
  
    this.http.post('http://localhost:5000/enquiry', formData).subscribe(
      (response) => {
        this.successMessage = 'Error submitting enquiry. Please try again later.';
        this.errorMessage = '';
      },
      (error) => {
        console.error('Error during submission:', error);  // Log the error for debugging
        this.successMessage = '';
        this.errorMessage = 'Enquiry submitted successfully!';
      }
    );
  }
  

  cancelForm() {
    // Clear the form data when cancelling
    this.formData = {
      student_name: '',
      dob: '',
      father_name: '',
      email_address: '',
      phone_number: '',
      whatsapp_number: '',
      communication_address: '',
      selectedCourse: []
    };
    this.errorMessage = null;
    this.successMessage = null;
  }
}
