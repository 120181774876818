<div class="custom-container">
<app-header-style-seven></app-header-style-seven>

<div
    class="motivation-mission-area"
    [class.dark-motivation-mission-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-content">
                    <h4><span class="sub-title">About IIC</span></h4>
                   <p>In the year 2018, the Institute Innovation Council (IIC) is an initiative by the Ministry of Education's Innovation Cell (MIC) in India. It was established to foster a culture of innovation and entrepreneurship within higher educational institutions (HEIs) across the country. The primary goal of IIC is to encourage, inspire, and nurture young students by exposing them to new ideas and processes resulting in innovative activities in their formative years.</p>
                    
                                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-image"></div>
            </div>
        </div>
    </div>
    
</div>
<section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>“To create a vibrant local innovation ecosystem. Start-up supporting Mechanism in HEIs. Prepare institute for Atal Ranking of Institutions on Innovation Achievements Framework. Establish Function Ecosystem for Scouting Ideas and Pre-incubation of Ideas. Develop better Cognitive Ability for Technology Students.”</p> 
                    
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                       To conduct various innovation and entrepreneuship-related activities prescribed by Central MIC in time-bound fashion.<br>
                       Identify and reward innovations and share success stories.<br>
                       Organize periodic workshops/ seminars/ interactions with entrepreneurs, investors, professionals and create a mentor pool for student innovators.<br>
                       Network with peers and national entrepreneurship development organizations.<br>
                       Create an Institution’s Innovation portal to highlight innovative projects carried out by institution’s faculty and students.<br>
                       Organize Hackathons, idea competition, mini-challenges etc. with the involvement of industries.
                        
                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>

<div class="container">
    
    <h2 class="playfair-display">Programs and Schemes for IIC Institutions</h2>
<br><br>
    
<div
    class="row"
    [class.dark-category-box]="themeService.isDark()"
>
    <div class="col-lg-3 col-sm-6 col-md-4">
        
        <div class="single-courses-category mb-30">
            <a>
                <i class='bx bx-shape-triangle'></i>
                Innovation Ambassador Program
                <p>Building In-house Human Rosource Capacity to Engage, Manage and Generate I&E: <strong>Training & mentoring support to  Students & Faculties</strong></p>
            </a>
            
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-font-family'></i>
                Impact Lecture Session
                <p>Funding Supprot to HEIs loacated faraway from I&E Ecosystem to Organize Impact Lectures : <strong>Institutes Performance Improvement</strong></p>
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bxs-drink'></i>
                Innovation Contest
                <p>360 Degree ExperientialLearning Program for Potential : <strong>Student Innovators and  Entrepreneurs Students</strong></p>
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-first-aid'></i>
                Mentor-Mentee Program
                <p>Peer Group Mentoring Support with Funding Support - Institute Improvement & Iner-Institutional Collaborations</p>
            </a>
        </div>
    </div>
   

    
   
</div>
</div>

<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    
                    <h2 class="playfair-display">MHRD’s Innovation Cell</h2>
                    <p>Ministry of HRD has established ‘MHRD’s Innovation Cell’ with the mandate to work closely with our Higher Education Institutions (HEIs) to encourage the creative energy of our student population to work on new ideas and innovation and promote them to create start-ups and entrepreneurial ventures.
                        MIC will focus on creating complete ecosystem which will foster the culture of Innovation across all educational institutions from ideas generation to pre-incubation, incubation and graduating from the incubator as successful start-ups. MIC will also work on designing ranking system to identify institutions in the forefront of innovation.
                        MIC will actively engage various central and state agencies to ensure that dedicated budget is allocated for innovation related activities.
                        </p>
                        <h2 class="playfair-display">Functions of IIC</h2>
                        <p>
                            students/Faculty associated with ICs will have exclusive opportunity to  participate in various Innovation related initiative and competitions organized by MHRD.
                            Win exciting prizes/Certificates every EDP activities.
                            Meet/Interact renowned Business Leaders and top-notch academicians.
                            Opportunity to nurture and prototype new ideas.
                            Mentoring by industry Professionals.
                            Experiment with new technologies.
                            Visit companies and places and see new culture of Entrepreneurship.
                            </p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="experience-image">
                    <a href="assets/img/pdf/EstablismentCertificate.pdf" target="_blank"><img src="assets/img/EstablismentCertificate.jpg" class="shadow" alt="image"></a>
                    
                </div>
            </div>
        </div>
    </div>
    
</section>

<div class="container">
<div class="row align-items-center">
    <div class="col-lg-4 col-md-12">
        <div class="about-image">
            <a href="assets/img/pdf/IIC_Appreciation_Letter_AY_2022_23.pdf" target="_blank"><img src="assets/img/pdf/IIC_Appreciation_Letter_AY_2022_23.jpg" class="shadow" alt="image"></a>
            
        </div>
    </div>

    <div class="col-lg-8 col-md-12">
        <div class="about-content">
           
        
            <div class="myDashboard-content">
                          
               
                <h3>IIC MEMBERS</h3>
                <div class="recent-orders-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name of Member</th>
                                <th>Designation</th>
                                <th>Key role/Position assigned in IIC</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. P. Nelson Raja</td>
                                <td>Principal</td>
                                <td>Chairperson</td>
                                </tr>
                            <tr>
                                <td>2</td>
                                <td>Dr.S.NishaRani</td>
                                <td>Professor</td>
                                <td>Vice-President</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Dr.K.Vinayagar</td>
                                <td>R&D/MECH</td>
                                <td>Convener</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Dr.G.Dhinesh</td>
                                <td>Director/M.B.A</td>
                                <td>EDC</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Dr.J.Rajalakshmi</td>
                                <td>Associate professor/EEE</td>
                                <td>Member</td>
                            </tr>
                            
                            <tr>
                                <td>6</td>
                                <td>Mr.C.Rajadurai</td>
                                <td>Associate professor/ECE</td>   
                                <td>Placement Coordinator</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Mrs.K.Preethi</td>
                                <td>Assistant professor/CSE</td>  
                                <td>Memeber</td> 
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Mr.P.Jeyakumar</td>
                                <td>Assistant professor/CIVIL</td>
                                <td>Internship Activity Coordinator</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Mr.V.Aravind</td>
                                <td>Assistant professor/AUTO</td>
                                <td>ARIIA Coordinator</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Mr.S.Venkadesh</td>
                                <td>Assistant professor/MECH</td>
                                <td>Social Media</td>
                            </tr>
                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
      </div>
      
    </div>
</div>
</div>
</div>