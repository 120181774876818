<div class="enquiry-container">
  <div class="enquiry-card">
    <span class="close-icon" (click)="cancelForm()">X</span>

    <h2>Enquiry Form</h2>

    <form (ngSubmit)="submitForm()">
      <div class="form-group">
        <label for="student_name">Student Name</label>
        <input type="text" id="student_name" [(ngModel)]="formData.student_name" name="student_name" required />
      </div>

      <div class="form-group">
        <label for="dob">Date of Birth</label>
        <input type="date" id="dob" [(ngModel)]="formData.dob" name="dob" required />
      </div>

      <div class="form-group">
        <label for="father_name">Father's Name</label>
        <input type="text" id="father_name" [(ngModel)]="formData.father_name" name="father_name" required />
      </div>

      <div class="form-group">
        <label for="email_address">Email Address</label>
        <input type="email" id="email_address" [(ngModel)]="formData.email_address" name="email_address" required />
      </div>

      <div class="form-group">
        <label for="phone_number">Phone Number</label>
        <input type="tel" id="phone_number" [(ngModel)]="formData.phone_number" name="phone_number" required />
      </div>

      <div class="form-group">
        <label for="whatsapp_number">WhatsApp Number</label>
        <input type="tel" id="whatsapp_number" [(ngModel)]="formData.whatsapp_number" name="whatsapp_number" required />
      </div>

      <div class="form-group">
        <label for="communication_address">Communication Address</label>
        <textarea id="communication_address" [(ngModel)]="formData.communication_address" name="communication_address" required></textarea>
      </div>

      <div class="form-group">
        <label for="interested_course">Select Courses</label>
        <select id="interested_course" [(ngModel)]="formData.selectedCourse" name="interested_course" multiple required>
          <option *ngFor="let course of availableCourses" [value]="course">{{ course }}</option>
        </select>
      </div>

      <!-- Error message -->
      <div *ngIf="errorMessage" class="alert error">
        {{ errorMessage }}
      </div>

      <!-- Success message -->
      <div *ngIf="successMessage" class="alert success">
        {{ successMessage }}
      </div>

      <!-- Submit and Cancel buttons -->
      <button type="submit" class="btn-submit">Submit</button>
      <button type="button" (click)="cancelForm()" class="btn-cancel">Cancel</button>
    </form>
  </div>
</div>
