import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ThemeCustomizerService } from '../theme-customizer/theme-customizer.service';  // Ensure this import is correct

@Component({
  selector: 'app-header-style-seven',
  templateUrl: './header-style-seven.component.html',
  styleUrls: ['./header-style-seven.component.scss']
})
export class HeaderStyleSevenComponent implements OnInit, OnDestroy {
  isSticky: boolean = false;
  newsItems: string[] = [];
  currentNewsIndex: number = 0;
  newsInterval: any;
  apiUrl: string = 'https://fmcet.ac.in/backend/getNews.php';

  constructor(
    private http: HttpClient,
    public themeService: ThemeCustomizerService  // Ensure the service is injected here
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.isSticky = window.scrollY > 50;
  }

  ngOnInit(): void {
    this.loadNews();
  }

  ngOnDestroy(): void {
    if (this.newsInterval) {
      clearInterval(this.newsInterval);
    }
  }

  loadNews(): void {
    this.http.get<string[]>(this.apiUrl).subscribe({
      next: (data) => {
        this.newsItems = data.length ? data : ['No news available'];
        this.startNewsRotation();
      },
      error: (error) => {
        console.error('Error loading news:', error);
        this.newsItems = ['Error loading news.'];
      }
    });
  }

  startNewsRotation(): void {
    this.newsInterval = setInterval(() => {
      this.currentNewsIndex = (this.currentNewsIndex + 1) % this.newsItems.length;
    }, 5000);
  }

  classApplied: boolean = false;
  toggleClass(): void {
    this.classApplied = !this.classApplied;
  }

  classApplied2: boolean = false;
  toggleClass2(): void {
    this.classApplied2 = !this.classApplied2;
  }

  classApplied3: boolean = false;
  toggleClass3(): void {
    this.classApplied3 = !this.classApplied3;
  }
}
