<div class="custom-container">
  <app-header-style-seven></app-header-style-seven>
  
  <h2 class="management-header"> DEPARTMENT OF SCIENCE & HUMANITIES  </h2>
  <div class="marquee-container">
    <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()" class="marq-one">
        <a style="color: #757474;" routerLink="/login" target="_blank">
            <span>Admissions open for - 2025 : B.E(CIVIL), B.E(CSE), B.E(CSE- AI & ML), B.E(CSE - CS), B.E(ECE), B.E(EEE), B.E(MECH), MBA, M.E(CSE), M.E(Embedded System Technologies), M.E(Thermal Engineering).</span>
        </a>
     
    </marquee>
  </div>
  <div class="container">
      
      <div class="tab-menu">
        <ul>
            <li [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">About the Department</li>
            <li [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">Vision & Mission</li>
            <li [class.active]="selectedTab === 'tab5'" (click)="selectTab('tab5')">Laboratory Facilities
           <!-- <li [class.active]="selectedTab === 'tab6'" (click)="selectTab('tab6')">Association Activities</li>
            <li [class.active]="selectedTab === 'tab7'" (click)="selectTab('tab7')">MoU's</li>
            <li [class.active]="selectedTab === 'tab8'" (click)="selectTab('tab8')">Placement Record</li>
            <li [class.active]="selectedTab === 'tab9'" (click)="selectTab('tab9')">Industrial Visit</li> 
            <li [class.active]="selectedTab === 'tab11'" (click)="selectTab('tab11')">Value Added Courses</li>
            <li [class.active]="selectedTab === 'tab12'" (click)="selectTab('tab12')">Alumini</li>-->
            <li [class.active]="selectedTab === 'tab13'" (click)="selectTab('tab13')">Gallery</li>
            
  
        </ul>
      </div>
      <div class="tab-content">
         
  
  
  <div *ngIf="selectedTab === 'tab1'">
    <div class="department-about">
      <!-- Full-width Image Box -->
      <div class="image-container">
        <img src="assets/img/page-title/page-title18.jpg"   srcset="high-res.jpg 2x, ultra-res.jpg 3x"  alt="Department Image">
      </div>
  
      <!-- Content Box -->
      <div class="text-container">
        
        <h1>About the Department</h1>
        <p>
          The Department of Science and Humanities is set up to cater the needs of budding Engineers.One of the most important aspects of Engineering is effective communication, both verbal and written. Humanities study can strengthen the ability of Engineers to work and communicate with others. The department targets at prominence in some of the latest trends and to establish consciousness and exposure in multi-disciplinary Engineering domain and to induct them to succeed with their technical skills and aptitudes.
        </p>
    
        <h1>DEPARTMENT OF MATHEMATICS
        </h1>
        <p>
          Mathematics gave birth to the core troubleshooters in scientific and engineering courses. With dynamics of design and theory converted into precise numbers, Mathematics provides practical solutions to theoretical promises. Special emphasis on recruitment of faculty who provide fundamentals of Mathematics and thus we can say we've a top class line-up of educators who have expertise in various Mathematical fields like topology, generalized MATRIX theory, Fluid Dynamics and graph theory. Our faculty members have published research papers in a peer-reviewed journals.
        </p>
    
        <h1>DEPARTMENT OF PHYSICS
        </h1>
        <p>
          Physics is considered the building block for all disciplines of engineering. It gives Mathematical equations and theoretic, a physical shape and form. Physics is a dynamic subject whose algorithmic formulations have provided answers to contemporary problems that involve addressing glitches relating to physical sciences. Physics cannot be contained into a box as a specific subject developed for a special Engineering branch; instead it involves answering questions to all kinds of physical problems. Engineering physics provides a deep understanding to the concepts of Nanotechnology, Quantum Physics, SemiconductorPhysics, Optics, MaterialScience andElectromagnetics, Thermal Physics, Crystallography, Fiber Optics and Electronics.
        </p>
    
        <h1>DEPARTMENT OF CHEMISTRY
        </h1>
        <p>
          The Department of Chemistry provides excellent infrastructure and sophisticated equipment to provide best possible fundamental education with training to students that develop kick starter skills for starting their career. Engineering Chemistry provides a deep understanding in the realm of Engineering Chemistry and Environmental Engineering.
        </p>
        <h1>DEPARTMENT OF ENGLISH
        </h1>
        <p>
          English has very often been termed as a window of rapid progress for technological and scientific knowledge. It has been portrayed as pipe-line for the stream of acquaintance in all branches of learning. In this scenario, the contributions of the department of English in imparting communication skills (LSRW) and functional grammar in English are significant.
        </p>
    
      </div>
    </div>
  </div>
  
        <div *ngIf="selectedTab === 'tab2'">
          <div class="vision-mission-wrapper">
              <!-- Vision Box -->
              <div class="vision-box">
                <div class="vision-content">
                  <h2>Our Vision</h2>
                  <p style="text-align: justify;">
                    To be one of the leading and preferred engineering colleges in education, research and ethics, and achieve greater recognition for our efforts to make the world look to us for technology and methodology for future trends and innovations.
                  </p>
                </div>
                <span class="vision-icon">
                  <img src="assets/img/vision-icon.png" alt="Vision Icon" />
                </span>
              </div>
          
              <!-- Mission Box -->
              <div class="mission-box">
                <div class="mission-content">
                  <h2>Our Mission</h2>
                  <p style="text-align: justify;">
                    To develop high-quality technical education and technocrats with a sound grip on basic engineering principles, technical, and managerial skills.<br>

              To innovate research capabilities, and exemplary professional conduct to lead and to use technology for the progress of humanity.<br>
              
              To adaptthemselves to changing technological conditions with the highest ethical values.

                  </p>
                </div>
                <span class="mission-icon">
                  <img src="assets/img/mission-icon.png" alt="Mission Icon" />
                </span>
              </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'tab3'">
          <div class="peo-section">
            <h2>Program Educational Objectives (PEO)</h2>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 1</div>
                    <div class="peo-description">Gain knowledge and skills in Civil engineering which will enable them to have a career and professional accomplishment in the public or private sector organizations.

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 2</div>
                    <div class="peo-description">Become consultants on complex real life Civil Engineering problems related to Infrastructure development especially housing, construction, water supply, sewerage, transport, spatial planning

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 3</div>
                    <div class="peo-description">Become entrepreneurs and develop processes and technologies to meet desired infrastructure needs of society and formulate solutions that are technically sound, Economically feasible, and socially acceptable.

                    </div>
                </div>
            </div>
            <div class="peo-card">
              <div class="peo-card-item">
                  <div class="peo-no"> 4</div>
                  <div class="peo-description">Perform investigation for solving Civil Engineering problems by conducting research using modern equipment and software tools.

  
                  </div>
              </div>
          </div>
          <div class="peo-card">
            <div class="peo-card-item">
                <div class="peo-no"> 5</div>
                <div class="peo-description">Function in multi-disciplinary teams and advocate policies, systems, processes and equipment to support civil engineering.


                </div>
            </div>
        </div>
      
        </div>
        
        </div>
        <div *ngIf="selectedTab === 'tab4'">
          <div class="outcomes-section">
            <h2 class="section-title">Program Outcomes (POs)</h2>
        
            <div class="outcomes-list">
                <div class="outcome-item">
                    <h3 class="outcome-title">Engineering Knowledge</h3>
                    <p class="outcome-description">Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Problem Analysis</h3>
                    <p class="outcome-description">Identify, formulate, review research literature, and analyze complex Engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Design/Development of Solutions</h3>
                    <p class="outcome-description">Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and Environmental considerations.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Conduct Investigations of Complex Problems</h3>
                    <p class="outcome-description">Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Modern Tool Usage</h3>
                    <p class="outcome-description">Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</p>
                </div>
                <div class="outcome-item">
                  <h3 class="outcome-title">The engineer and society</h3>
                  <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                  </p>
              </div>
              <div class="outcome-item">
                <h3 class="outcome-title">The engineer and society</h3>
                <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                </p>
            </div>
            <div class="outcome-item">
              <h3 class="outcome-title">Environment and sustainability</h3>
              <p class="outcome-description">Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
  
              </p>
          </div>
          <div class="outcome-item">
            <h3 class="outcome-title">Ethics</h3>
            <p class="outcome-description">Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
  
            </p>
        </div>
        <div class="outcome-item">
          <h3 class="outcome-title">Individual and teamwork</h3>
          <p class="outcome-description">Function effectively as an individual, and as a member Or leader in diverse teams, and in multidisciplinary settings.
  
  
          </p>
      </div>
      <div class="outcome-item">
        <h3 class="outcome-title">Communication</h3>
        <p class="outcome-description">Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give And receive clear instructions.
  
  
        </p>
    </div>
    <div class="outcome-item">
      <h3 class="outcome-title">Project management and finance</h3>
      <p class="outcome-description">Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member And leader in a team, to manage projects and in multi disciplinary environments.
  
  
      </p>
  </div>
  <div class="outcome-item">
    <h3 class="outcome-title">Life-long learning</h3>
    <p class="outcome-description">Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
  
  
  
    </p>
  </div>
                <!-- Additional POs continue here -->
            </div>
        
            <h2 class="section-title">Program Specific Outcomes (PSOs)</h2>
        
            <div class="outcomes-list">
     
                <div class="outcome-item">
                
                    
                    <p class="outcome-description">Knowledge of Civil Engineering discipline Demonstrate in-depth knowledge of Civil Engineering discipline, with an ability to evaluate, analyze and synthesize existing and new knowledge.
                 
            

                    </p>
                </div>
                <div class="outcome-item">
                   
                    <p class="outcome-description">Critical analysis of Civil Engineering problems and innovation Critically analyze complex Civil Engineering problems, apply independent judgment for synthesizing information and make innovative advances in a theoretical, practical and policy context.

                    </p>
                </div>
                <div class="outcome-item">
                    
                    <p class="outcome-description">Conceptualization and evaluation of engineering solutions to Civil Engineering Issues Conceptualize and solve Civil Engineering problems, evaluate potential solutions and arrive at technically feasible, economically viable and environmentally sound solutions with due consideration of health, safety, and socio cultural factors

  
                    </p>
                </div>
            </div>
        </div>
        
       
        
        
        </div>
        <div *ngIf="selectedTab === 'tab5'">
          <div class="labs-container">
            <div class="lab">
              <img src="assets/img/gallery/sh/2.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Physics Laboratory</h3>
                <p>FMCET  has a well-equipped a state – of the art Physics Laboratory equipped with cutting – edge instruments to conduct experiments in Optics, Electricity and Magnetism, Modern Physics and General Physics.These are aimed at exposing the young Engineers to the fascinating domain of experimental physics to enhance their engineering acumen and put a right step towards their industry readiness. Through the well-structured syllabus of Engineering Physics Lab ,the students not only learn, share and interact cohesively in the laboratory environment which in turn attributes to their lifelong learning skills. Such studies also provide fundamental knowledge required for future technological advancement.
                  </p>
                 
                
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/gallery/sh/3.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Chemistry Laboratory </h3>
            <p >The main objective of Chemistry Laboratory is to provide a conceptual understanding of the basic principles involved in chemical analysis. Most of the experiments are aligned with theoretical concepts. This course lays the foundation of certain basic concepts and skills that can be repeatedly employed by the students in their future endeavours. Additionally, it fosters  the students understand the real – world applications of chemistry, preparing them for industry roles or higher education in related fields.
              </p>
           
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/gallery/sh/4.jpg" alt="Lab 1 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>Language Laboratory</h3>
                <p>The department boasts of the state of the art Language and Multimedia Interactive Laboratory 
                  • Prepares students to face the real life situation of cooperation, competition and communication through role plays, pair work, individual tasks.
                  • The laboratory caters to honing four basic language skills (LSRW) through practice
                  </p>
               
              </div>
            </div>
  
            <!-- Add more labs as needed -->


     
      
     
  
          
          
   
  
  
          </div>
          
        </div>
 

        <div *ngIf="selectedTab === 'tab13'">
          <div class="single-department-gallery">
           
            <div class="gallery-grid">
              <div class="photo-item">
                <img src="assets/img/mech/gallery/1.jpg" alt="Lab 1">
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/2.jpg" alt="Lab 2">
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/3.jpg" alt="Lab 3">
               
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/4.jpg" alt="Seminar Hall">
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/5.jpg" alt="Classroom">
                
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/6.jpg" alt="Classroom">
               
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab14'">
          <div class="programmes-offered">
            <h2>Programmes Offered</h2>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Programme Name</th>
                  <th>Duration</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>B.E. Civil Engineering</td>
                  <td>4 Years</td>
                 
                </tr>
                
    
              </tbody>
            </table>
          </div>
          
          
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-section">
       <h2>Contact</h2>
       
           <p>Head of Department, <br> Department of science & Humanities,<br> Fatima Michael College of Engineering & Technology,<br> Madurai.  <br>Email: shhod&#64;fmcet.ac.in</p>
     
      
      </div>
 
    </div>
    
  </div>