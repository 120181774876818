<div class="custom-container">
    <app-header-style-one></app-header-style-one>
    <app-homenine-main-banner></app-homenine-main-banner>

    <app-discover-ednuv></app-discover-ednuv>
    <app-get-started></app-get-started>

    <app-features-stye-three></app-features-stye-three>

    <div class="testimonials-wrap-area pt-100" style="background-color: transparent">
        <app-students-feedback></app-students-feedback>
    </div>
</div>
