import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-home-page-nine',
  templateUrl: './home-page-nine.component.html',
  styleUrls: ['./home-page-nine.component.scss']
})
export class HomePageNineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.disableRightClick();
    this.disableTextSelection();
    this.preventCopy();
  }

  // Disable right-click
  disableRightClick(): void {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault(); // Block right-click without showing a popup
    });
  }

  // Disable text selection
  disableTextSelection(): void {
    document.body.style.userSelect = 'none'; // CSS to disable text selection
    document.body.style.webkitUserSelect = 'none'; // For Safari
  }

  // Prevent copy using clipboard events
  preventCopy(): void {
    document.addEventListener('copy', (event) => {
      event.preventDefault(); // Block copying without showing a popup
    });
  }

  // Block common keyboard shortcuts for copying content
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && (event.key === 'c' || event.key === 'u' || event.key === 's')) {
      event.preventDefault(); // Block shortcuts without showing a popup
    }
  }
}
