<div class="custom-container">
  <app-header-style-seven></app-header-style-seven>
  
  <h2 class="management-header"> DEPARTMENT OF CSE (ARTIFICIAL INTELLIGENCE & MACHINE LEARNING)  </h2>
  <div class="marquee-container">
    <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()" class="marq-one">
        <a style="color: #757474;" routerLink="/login" target="_blank">
            <span>Admissions open for - 2025 : B.E(CIVIL), B.E(CSE), B.E(CSE- AI & ML), B.E(CSE - CS), B.E(ECE), B.E(EEE), B.E(MECH), MBA, M.E(CSE), M.E(Embedded System Technologies), M.E(Thermal Engineering).</span>
        </a>
     
    </marquee>
  </div>
  <div class="container">
      
      <div class="tab-menu">
        <ul>
            <li [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">About the Department</li>
            <li [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">Vision & Mission</li>
            <li [class.active]="selectedTab === 'tab3'" (click)="selectTab('tab3')">PEO's</li>
            <li [class.active]="selectedTab === 'tab4'" (click)="selectTab('tab4')">POs & PSOs</li>
            <li [class.active]="selectedTab === 'tab14'" (click)="selectTab('tab14')">Programme Offered</li>
            <!--<li [class.active]="selectedTab === 'tab5'" (click)="selectTab('tab5')">Laboratory Facilities</li>-->
            
            
  
        </ul>
      </div>
      <div class="tab-content">
         
  
  
  <div *ngIf="selectedTab === 'tab1'">
    <div class="department-about">
      <!-- Full-width Image Box -->
      <div class="image-container">
        <img src="assets/img/page-title/page-title4.jpg"   srcset="high-res.jpg 2x, ultra-res.jpg 3x"  alt="Department Image">
      </div>
  
      <!-- Content Box -->
      <div class="text-container">
        <h1>About the Department</h1>
        <p>
          At our B.E. Computer Science and Engineering program with a specialization in Artificial Intelligence and Machine Learning, we believe in fostering a vibrant and collaborative learning community. The student association plays a pivotal role in enhancing the academic experience by organizing a variety of activities that complement classroom learning.<br>

The student association hosts regular seminars and guest lectures featuring experts from academia and industry. These sessions expose students to the latest trends, research, and applications in AI and ML, enriching their understanding beyond the curriculum.
        </p>
       
      </div>
    </div>
  </div>
  
        <div *ngIf="selectedTab === 'tab2'">
          <div class="vision-mission-wrapper">
              <!-- Vision Box -->
              <div class="vision-box">
                <div class="vision-content">
                  <h2>Our Vision</h2>
                  <p style="text-align: justify;">
                    To achieve academic excellence in Computer Science & Engineering. Providing in depth knowledge to the students, inculcating research activities to meet out industrial demands and society needs. Producing quality graduates to become leaders and innovators in industry worldwide.
                  </p>
                </div>
                <span class="vision-icon">
                  <img src="assets/img/vision-icon.png" alt="Vision Icon" />
                </span>
              </div>
          
              <!-- Mission Box -->
              <div class="mission-box">
                <div class="mission-content">
                  <h2>Our Mission</h2>
                  <p style="text-align: justify;">
                    To provide quality education to the under graduate and post graduate students and make them expert in cutting edge technology in Computer science engineering.<br>

To share and apply knowledge in interdisciplinary areas that extends the scope of computer science.<br>
To promote research based innovative projects and activities in the emerging technology areas.


                  </p>
                </div>
                <span class="mission-icon">
                  <img src="assets/img/mission-icon.png" alt="Mission Icon" />
                </span>
              </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'tab3'">
          <div class="peo-section">
            <h2>Program Educational Objectives (PEO)</h2>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 1</div>
                    <div class="peo-description">Apply their technical competence in computer science to solve real world problems, with technical and people leadership. 

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 2</div>
                    <div class="peo-description">Conduct cutting edge research and develop solutions on problems of social relevance. 

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 3</div>
                    <div class="peo-description">Work in a business environment, exhibiting team skills, work ethics, adaptability and lifelong learning.

                    </div>
                </div>
            </div>

      
        </div>
        
        </div>
        <div *ngIf="selectedTab === 'tab4'">
          <div class="outcomes-section">
            <h2 class="section-title">Program Outcomes (POs)</h2>
        
            <div class="outcomes-list">
                <div class="outcome-item">
                    <h3 class="outcome-title">Engineering Knowledge</h3>
                    <p class="outcome-description">Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Problem Analysis</h3>
                    <p class="outcome-description">Identify, formulate, review research literature, and analyze complex Engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Design/Development of Solutions</h3>
                    <p class="outcome-description">Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and Environmental considerations.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Conduct Investigations of Complex Problems</h3>
                    <p class="outcome-description">Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Modern Tool Usage</h3>
                    <p class="outcome-description">Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</p>
                </div>
                <div class="outcome-item">
                  <h3 class="outcome-title">The engineer and society</h3>
                  <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                  </p>
              </div>
              <div class="outcome-item">
                <h3 class="outcome-title">The engineer and society</h3>
                <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                </p>
            </div>
            <div class="outcome-item">
              <h3 class="outcome-title">Environment and sustainability</h3>
              <p class="outcome-description">Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
  
              </p>
          </div>
          <div class="outcome-item">
            <h3 class="outcome-title">Ethics</h3>
            <p class="outcome-description">Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
  
            </p>
        </div>
        <div class="outcome-item">
          <h3 class="outcome-title">Individual and teamwork</h3>
          <p class="outcome-description">Function effectively as an individual, and as a member Or leader in diverse teams, and in multidisciplinary settings.
  
  
          </p>
      </div>
      <div class="outcome-item">
        <h3 class="outcome-title">Communication</h3>
        <p class="outcome-description">Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give And receive clear instructions.
  
  
        </p>
    </div>
    <div class="outcome-item">
      <h3 class="outcome-title">Project management and finance</h3>
      <p class="outcome-description">Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member And leader in a team, to manage projects and in multi disciplinary environments.
  
  
      </p>
  </div>
  <div class="outcome-item">
    <h3 class="outcome-title">Life-long learning</h3>
    <p class="outcome-description">Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
  
  
  
    </p>
  </div>
                <!-- Additional POs continue here -->
            </div>
        
            <h2 class="section-title">Program Specific Outcomes (PSOs)</h2>
        
            <div class="outcomes-list">
              <p>The Students will be able to 


  
              </p>
                <div class="outcome-item">
                
                    
                    <p class="outcome-description">Exhibit design and programming skills to build and automate business solutions using cutting edge technologies. 

                    </p>
                </div>
                <div class="outcome-item">
                   
                    <p class="outcome-description">Strong theoretical foundation leading to excellence and excitement towards research, to provide elegant solutions to complex problems. 

                    </p>
                </div>
                
            </div>
        </div>
        
       
        
        
        </div>
        <div *ngIf="selectedTab === 'tab5'">
          <div class="labs-container">
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>PROGRAMMING LAB</h3>
                <p>Focuses on building foundational programming skills using languages like C, C++, Python, and Java. Supports courses such as Programming Fundamentals and Object-Oriented Programming.
                  </p>
                  
                
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>DATA STURUCTURES & ALGORITHMS LAB  </h3>
            <p >Provides hands-on experience in implementing and analyzing data structures and algorithms to solve computational problems. Supports courses like Data Structures and Algorithm Design.
              </p>
           
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/conhigh.jpg" alt="Lab 1 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>DATABASE MANAGEMENT SYTEMS LAB</h3>
                <p>Equipped to teach database design, querying, and management using tools like Oracle, MySQL, and MongoDB. Supports the course Database Management Systems.</p>
               
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>OPERATING SYSTEMS LAB </h3>
            <p>Offers practical exposure to operating system concepts such as process management, memory management, and file systems. Supports the course Operating Systems.</p>
            
              </div>
            </div>
            <!-- Add more labs as needed -->
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>NETWORKS AND CYBER SECURITY LAB </h3>
                <p>Facilitates learning of network protocols, design, and security using tools like Cisco Packet Tracer and Wireshark. Supports courses like Computer Networks and Cybersecurity.</p>
               
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>ARTIFICIAL INTELLIGENCE & MACHINE LEARNING LAB </h3>
                <p>Dedicated to exploring AI and ML techniques using frameworks like TensorFlow and PyTorch. Supports courses such as Artificial Intelligence and Machine Learning.</p>
                
              </div>
            </div>
  
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>CLOUD COMPUTING AND BIG DATA LAB </h3>
            <p>Provides an environment to work with cloud platforms (AWS, Azure) and big data tools (Hadoop, Spark). Supports courses like Cloud Computing and Big Data Analytics </p>
            
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>WEB DEVELOPMENT AND MOBILE APPLICATOIN LAB  </h3>
            <p>Focused on designing and developing responsive websites and mobile apps using technologies like HTML, CSS, JavaScript, Android Studio, and Flutter. Supports courses like Web Technology and Mobile Application Development.</p>
            
              </div>
            </div>
      
     
  
          
          
   
  
  
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab6'">
          <div class="tab6-table-container">
            <table class="tab6-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>CSE Association Inauguration</td>
                  <td>22/10/24</td>
                  <td>CSE Students Association inauguration
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Guest Lecture - Data warehousing</td>
                  <td>22/10/24 </td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Intra Department  Symposium-24 </td>
                  <td>15/10/24 </td>
                  <td>Technical competitions for Intra department students</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Seminar  - Career Opportunities for Engineering Graduates</td>
                  <td>27/9/24 </td>
                  <td>Seminar on Carrer opportunities for Engineering graduatesby expert from ICA skills pvt ltd, Madurai</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Seminar - Full stack development in Python : Best Practice & tools</td>
                  <td>20/9/24 </td>
                  <td>Seminar on Best practices & tools of full stack development by expert from Pantech Pro Ed, Chennai</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Engineers</td>
                  <td>16/10/24  </td>
                  <td>Seminar on emporwering society with AI concepts by Dr. VK Vijayakumar for Jawaharlal university Chennai</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Hands on training  -  Showcase a better You </td>
                  <td>20/3/24  </td>
                  <td>Hands on training on Showcase a better you by expert from Tringsapp research lab, Chennai</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Intra Department Symposium 2023</td>
                  <td>15/10/23  </td>
                  <td>Technical competitions for Intra department students</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>CSE Association inauguration </td>
                  <td>4/10/23 </td>
                  <td>CSE Students Association inauguration </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Seminar - Full stack development in Python : Best Practice & tools  </td>
                  <td>20/9/24  </td>
                  <td>Seminar on Best practices & tools of full stack development by expert from Pantech Pro Ed, Chennai</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Workshop – Python- AI  </td>
                  <td>20/9/23 </td>
                  <td>Hands on training on python AI concepts by expert form Scope tech solutions, Madurai</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Seminar – Cyber Security </td>
                  <td>28/8/23  </td>
                  <td>Awareness programme for girls regarding cyber threads by expert from Cybercrime dept, Madurai</td>
                </tr>
                            

              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab7'">
          <div class="tab7-table-wrapper">
            <table class="tab7-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Organization</th>
                  <th>Date of Signing</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Chrome Software Solutions, Madurai
                    </td>
                  <td>3/1/20</td>
                  <td>Internship & projects
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Pantech Pro Ed, Chennai
                    </td>
                  <td>15/9/21</td>
                  <td>Placements &inplant training
                    </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Winways informatics, Madurai
                    </td>
                  <td>3/10/22</td>
                  <td>International certification courses, internships & Research activities
                    
                    </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Scope Tech Solutions, madurai
                    
                    </td>
                  <td>14/9/23</td>
                  <td>Placements & students innovative projects
                    
                    </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Amizhth, Chennai
                    
                    </td>
                  <td>7/8/24</td>
                  <td>Internship & placements
                    
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab8'">
    
          <div class="tab8-table-container">
            <table class="tab8-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Student Name</th>
                  <th>Company</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Barathkumar .P</td>
                  <td>Qspider, Chennai </td>
                  <td>16.09.24</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Selvaganesh .P</td>
                  <td>Qspider, Chennai</td>
                  <td>16.09.24</td>
                  
                </tr>
                <tr>
                  <td>3</td>
                  <td>Mathanagopal .P</td>
                  <td>Tcon Engineering PVT LTD</td>
                  <td>21.11.24</td>
               
                </tr>
                <tr>
                  <td>4</td>
                  <td>Hemanth Kumar .S</td>
                  <td>Team Engineers ,Chennai</td>
                  <td>13.02.23</td>
               
                </tr>
                <tr>
                  <td>5</td>
                  <td>Kandeepan.C</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>6</td>
                  <td>Sangeetha.V</td>
                  <td>V.Ilango Engieering Contractor,Madurai</td>
                  <td>06.04.23</td>
               
                </tr>
                <tr>
                  <td>7</td>
                  <td>Sobika.M</td>
                  <td>Threads N Needles,Madurai</td>
                  <td>10.04.23</td>
               
                </tr>
                <tr>
                  <td>8</td>
                  <td>Vigneshwaran.M</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>9</td>
                  <td>Sivasankar K</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>10</td>
                  <td>Pushpalatha D</td>
                  <td>Team Engineers ,Chennai</td>
                  <td>13.02.23</td>
               
                </tr>
         
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedTab === 'tab9'">
          <div class="industrial-visit-container">
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv1.jpg" alt="Industrial Visit 1">
              </div>
              <div class="visit-content">
                <h3>VISIT TO JOTHI ELECTRICAL INDUSTRY </h3>
                <p>Jothi Electrical Industries has made significant strides in the electrical industry, especially in offering products like electrical transformers, power transformers, and services related to electrical motors. The visit of 35 students with 2 faculties to their company must have been a valuable experience for the students. It would have provided them with real-world insights into the electrical industry, especially in terms of manufacturing, servicing, and the technical knowledge involved in transformers and motors. </p>
              </div>
            </div>
          
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv2.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO STK TRANSFORMERS</h3>
                <p>STK TRANSFORMERS is a sub manufacture of electrical power distribution transformers, arc welding transformers, and fluorescent ballasts transmission and distribution voltage regulators. Manufacture of electrical equipments like motor generator, transformer and control apparatus.35 students with 2 faculties visited the industry.
                  </p>
              </div>
            </div>

            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv3.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO KUDANKULAM NUCLEAR POWER PLANT</h3>
                <p>Kudankulam  Nuclear Power Plant Is The Largest Nuclear Power Plant In India Situated In Tirunelveli District Of Tamil Nadu It Has Total 6 Units  4 Units Under Operating Condition And Other 2 Is Kept Reserve Each Unit Generation Capacity Of 1000 Mw .36 Students  along with 2 faculty members  Visited the Reactor Unit, Generators, Cooling System And Protective Measures From Radio Active Rays .
                  </p>
              </div>
            </div>

            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv4.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO MEDC /METRO</h3>
                <p>MEDC/METRO is one of the Prestigious Substation (110k/It Park Elathaikulam) in Tamil Nadu . This gave insight to the student about power distribution system, Indoor & Outdoor units used in power system.Data Monitoring System Using SCADA AND RTU .19 students along with 2 faculties visited the substation. 
                  </p>
              </div>
            </div>

          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab10'">
          <div class="supervisors-container">
            <h2>Anna University Recognized Supervisors</h2>
            <table class="supervisors-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Supervisor Reference No.</th>
                  <th>Supervisor Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Specialization</th>
                  <th>Contact Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2520033</td>
                  <td>Dr.P. Nelson Raja. BE, ME, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Principal & Professor</td>
                  <td>Industrial engineering, Quality engineering, Energy Engineering, Manufacturing Engineering.</td>
                  <td>9942982321</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>2820030</td>
                  <td>Dr.P. Paul Pandian, BE, M.Tech, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Professor</td>
                  <td>Flexible Manufacturing, scheduling, composite materials, natural fibers.</td>
                  <td>9843481546</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="research-certificate-container">
            <h2 class="research-certificate-heading">Research Certificate</h2>
            <div class="research-certificate-content">
              <img src="assets/img/mech/cr.png" alt="Research Certificate" class="certificate-image">
              
            </div>
          </div>
          
          
          
          
        </div>
        <div *ngIf="selectedTab === 'tab11'">
          <div class="tab12-table-container">
            <table class="tab12-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>COURSE NAME</th>
                  <th>COURSE DETAILS</th>
                  <th>COMMENCE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Electric safety training Program </td>
                  <td>Emphasizing the importance of electrical safety, encouraging active Participation in safety initiatives and promoting awareness about hazards incidents</td>
                  <td>03.09.2024</td>
                </tr>
         
             </tbody>
            </table>
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab12'">
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a1.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.V.Alagarsamy</h2>
              <h4 class="alumni-batch">Batch:2011-2015</h4>
              <p class="alumni-description">
                Sr. Automation Engineer
Promho Engineering Pvt. Ltd
Chennai

              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>

          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a2.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er. B. Lilil Kannan</h2>
              <h4 class="alumni-batch">Batch:2017-2021</h4>
              <p class="alumni-description">
                Testing Engineer,
Voltech Engineering Pvt Ltd
Chennai


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a3.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.G.Anupriya</h2>
              <h4 class="alumni-batch">Batch:2018-2022</h4>
              <p class="alumni-description">
                Design Eng,
Caliber Interconnect Solutions Pvt. Ltd
Coimbatore


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab13'">
          <div class="single-department-gallery">
            <h2 class="department-title">Department of Mechanical Engineering</h2>
            <div class="gallery-grid">
              <div class="photo-item">
                <img src="assets/img/mech/gallery/1.jpg" alt="Lab 1">
                <div class="caption">Lab 1</div>
                <div class="footnote">This is the state-of-the-art lab for software development and programming courses.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/2.jpg" alt="Lab 2">
                <div class="caption">Lab 2</div>
                <div class="footnote">This lab is equipped with high-end machines for machine learning and data science research.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/3.jpg" alt="Lab 3">
                <div class="caption">Lab 3</div>
                <div class="footnote">Our lab for web development and software engineering projects.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/4.jpg" alt="Seminar Hall">
                <div class="caption">Seminar Hall</div>
                <div class="footnote">The seminar hall is used for hosting workshops, guest lectures, and student presentations.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/5.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/6.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab14'">
          <div class="programmes-offered">
            <h2>Programmes Offered</h2>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Programme Name</th>
                  <th>Duration</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>B.E. CSE (Artificial Intelligence & Machine Learning)</td>
                  <td>4 Years</td>
                 
                </tr>

    
              </tbody>
            </table>
          </div>
          
          
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-section">
       <h2>Contact</h2>
       
           <p>Head of Department, <br> Department of Computer Science & Engineering (AI&ML),<br> Fatima Michael College of Engineering & Technology,<br> Madurai.  <br>Email: csehod&#64;fmcet.ac.in</p>
     
      
      </div>
      <div class="right-section">
        <h3>Welcome to Department of CSE (Artificial Intelligence & Machine Learning)</h3>
        <p>Become a Computer Science Engineer (AL&ML)</p>
        <a href="https://fmcet.ac.in/enquiry/enquiry.html" target="_blank" class="apply-button">Apply Now</a>
      </div>
    </div>
    
  </div>