<app-header-style-seven></app-header-style-seven>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>The automobile industry provides us with mobility opportunity for an individual as well as industries and businesses. Automobile Engineering Department was established in the year 2009 with a sanctioned intake of 30 students annually. The automobile engineering department is a branch of engineering that focuses on designing, developing, and manufacturing vehicles, including cars, motorcycles, trucks, and other automobiles. It encompasses various areas such as vehicle design, propulsion systems, vehicle dynamics, safety, and manufacturing processes. Students in this department learn about the latest technologies and advancements in the automotive industry, making them well-equipped to work in the automotive sector. We're one of handful universities who provide a four year Bachelors program in Automobile Engineering. The Department of Automobile Engineering is well equipped with state of the art tech, to help the students understand fundamentals of automobiles and also adapt themselves to new and upcoming technology in the industry. The department was established with the aim to develop the best automobile engineers in the country, as it's one of the few disciplines that not only requires theoretical knowledge but also precise practical application. We not only provide classroom teaching and training but we also conduct workshops and factory floor training experience for the undergraduates so they can get an hands on approach in areas like production, design, service and maintenance of various automobiles. With an intensive academic and practical exposure, the graduating students will have greater opportunities of getting placed in leading companies across the globe. These future gems will be part of teams in SAE (Southern India Section) and Imperial Society of Innovative Engineers.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses20.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>Attaining a status in the field of Automobile engineering education to enhance the world of Automation with which to serve the global demands scrupulously </p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                   To provide top-notch instruction in automobile engineering with the foundational knowledge, multidisciplinary problem-solving abilities, and self-assurance needed for students to succeed in their chosen careers. 
<br>	To create a welcoming and inclusive environment that inspires students to reach their full potential as innovators or workers. 
<br>	To continue working in close collaboration with business sectors on technology development and transfer via consulting initiatives.

                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>

    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
        <div class="container">
            <div class="user-actions">
                <span> PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                <ul style="text-align: justify;">
                    <li> Excel in their professional career in automobile industry</li><br>
                    <li> Display research contribution with highest professional and ethical standards.</li><br>
                    <li> Apply the mathematical, scientific and engineering knowledge acquired in automobile
                        engineering in growth of the industry.</li><br>
                    <li> Demonstrate professionalism and team work in their career</li><br>
                    <li> Adapt to contemporary, technological and industrial trends by involving in life-long
                        learning.</li><br>
                </ul>
            </div>
        </div>
    </section>
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span> PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to</p>
                    <ul style="text-align: justify;">
                        <li> <span>Engineering knowledge:</span> Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li> <span>Problem analysis:</span> Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li> <span>Design/development of solutions:</span> Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li> <span>Conduct investigations of complex problems:</span> Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li> <span>Modern tool usage:</span> Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                            <li><span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
                                To assess societal, health, safety, legal and cultural issues and the consequent
                                responsibilities relevant to the professional engineering practice.</li><br>
                            <li><span>Environment and sustainability:</span> Understand the impact of the professional engineering
                                solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                                need for sustainable development.</li><br>
                            <li><span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
                                norms of the engineering practice.</li><br>
                            <li><span>Individual and teamwork:</span> Function effectively as an individual, and as a member
                                Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                            <li><span>Communication:</span> Communicate effectively on complex engineering activities with the
                                engineering community and with society at large, such as, being able to comprehend and
                                write effective reports and design documentation, make effective presentations, and give
                                And receive clear instructions.</li><br>
                            <li><span>Project management and finance:</span> Demonstrate knowledge and understanding of the
                                engineering and management principles and apply these to one’s own work, as a member
                                And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                            <li><span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
                                engage in independent and life-long learning in the broadest context of technological
                                change.</li><br>
                            <br>

                    </ul>

                    <span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span>
                    <ul>
                       <br>
                        <li>The knowledge of humanities, basic sciences and management principles in the
                            automobile industry.</li><br>
                        <li>The knowledge of engineering sciences in field of automobile engineering for the
                            development of sustainable society.</li><br>
                        <li>The attitude of lifelong learning and ethical practices in their profession to assess societal, health,
                            safety, legal and cultural issues.</li><br>
                    </ul>
                </div>
            </div>
        </section>


    </mat-tab>
    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg7 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Automobile Engineering</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <br><br><br>
            <div class="user-actions">
                <i class='bx bx-log-in'></i>
                <span>Programme Offered </span>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Programme Name</th>
                            <th scope="col">Years</th>
                         
                           
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td class="product-name">
                                <a >1.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/auto">B.E. Automobile Engineering</a>
                            </td>
                            <td class="product-total">
                                <span class="subtotal-amount">4 Years</span>
                            </td>
                            
                          </tr>
                    
                          </tbody>
                          </table>
                    
                </div>
            </div>
    </mat-tab>
    </mat-tab-group>



 


<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses42.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    <br><br>
                    <h3>Association Activities</h3>
              
                    <p>The association actively involves itself into development of the students. It organizes self-development programs, placement training programs, guest lectures to enhance technical, and communication skills of the students in order to interact in a precise and proper manner. The department encourages students to take initiative and participate in curricular and extracurricular activities ranging from quizzes, symposiums to National and International Conferences.
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Automobile Engineering</h2>
                        <p> Passion and interest” in automobiles and other automotive items are the primary prerequisites for becoming an automobile engineer.  This is necessary to be able to manage several jobs as efficiently as possible. The demand for skilled automotive engineers has expanded as India’s automotive sector expands quickly. The Society of Indian Automobile Manufacturers is the seventh-largest automaker in the world, producing 17.5 million vehicles annually. Recognizing this growing prospect, Fatima Michael College of Engineering and Technology has curated a specialized course, B.E. Automobile Engineering</p>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses22.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>The laboratories in the department are well-equipped with modern machineries offering opportunities to practical exposure which will be directly transferrable to real life. The laboratory has been adapted to suit the needs for the following areas.</p>
                            <ul>
                           <li> Mechanical Sciences Laboratory</li>
                           <li>Vehicle Components Laboratory</li>
                           <li>Fuels and Lubricants Laboratory</li>
                           <li>Automotive Electrical and Electronics laboratory</li>
                           <li>Computer Aided Vehicle Design and Analysis Laboratory</li>
                           <li>Engine Testing and Emission Measurement Laboratory</li>
                           <li>Vehicle Maintenance and Testing Laboratory</li>
                        </ul>
                       
             
                  
                </div>
            </div>
        </div>
    </div>
</section>



