<div class="custom-container">
<app-header-style-seven></app-header-style-seven>
<header >
    <div class="seven">   
         <h1 >SC / ST Committee</h1>
    </div>
    </header>



<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="experience-content">
                    
                    
                    <p>As per the Prevention of atrocities Act 1989, No.33 of 1989, Dated 11-09- 1989 of Scheduled castes and Tribes, a committee is constituted in Fatima Michael College of Engineering and Technology, Madurai-20.
                        
                    </p>
                    <p>We aim to adhere to the AICTE regulations by forming the SC/ST Committee in accordance with the Scheduled Caste 
                        and the Scheduled Tribes (Prevention of Atrocities) Act of 1989 (No. 33 of 1989, dated 11-09-1989).
                        </p>
                        <ul class="myDashboard-content">
                            <P>The goal is to establish and uphold a secure, healthful, and supportive atmosphere within the campus for SC/ST staff and students.
                            </P>
                            <P>The objective is to resolve concerns faced by both staff and students from the SC/ST community and to prevent any acts of violence against them.
                            </P>
                            <P>Henceforth, affected SC/ST students and staff may approach this committee for any complaints falling within the scope of the aforementioned act.</P>
                            
                            
                            
                           </ul>
                       
                    
                </div>
            </div>
      
        </div>
    </div>
    
</section>

<div class="container">
<div class="row align-items-center">
    <div class="col-lg-4 col-md-12">
        <div class="about-image">
       
            <a href="assets/img/pdf/MOM-SC,SC-Committee.pdf" target="_blank"><img src="assets/img/pdf/MOM-SCST.jpg" class="shadow" alt="image"></a>
            <br>
        </div>
    </div>

    <div class="col-lg-8 col-md-12">
        <div class="about-content">
           
        
            <div class="myDashboard-content">
                          
               <br>
                <h3>SC / ST Committee</h3>
                <div class="recent-orders-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Post</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. P. Nelson Raja</td>
                                <td>Principal</td>
                                <td>Chairperson</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Dr. S. Saravana kumar</td>
                                    <td>Professor & Head / Automobile</td>
                                   <td>Member</td>
                                </tr>
                            <tr>
                                <td>3</td>
                                <td>Mr. A. Athimoolam</td>
                                <td>Associate Professor & Head / S&H</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Mr. M. Beju Mohan</td>
                                <td>AP/MECH</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mr. M. Thiyagarajan</td>
                                <td>Student</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Mr. M. Sivakumar</td>
                                <td>Student</td>
                                <td>Member</td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
      </div>
      
    </div>
</div>
</div>
</div>