import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-list-page',
  templateUrl: './courses-list-page.component.html',
  styleUrls: ['./courses-list-page.component.scss']
})
export class CoursesListPageComponent implements OnInit {
  showStartPopup = true; // To control the visibility of the start popup
  showEndPopup = false; // To control the visibility of the end popup
  selectedWord = ''; // Store the selected word

  constructor() { }

  ngOnInit(): void { }

  // Function to hide the start popup and start the matrix
  startMatrix(): void {
    this.showStartPopup = false;
    this.startCanvas(); // Initialize canvas matrix
  }

  // Function to show the end popup after clicking a word
  endGame(word: string): void {
    this.selectedWord = word;
    this.showEndPopup = true; // Show the end popup
  }

  // Function to reset the matrix and hide the end popup
  restartGame(): void {
    this.showEndPopup = false; // Hide end popup
    this.startMatrix(); // Start the matrix again
  }

  // Function to handle the matrix functionality
  startCanvas(): void {
    const canvas = document.getElementById('matrixCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    // Set canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const fontSize = 30;
    const columns = Math.floor(canvas.width / fontSize);
    const drops = Array(columns).fill(1);

    const words = ["CSE", "CSE (AI & ML)",'CSE (CS)', "Civil", "Mechanical", 'ECE', 'EEE', 'MBA'];

    function drawMatrix() {
      // Create trail effect
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Set font style
      ctx.fillStyle = "#0F0";
      ctx.font = fontSize + "px monospace";

      drops.forEach((y, x) => {
        const text = words[Math.floor(Math.random() * words.length)];
        ctx.fillText(text, x * fontSize, y * fontSize);

        if (y * fontSize > canvas.height && Math.random() > 0.975) {
          drops[x] = 0; // Reset drop to the top
        }
        drops[x]++;
      });
    }

    const matrixInterval = setInterval(drawMatrix, 50); // Start the animation

    // Add event listener to stop matrix animation on click
    canvas.addEventListener('click', (e) => {
      // Get the x, y position where the user clicked
      const clickX = e.clientX;
      const clickY = e.clientY;

      // Convert the click coordinates into column and row
      const column = Math.floor(clickX / fontSize);
      const row = Math.floor(clickY / fontSize);

      // Show popup with selected word
      const selectedWord = words[Math.floor(Math.random() * words.length)];
      this.endGame(selectedWord);

      // Stop the matrix animation
      clearInterval(matrixInterval);
    });
  }
}
