import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss']
})
export class FeedbackPageComponent implements OnInit {
  activeTab: string = 'overview'; // Default tab
  student = {
    name: '',
    email: '',
    department: '',
    grade: ''
  };
  formSubmitted: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  // Submit form and handle data
  onSubmit(): void {
    this.formSubmitted = true;
    if (this.isFormValid()) {
      console.log('Form Data:', this.student);
      // You can handle the form submission here, such as sending data to a backend.
    }
  }

  // Corrected validation method that returns boolean
  isFormValid(): boolean {
    return this.student.name !== '' && this.student.email !== '' && this.student.department !== '' && this.student.grade !== '';
  }

  // Set the active tab
  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
