<div class="custom-container">
<app-header-style-seven></app-header-style-seven>


<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>TEACHING STAFF </span> 
          
            
        </div>

        <form>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title"> List of Full-time Teachers </h3>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                      <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Faculty Name</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Department</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >Dr.P.Nelson Raja
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >PRINCIPAL & PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >M.E THERMAL
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. VIJAYALAKSHMI
                                                M </a>
                                        </td>
                                        <td class="product-name">
                                            <a >PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                  
                                    <tr>
                                        <td class="product-name">
                                            <a >3.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. VENKADA
                                                LAKSHMI
                                                R</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >4.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. VELUMANI
                                                P  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >5.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. THAVASEELAN
                                                J </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Civil Engineering  </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >6.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. KARTHIKEYAN
                                                S
                                                 </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >7.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. KANMANI
                                                V
                                                 </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >8.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. JEYAKUMAR
                                                P </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >9.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. ALAGU
                                                MURUGAN
                                                G  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >10.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. BHARATH
                                                KUMAR
                                                A </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Civil Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >11.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. RAMALINGAM
                                                S </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - CHEMISTRY </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >12.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. MUTHUPOONGODI
                                                S
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - CHEMISTRY </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >13.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. ASHOK KUMAR
                                                J
                                                 </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - PHYSICS </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >14.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. ATHIMOOLAM
                                                A </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE    PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H - PHYSICS </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >15.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. KAYALVIZHY
                                                S
                                                  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H - PHYSICS </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >16.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. NAVANEETHAM
                                                K </a>
                                        </td>
                                        <td class="product-name">
                                            <a >PROFESSOR </a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H Mathematics </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >17.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. SELVAKUMAR
                                                B
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H Mathematics </a>
                                        </td>    
                                        
                                    </tr>
 
                                    <tr>
                                        <td class="product-name">
                                            <a >18.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. PRABAKARAN
                                                R</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H Mathematics</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >19.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. BEAULAH
                                                WISELINE
                                                J</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - English</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >20.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. VASANTHI
                                                V</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - English </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >21.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. JEYANTHI
                                                J</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE
                                                PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >S & H - English </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >22.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. NAVAMMAL
                                                P</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H - English  </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >23.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS.
                                                MURUGESHWARI
                                                P
                                                 </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >S & H - English </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >24.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. SENTHAMARAI
                                                KANNAN
                                                S </a>
                                        </td>
                                        <td class="product-name">
                                            <a >PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >25.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. SUGANTHI
                                                J</a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >26.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. RAVIKUMARAN
                                                P
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science & Engineering</a>
                                        </td>    
                                        
                                    </tr>
                                    
                                    <tr>
                                        <td class="product-name">
                                            <a >27.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS.
                                                MANGAIYARKARASI
                                                T </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science & Engineering</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >28.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. SANGEETHA
                                                K </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >29.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. SENTHIL
                                                KUMARI
                                                M </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science & Engineering</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >30.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. SALOMI
                                                VICTORIA
                                                P K </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >31.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. YASAR
                                                B </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                    
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >32.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MS. VIJAYALAKSHMI
                                                D
                                                  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >33.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. THENMOZHI
                                                D  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science & Engineering</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >34.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. RATHIKA
                                                M</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science & Engineering</a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >35.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. DEVABALAN
                                                P</a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >M.E. Computer Science & Engineering  </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >36.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MS.
                                                DHIVYADHARSHINI
                                                M</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >M.E. Computer Science & Engineering </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >37.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. VENNILA
                                                P </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >M.E. Computer Science & Engineering   </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >38.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. RAJA MOHAMED
                                                RABI</a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Mechanical Engineering  </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >39.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. PAUL PANDIAN
                                                P  </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Mechanical Engineering  </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >40.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. PRABAKARAN
                                                M</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Mechanical Engineering  </a>
                                        </td>    
                                        
                                    </tr><tr>
                                        <td class="product-name">
                                            <a >41.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. KATHIRAVAN
                                                M</a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Mechanical Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >42.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. PANDI
                                                R </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Mechanical Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >43.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. VENKADESH
                                                S </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR </a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Mechanical Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >44.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. SELVAKUMAR
                                                M
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Mechanical Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >45.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. BALAKRISHNAN
                                                G
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science and Engineering (Artificial Intelligence and Machine Learning) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >46.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. PREETHI
                                                K </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Computer Science and Engineering (Artificial Intelligence and Machine Learning) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >47.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. AJITH KUMAR
                                                K </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science and Engineering (Artificial Intelligence and Machine Learning) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >48.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. RENISNA
                                                A </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Computer Science and Engineering (Artificial Intelligence and Machine Learning) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >49.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. KAVERY SUBA
                                                G
                                                  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Computer Science and Engineering (Artificial Intelligence and Machine Learning) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >50.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. ABISHEKA
                                                PON  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a > B.E.-Computer Science and Engineering (Cyber Security) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >51.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. SURIYA PRAKASH
                                                A   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a > B.E.-Computer Science and Engineering (Cyber Security) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >52.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. GRACE
                                                ANNAPACKIAM
                                                W  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >B.E.-Computer Science and Engineering (Cyber Security) </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >53.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. NISHA RANI
                                                SN  </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >54.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. PALANI KUMAR
                                                AK  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >55.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. PANDIAMMAL
                                                K   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering  </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >56.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. NAGADEEPA
                                                R </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering  </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >57.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. ELAYARAJA
                                                M  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR </a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >58.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. SARANIYA
                                                T   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >59.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. VIJITHA
                                                D </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >60.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. ASIYA MARIAM
                                                A
                                                   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >61.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. JEBANE
                                                P  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electronics and Communication Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >62.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. NATARAJAN
                                                R </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >63.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. ARUNRAJA
                                                M   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >64.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. MOHAMED
                                                AJMAL KHAN
                                                M
                                                  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >65.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. PRATHIBA
                                                RV   </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >66.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. AISHWARYA
                                                M  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >67.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. PRABU
                                                A  </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >Electrical and Electronics Engineering </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >68.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. RAMELA
                                                KR
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >M.E.-Embedded System Technologies </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >69.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. SAHAYA
                                                PAULRAJ
                                                S
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >M.E.-Embedded System Technologies  </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >70.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. RAJADURAI
                                                C
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >M.E.-Embedded System Technologies
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >71.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MS. SATHYA
                                                D
                                                </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >72.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MRS. SUGANYA
                                                M
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                        
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                        
                                    <tr>
                                        <td class="product-name">
                                            <a >73.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MS. SHANTHINI
                                                G
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                       
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >74.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. MURUGESAN
                                                K
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                      
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >75.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MS. ARUNADEVI
                                                H
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >76.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. SHEIK
                                                MOHAMMED
                                                A
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >B.E.-General Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >77.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. GNANASEKARAN
                                                B M
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSOCIATE PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.E.-Thermal Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >78.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >MR. GANESH
                                                H
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.E.-Thermal Engineering
                                            </a>
                                        </td>    
                                        

                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >79.</a>
                                        </td>
                                        <td class="product-name">
                                            <a >DR. SARAVANA
                                                KUMAR
                                                S
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >Auto Mobile Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >80.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> MRS. SUNDARAVALLI
                                            M
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSOCIATE PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >Auto Mobile Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >81.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> DR. JOHN
                                                K M
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSOCIATE PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >Auto Mobile Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >82.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> MR. JANAGARAN
                                                S
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >Auto Mobile Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >83.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> MR. KANNAN
                                                S
                                                
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >Auto Mobile Engineering
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >84.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> DR. DHINESH
                                                G
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a >  PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >85.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> DR. AYYANAR
                                                A
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSOCIATE PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >86.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> MR. BALAJI
                                                S V
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >87.</a>
                                        </td>
                                        <td class="product-name">
                                            <a> MRS. ANGEL
                                                RANJITHAM
                                                P
                                                
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >88.</a>
                                        </td>
                                        <td class="product-name">
                                            <a>MRS. GEETHA
                                                P
                                                
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >89.</a>
                                        </td>
                                        <td class="product-name">
                                            <a>MRS. VEERA SATHYA
                                                S
                                                
                                                
                                                
                                            </a>
                                        </td>
                                        <td class="product-name">
                                            <a > ASSISTANT PROFESSOR</a>
                                        </td>
                                     
                                        <td class="product-name">
                                            <a >M.B.A.-Master of Business Administration
                                            </a>
                                        </td>    
                                        
                                    </tr>



                                    
                                   
                                </tbody>
                            </table>
                        </div>

                  
                    </div>
                </div>
            </div>
            <div id="particles-js-circle-bubble-3"></div>
        </form>
    </div>
</section>
<!--
<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Post Graduate Programs Offered</span>
            
        </div>

        <form>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Postgraduate Programme</h3>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                      <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S.NO</th>
                                        <th scope="col">PG PROGRAMME</th>
                                        <th scope="col">DETAILS</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="">M.E. Thermal Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="">M.E. Embeded System Technologies</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >3.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="">M.E. Computer Science and Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >4.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="">M.B.A.</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                        
                                    </tr>
                                  
                                </tbody>
                            </table>
                        </div>
                        <div id="particles-js-circle-bubble-3"></div>
                  
                    </div>
                </div>
            </div>
            <div id="particles-js-circle-bubble-3"></div>
        </form>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Message for any Query</h2>
            <p></p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
   
</section>
<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:info@stmichaelgroup.org">principal@fmcet.ac.in</a></p>
                    <p><a href="mailto:info@stmichaelgroup.org">fmcet935@gmail.com</a></p>
                    <p><a href="mailto:info@stmichaelgroup.org">info@stmichaelgroup.org</a></p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Admission Enquiry</h3>
                    <p><a href="tel:91 98421 44567">+91 99429 82311</a></p>
                    <p><a href="tel:91 98421 44567">+91 99429 81321</a></p>
                    <p><a href="tel:91 98421 44567">+91 99429 82322</a></p>
                    <h3>Kerala Admission Enquiry</h3>
                    <p><a href="tel:91 98421 44567">+91 97457 11167</a></p>
                    <p><a href="tel:91 98421 44567">+91 75589 41111</a></p>
                    </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://goo.gl/maps/vpK16J7zFHHrzPJR8" target="_blank">Madurai Sivagangai Main Road, <br>(15 Kms from Madurai)<br>
                        Madurai - 625 020.
                        Tamil Nadu.
                        India.</a></p>
                </div>
            </div>

        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>-->
</div>