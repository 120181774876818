<section class="online-area pt-100 bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12" >
                <div class="online-image">
                    <img src="assets/img/woman-smile.png" alt="image">
                    <div class="gym-shape1"><img src="assets/img/gym-shape1.png" alt="image"></div>
                    <div class="gym-shape2"><img src="assets/img/gym-shape2.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                    Welcome to <h2 >Fatima Michael College of Engineering & Technology</h2>
                        Empowering Dreams, Building Futures                      
                        For the past 30 years, St. Michael Educational Trust has been a guiding light in education. We proudly manage: <br>
                        
                        * 2 Engineering Colleges<br>
                        * 1 Polytechnic College<br>
                        * 5 Schools<br>
                        * 1 B.Ed College <br>
                        Our journey has shaped thousands of engineers, young leaders, and entrepreneurs, many of whom are now working in top MNCs across the globe.<br>
                        
                       <br><h2> Why Choose Us?</h2>
                        <span>Quality Education :</span> A strong focus on both academics and practical skills.<br>
                        <span>Earn While You Learn :</span> Unique opportunities for students to gain income during their studies.<br>
                        <span>Development :</span> Co-curricular and extracurricular activities that boost creativity and leadership.<br>
                        <span>Strong Alumni Network :</span> Our graduates inspire and guide the next generation.<br><br>
                        At Fatima Michael College, we don’t just teach; we nurture talent and inspire success. <br>
                        
                        Join us to achieve your dreams and build a brighter future. <br>
                                             
                       <span> Be part of a legacy that changes lives.</span><br><br>
                   
                    
                    
                    <a target="_blank" routerLink="/login" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-user-circle icon-arrow after"></i></a>
               
            </div>
        </div>
    </div>
</section>