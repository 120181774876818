<div
    class="hero-banner "
    [class.dark-hero-banner]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <span style="text-align: left;" style="color:red" >Since 1993</span>
                            <h1 Style="color: #001f3f;"><br><br>St. Michael Educational <br><br>and Charitable Trust</h1>
                            
                         <p>"Welcome to a world where education is the key to unlocking potential, nurturing dreams, and shaping a future filled with possibilities. Together, we build a foundation of knowledge, character, and success."</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

