<div class="custom-container">
  <app-header-style-seven></app-header-style-seven>
  
  <h2 class="management-header"> DEPARTMENT OF ELECTRONICS & COMMUNICATION ENGINEERING  </h2>
  <div class="marquee-container">
    <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()" class="marq-one">
        <a style="color: #757474;" routerLink="/login" target="_blank">
            <span>Admissions open for - 2025 : B.E(CIVIL), B.E(CSE), B.E(CSE- AI & ML), B.E(CSE - CS), B.E(ECE), B.E(EEE), B.E(MECH), MBA, M.E(CSE), M.E(Embedded System Technologies), M.E(Thermal Engineering).</span>
        </a>
     
    </marquee>
  </div>
  <div class="container">
      
      <div class="tab-menu">
        <ul>
            <li [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">About the Department</li>
            <li [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">Vision & Mission</li>
            <li [class.active]="selectedTab === 'tab3'" (click)="selectTab('tab3')">PEO's</li>
            <li [class.active]="selectedTab === 'tab4'" (click)="selectTab('tab4')">POs & PSOs</li>
            <li [class.active]="selectedTab === 'tab14'" (click)="selectTab('tab14')">Programme Offered</li>
            <!--<li [class.active]="selectedTab === 'tab5'" (click)="selectTab('tab5')">Laboratory Facilities
            <li [class.active]="selectedTab === 'tab6'" (click)="selectTab('tab6')">Association Activities</li>-->
            <li [class.active]="selectedTab === 'tab7'" (click)="selectTab('tab7')">MoU's</li>
            <li [class.active]="selectedTab === 'tab8'" (click)="selectTab('tab8')">Placement Record</li>
            <li [class.active]="selectedTab === 'tab9'" (click)="selectTab('tab9')">Industrial Visit</li> 
            <!--<li [class.active]="selectedTab === 'tab11'" (click)="selectTab('tab11')">Value Added Courses</li>
            <li [class.active]="selectedTab === 'tab12'" (click)="selectTab('tab12')">Alumini</li>
            <li [class.active]="selectedTab === 'tab13'" (click)="selectTab('tab13')">Gallery</li>-->
            
  
        </ul>
      </div>
      <div class="tab-content">
         
  
  
  <div *ngIf="selectedTab === 'tab1'">
    <div class="department-about">
      <!-- Full-width Image Box -->
      <div class="image-container">
        <img src="assets/img/page-title/page-title5.jpg"   srcset="high-res.jpg 2x, ultra-res.jpg 3x"  alt="Department Image">
      </div>
  
      <!-- Content Box -->
      <div class="text-container">
        <h1>About the Department</h1>
        <p>
          The Department of Electronics and Communication Engineering is established in the year 2009 with a vision to develop competent professionals to meet the global challenges in modern engineering society. At present it offers a 4-year B.E. Electronics and Communication Engineering programme with a sanctioned intake of 60 students.<BR>

The Laboratories are equipped with latest technology in the fields of Electronic Devices and Circuits, Digital Electronics, Linear Integrated Circuits, Microprocessors and Microcontrollers, Digital Signal Processing, Communication System, VLSI, Embedded Systems, Optical & Microwave, Embedded systems that offer opportunities on a wide range of hardware and advanced software packages.<br>

The Department has a team of committed faculty members with sound knowledge in the areas of Applied Electronics, Communication systems, Wireless Communication, Embedded Systems, Computer Communication and Networking.
        </p>
      </div>
    </div>
  </div>
  
        <div *ngIf="selectedTab === 'tab2'">
          <div class="vision-mission-wrapper">
              <!-- Vision Box -->
              <div class="vision-box">
                <div class="vision-content">
                  <h2>Our Vision</h2>
                  <p style="text-align: justify;">
                    To produce Electronics and Communication Engineers capable of generating a knowledge economy with social responsibility.
                  </p>
                </div>
                <span class="vision-icon">
                  <img src="assets/img/vision-icon.png" alt="Vision Icon" />
                </span>
              </div>
          
              <!-- Mission Box -->
              <div class="mission-box">
                <div class="mission-content">
                  <h2>Our Mission</h2>
                  <p style="text-align: justify;">
                    To Equip the students with the current trends and latest technologies in the field of Electronics and Communication Engineering to make them technically strong and ethically sound.<br>
To Constantly motivate the students and faculty members to achieve excellence in Electronics and Communication Engineering and Research & Development activities.<br>
To Ideate and implement interdisciplinary projects and foster continuous learning in association with students and colleagues across disciplines of the institute collaborate with academia, industries, organizations and professional bodies for training, consultancy and research.
                  </p>
                </div>
                <span class="mission-icon">
                  <img src="assets/img/mission-icon.png" alt="Mission Icon" />
                </span>
              </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'tab3'">
          <div class="peo-section">
            <h2>Program Educational Objectives (PEO)</h2>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 1</div>
                    <div class="peo-description">To provide the students with a strong foundation in the required sciences in order to pursue studies in Electronics and Communication Engineering.


                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 2</div>
                    <div class="peo-description">To gain adequate knowledge to become good professional in electronic and communication engineering associated industries, higher education and research.


                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 3</div>
                    <div class="peo-description">To develop attitude in lifelong learning, applying and adapting new ideas and technologies as their field evolves.


                    </div>
                </div>
            </div>
            <div class="peo-card">
              <div class="peo-card-item">
                  <div class="peo-no"> 4</div>
                  <div class="peo-description">To prepare students to critically analyze existing literature in an area of specialization and ethically develop innovative and research oriented methodologies to solve the problems identified.


  
                  </div>
              </div>
          </div>
          <div class="peo-card">
            <div class="peo-card-item">
                <div class="peo-no"> 5</div>
                <div class="peo-description">To inculcate in the students a professional and ethical attitude and an ability to visualize the engineering issues in a broader social context.
                </div>
            </div>
        </div>
      
        </div>
        
        </div>
        <div *ngIf="selectedTab === 'tab4'">
          <div class="outcomes-section">
            <h2 class="section-title">Program Outcomes (POs)</h2>
        
            <div class="outcomes-list">
                <div class="outcome-item">
                    <h3 class="outcome-title">Engineering Knowledge</h3>
                    <p class="outcome-description">Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Problem Analysis</h3>
                    <p class="outcome-description">Identify, formulate, review research literature, and analyze complex Engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Design/Development of Solutions</h3>
                    <p class="outcome-description">Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and Environmental considerations.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Conduct Investigations of Complex Problems</h3>
                    <p class="outcome-description">Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Modern Tool Usage</h3>
                    <p class="outcome-description">Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</p>
                </div>
                <div class="outcome-item">
                  <h3 class="outcome-title">The engineer and society</h3>
                  <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                  </p>
              </div>
              <div class="outcome-item">
                <h3 class="outcome-title">The engineer and society</h3>
                <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                </p>
            </div>
            <div class="outcome-item">
              <h3 class="outcome-title">Environment and sustainability</h3>
              <p class="outcome-description">Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
  
              </p>
          </div>
          <div class="outcome-item">
            <h3 class="outcome-title">Ethics</h3>
            <p class="outcome-description">Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
  
            </p>
        </div>
        <div class="outcome-item">
          <h3 class="outcome-title">Individual and teamwork</h3>
          <p class="outcome-description">Function effectively as an individual, and as a member Or leader in diverse teams, and in multidisciplinary settings.
  
  
          </p>
      </div>
      <div class="outcome-item">
        <h3 class="outcome-title">Communication</h3>
        <p class="outcome-description">Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give And receive clear instructions.
  
  
        </p>
    </div>
    <div class="outcome-item">
      <h3 class="outcome-title">Project management and finance</h3>
      <p class="outcome-description">Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member And leader in a team, to manage projects and in multi disciplinary environments.
  
  
      </p>
  </div>
  <div class="outcome-item">
    <h3 class="outcome-title">Life-long learning</h3>
    <p class="outcome-description">Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
  
  
  
    </p>
  </div>
                <!-- Additional POs continue here -->
            </div>
        
            <h2 class="section-title">Program Specific Outcomes (PSOs)</h2>
        
            <div class="outcomes-list">
             


  

                <div class="outcome-item">
                
                    
                    <p class="outcome-description">Design, develop and analyze electronic systems through application of relevant electronics, mathematics and engineering principles


                    </p>
                </div>
                <div class="outcome-item">
                   
                    <p class="outcome-description">Design, develop and analyze communication systems through application of fundamentals from communication principles, signal processing, and RF System Design & Electromagnetics.


                    </p>
                </div>
                <div class="outcome-item">
                    
                    <p class="outcome-description">Adapt to emerging electronics and communication technologies and develop innovative solutions for existing and newer problems


  
                    </p>
                </div>
            </div>
        </div>
        
       
        
        
        </div>
        <div *ngIf="selectedTab === 'tab5'">
          <div class="labs-container">
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>ELECTRICAL MACHINES LAB</h3>
                <p>Electrical Machines laboratory equipped with Transformers, DC motors, Induction motors, Synchronous motors, DC generators and Synchronous generators. Students can investigate practically the single-phase and three-phase characteristics and speed torque characteristics.
                  </p>
                  <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Step up and step down transformers,
                    Three phase alternators coupled with dc motors,
                    Shunt motor coupled with DC Thyristor control panel,
                    Synchronous motor coupled with DC generators,
                    Three-phase squirrel cage induction motors,
                    Three phase squirrel cage induction motors coupled with DC shunt motor,
                    Single phase squirrel cage capacitor start capacitor run induction motors,
                    Three and single phase auto transformers of various ratings.
                    </p>
                
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>POWER ELECTRONICS LAB </h3>
            <p >This laboratory is utilized to simulate and practical implementation of the power switching circuits, power converters such as single and three phase half, semi and full converters, single phase and three phase inverter, chopper circuits and ac voltage controller circuits. Students can investigate practically the power switching devices SCR, TRIAC, MOSFET and IGBT characteristics.
              </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Static characteristics of SCR, MOSFET,IGBT & TRIAC Kit,
              DC-DC buck converter Kit,
              DC-DC boost converter Kit,
              Transient characteristics of SCR & MOSFET Kit,
              Chopper/inverter PWM inverter,
              Three phase IGBT based power module Kit.
              </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/conhigh.jpg" alt="Lab 1 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>POWER SYSTEM SIMULATION LAB</h3>
                <p>This laboratory is utilized to understand and analyze power system operation, stability, control and protection. In addition to that, it can be utilized to gain the basic knowledge on Power system components, load flow analysis, simulation and modeling to solve real world problems</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  MATLAB Software
      
                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>ELECTRICAL CIRCUITS LAB</h3>
            <p>This laboratory will enable the undergraduate students to learn the basic concepts and techniques in the design and operation of common circuits relevant to the field of electrical and electronics. The learning objective in this laboratory is to supplement the theory course of Circuit theory, Electronic Devices and Circuits, Digital and Linear integrated circuits.</p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
              Cathode Ray Oscilloscope (30 MHz),
Function Generator (3 MHz),
DC Dual Power supply (0-30 V/3 A),
Digital Trainer kit,
Decade Resistance, Capacitance, Inductance Box,
Decade Capacitance Box,
Analog Voltmeter (Moving Coil),
Analog Ammeter (Moving Coil),
Digital Multimeter.

  
              
              
              </p>
              </div>
            </div>
            <!-- Add more labs as needed -->
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>CONTROL AND INSTRUMENTATION LABORATORY</h3>
                <p>This Control and Instruction laboratory is well equipped with sufficient number of electronic test benches each consisting of CRO, Digital Storage Oscilloscope, Function Generator, Digital Multimeter, Desktop computer, etc. </p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  Schering Bridge,
Maxwell’s Bridge,
DC Position Controller,
AC Position Controller,
DC Servo Motor,
AC Servo Motor,
3MHZ Function Generator.

                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>  RENEWABLE ENERGY LABORATORY</h3>
                <p>The Renewable Energy laboratory typically includes equipment and instruments to test, measure, and analyze the performance of renewable energy systems. The laboratory may focus on a particular type of renewable energy, such as solar photovoltaic and wind energy.</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>1kWp Solar Inverter System,
                  Wind Energy Training Set Up,
                  Solar Street Light Set Up,
                  Solar MPPT Charge Controller,
                  Current Transformer.
                  
                  
                  </p>
              </div>
            </div>
  
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>MICROPROCESSOR AND MICROCONTROLLER</h3>
            <p>Microprocessor lab helps the students enhance their knowledge about various processors such as 8085, 8080, 8051 & microcontrollers and also the interfacing of these processors with other equipment. The features and facilities available in this lab help the students do their projects and enhance their analyzing and programming capability. </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> ADC/DAC control module,
              Stepper motor Interface with stepper motor,
              Key board/Display Interface Board 8279,
              8031/51/52 Microcontroller Trainer,
               8085  microprocessor Trainer,
              PIC Microcontroller Trainer Systems-LCD.
              
              </p>
              </div>
            </div>
     
      
     
  
          
          
   
  
  
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab6'">
          <div class="tab6-table-container">
            <table class="tab6-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Department Association Inauguration</td>
                  <td>06/11/2024</td>
                  <td>An Inauguration for a department association typically involves a formal ceremony that marks the official establishment or re establishment of the association.
                    Chief Guest : Er.A.Kesavan, CEO
                    Quantanics Techserv Pvt.Ltd, Madurai.
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Saturday Activity on “Drone Technology”</td>
                  <td>26/10/2024 </td>
                  <td>Online Presentation: Exploring the future of drone technology.</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Saturday Activity on “Smart farming” </td>
                  <td>19/10/2024 </td>
                  <td>Online Presentation: Explore the innovative technologies and practices that are transforming the agriculture with smart farming.</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Saturday Activity on “Hybrid electric vehicle” </td>
                  <td>28/09/2024 </td>
                  <td>Online Presentation: Provides an in depth look at hybrid EV, their technology, benefits and the role play in shaping sustainable transportation.</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Engineers day celebration - Technical Quiz</td>
                  <td>20/09/2024 </td>
                  <td>Technical Quiz aims to understanding of various technological concepts, innovations and trends.</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Engineers day celebration - Paper Presentation on “ Green Technology – A Future for the New generation” </td>
                  <td>20/09/2024  </td>
                  <td>Paper Presentation is a formal method of sharing research findings, ideas about green technology.</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Elicit Event -Basics of Arduino </td>
                  <td>20/09/2024  </td>
                  <td>Presentation Empowering creativity and innovation in Electronics.</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Saturday Activity on “Internet of Things “</td>
                  <td>14/09/2024  </td>
                  <td>Online Presentation: Provides a comprehensive introduction of IOT, exploring its significance, components and future trends.</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Saturday Activity on “Awareness social; media” </td>
                  <td>07/09/2024  </td>
                  <td>Online presentation is to raise awareness about the impact of social media on individuals, communities, and society as a whole.</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Elicit Event -Solar Panel Installation  </td>
                  <td>06/09/2024  </td>
                  <td>Presentation provides the process, benefits and considerations of installing solar panel for residential or commercial use.</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Saturday Activity on “Bio medical instrument”  </td>
                  <td>09/04/2024  </td>
                  <td>Online presentation focus on explaining the design, working principles, applications and importance of the devices in healthcare.</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Elicit Event -Kudankulam Nuclear Power Plant </td>
                  <td>03/04/2024  </td>
                  <td>The Presentation on a nuclear power plant focuses on explaining the structure, working and significance in energy production.</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Saturday Activity on “Nuclear battery”  </td>
                  <td>16/03/2024  </td>
                  <td>Online presentation on nuclear batteries delves into the technology, functionality and potential applications.</td>
                </tr>
                

              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab7'">
          <div class="tab7-table-wrapper">
            <table class="tab7-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Organization</th>
                  <th>Date of Signing</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Quantanics Techserv
                    Pvt.Ltd,
                    Madurai-16
                    </td>
                  <td>13/11/2024</td>
                  <td>The purpose of MOU is to
                    facilitate Guest Lectures,
                    Seminars, webinars and
                    Conferences on Recent
                    trends, Internships,
                    Implant Training and
                    collaboration on funded
                    Research projects.
                    </td>
                </tr>
               
             
              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab8'">
    
          <div class="tab8-table-container">
            <table class="tab8-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Student Name</th>
                  <th>Company</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>K INBASELVAN</td>
                  <td>Delphi TVS,Madurai </td>
                  <td>03.06.2024</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>U KAVISHRI</td>
                  <td>Silicon House</td>
                  <td>03.06.2024</td>
                  
                </tr>
                <tr>
                  <td>3</td>
                  <td>M MANIKANDAN</td>
                  <td>Chrome Software

                    Solutions,Madurai</td>
                  <td>10.06.2024</td>
               
                </tr>
                <tr>
                  <td>4</td>
                  <td>N NALLUCHAMY</td>
                  <td>Delphi TVS,Madurai</td>
                  <td>03.06.2024</td>
               
                </tr>
                <tr>
                  <td>5</td>
                  <td>K NATHIYA</td>
                  <td>Vertical Solutions,Madurai</td>
                  <td>03.07.2024</td>
               
                </tr>
                <tr>
                  <td>6</td>
                  <td>K PRAKASH</td>
                  <td>Winways,Madurai</td>
                  <td>03.07.2024</td>
               
                </tr>
                <tr>
                  <td>7</td>
                  <td>J SETHURAJ</td>
                  <td>Delphi TVS,Madurai,
                    Digital Track IT
                    solutions,Madurai</td>
                  <td>15.07.2024</td>
               
                </tr>
               
         
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedTab === 'tab9'">
          <div class="industrial-visit-container">
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eiv1.png" alt="Industrial Visit 1">
              </div>
              <div class="visit-content">
                <h3>VISIT TO PARLE -G </h3>
                <p>An industrial visit to Parle -G
                  provides students with an
                  opportunity to observe and
                  understand the production processes
                  of one of India&#39;s leading
                  confectionery and biscuit
                  manufacturers. Participants can
                  witness the manufacturing, packaging, and quality control processes for popular
                  products like Parle-G biscuits. This visit also offers insights into the company’s
                  operations, technology, and sustainability practices, bridging the gap between
                  theoretical knowledge and practical application.</p>
              </div>
            </div>
          
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eiv2.png" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO ISRO, MAHENDIRAGIRI</h3>
                <p>An industrial visit to ISRO Propulsion
                  Complex (IPRC) at Mahendragiri
                  provides students with exposure to
                  advanced rocket propulsion technologies,
                  testing facilities, and space research
                  activities, offering valuable insights into
                  India&#39;s space exploration initiatives.
                  </p>
              </div>
            </div>

           

          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab10'">
          <div class="supervisors-container">
            <h2>Anna University Recognized Supervisors</h2>
            <table class="supervisors-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Supervisor Reference No.</th>
                  <th>Supervisor Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Specialization</th>
                  <th>Contact Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2520033</td>
                  <td>Dr.P. Nelson Raja. BE, ME, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Principal & Professor</td>
                  <td>Industrial engineering, Quality engineering, Energy Engineering, Manufacturing Engineering.</td>
                  <td>9942982321</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>2820030</td>
                  <td>Dr.P. Paul Pandian, BE, M.Tech, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Professor</td>
                  <td>Flexible Manufacturing, scheduling, composite materials, natural fibers.</td>
                  <td>9843481546</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="research-certificate-container">
            <h2 class="research-certificate-heading">Research Certificate</h2>
            <div class="research-certificate-content">
              <img src="assets/img/mech/cr.png" alt="Research Certificate" class="certificate-image">
              
            </div>
          </div>
          
          
          
          
        </div>
        <div *ngIf="selectedTab === 'tab11'">
          <div class="tab12-table-container">
            <table class="tab12-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>COURSE NAME</th>
                  <th>COURSE DETAILS</th>
                  <th>COMMENCE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Electric safety training Program </td>
                  <td>Emphasizing the importance of electrical safety, encouraging active Participation in safety initiatives and promoting awareness about hazards incidents</td>
                  <td>03.09.2024</td>
                </tr>
         
             </tbody>
            </table>
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab12'">
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a1.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.V.Alagarsamy</h2>
              <h4 class="alumni-batch">Batch:2011-2015</h4>
              <p class="alumni-description">
                Sr. Automation Engineer
Promho Engineering Pvt. Ltd
Chennai

              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>

          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a2.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er. B. Lilil Kannan</h2>
              <h4 class="alumni-batch">Batch:2017-2021</h4>
              <p class="alumni-description">
                Testing Engineer,
Voltech Engineering Pvt Ltd
Chennai


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a3.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.G.Anupriya</h2>
              <h4 class="alumni-batch">Batch:2018-2022</h4>
              <p class="alumni-description">
                Design Eng,
Caliber Interconnect Solutions Pvt. Ltd
Coimbatore


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab13'">
          <div class="single-department-gallery">
            <h2 class="department-title">Department of Mechanical Engineering</h2>
            <div class="gallery-grid">
              <div class="photo-item">
                <img src="assets/img/mech/gallery/1.jpg" alt="Lab 1">
                <div class="caption">Lab 1</div>
                <div class="footnote">This is the state-of-the-art lab for software development and programming courses.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/2.jpg" alt="Lab 2">
                <div class="caption">Lab 2</div>
                <div class="footnote">This lab is equipped with high-end machines for machine learning and data science research.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/3.jpg" alt="Lab 3">
                <div class="caption">Lab 3</div>
                <div class="footnote">Our lab for web development and software engineering projects.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/4.jpg" alt="Seminar Hall">
                <div class="caption">Seminar Hall</div>
                <div class="footnote">The seminar hall is used for hosting workshops, guest lectures, and student presentations.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/5.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/6.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab14'">
          <div class="programmes-offered">
            <h2>Programmes Offered</h2>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Programme Name</th>
                  <th>Duration</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>B.E. Electronics & Communication Engineering</td>
                  <td>4 Years</td>
                 
                </tr>
               
    
              </tbody>
            </table>
          </div>
          
          
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-section">
       <h2>Contact</h2>
       
           <p>Head of Department, <br> Department of Electronics & Communication Engineering,<br> Fatima Michael College of Engineering & Technology,<br> Madurai.  <br>Email: ecehod&#64;fmcet.ac.in</p>
     
      
      </div>
      <div class="right-section">
        <h3>Welcome to Department of Electronics & Communication Engineering</h3>
        <p>Become a Electronics & Communication Engineer</p>
        <a href="https://fmcet.ac.in/enquiry/enquiry.html" target="_blank" class="apply-button">Apply Now</a>
      </div>
    </div>
    
  </div>