<app-header-style-seven></app-header-style-seven>



<div
    class="about-area ptb-100"
    [class.dark-about-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">About the Research Centre:</span>
                    
                    
                    <p>Department of Mechanical Engineering is one of the approved Research Centre from Anna University – Chennai.The Mechanical Engineering Research Centre is a dedicated facility that brings together researchers, faculty and students to engage in fundamental and applied research in mechanical engineering. The centre houses state-of-the-art laboratories, experimental facilities and computational resources to support a wide range of research activities. It provides a platform for collaboration, knowledge exchange and technology transfer with a focus on addressing current and future challenges in mechanical engineering</p>
                  <span class="sub-title">Objective</span>
                   <p>The objective of the Mechanical Engineering Research Centre is to conduct cutting-edge research in various areas of mechanical engineering, including but not limited to materials science, robotics, thermodynamics, and fluid mechanics. The centre aims to contribute to the advancement of knowledge in the field and to develop innovative solutions to real-world engineering problems.</p>
                   <span class="sub-title">Vision</span>
                   <p>The vision of the Mechanical Engineering Research Centre is to become a globally recognized hub for excellence in interdisciplinary collaboration, nurturing talent and producing impactful research in mechanical engineering.</p>
                   <span class="sub-title">Mission</span>
                   <p>The mission of the Mechanical Engineering Research Centre is to conduct high-quality research and providing a stimulating environment for researchers, fostering partnerships with industry & academia and contributing to the development of sustainable and innovative engineering solutions.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/courses/courses13.jpg" alt="image">
                    
              
                </div>
            </div>
        </div>
    </div>

</div>
<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>RECOGNIZED RESEARCH SUPERVISORS </h2>
            
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>



