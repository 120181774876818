import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {
  marqueeList: string[] = []; // For marquee data
  apiUrlMarquee: string = 'https://fmcet.ac.in/backend/getMarquee.php'; // Marquee data API URL
  galleryList: any[] = []; // For gallery data
  apiUrlGallery: string = 'https://fmcet.ac.in/backend/getGallery.php'; // Gallery data API URL
  fullscreenImage: string | null = null;

  selectedTab = 'tab1'; // Default selected tab

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadMarqueeData();  // Load marquee data when component initializes
    this.loadGalleryData();  // Load gallery data when component initializes
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  // Function to load Marquee data
  loadMarqueeData(): void {
    this.http.get<string[]>(this.apiUrlMarquee).subscribe({
      next: (data) => {
        this.marqueeList = data.length ? data : ['No marquee content available'];
      },
      error: (error) => {
        console.error('Error loading marquee:', error);
        this.marqueeList = ['Error loading marquee data.'];
      }
    });
  }

  // Function to load Gallery data
  loadGalleryData(): void {
    this.http.get<any[]>(this.apiUrlGallery).subscribe({
      next: (data) => {
        this.galleryList = data;
      },
      error: (error) => {
        console.error('Error loading gallery:', error);
        this.galleryList = [];
      }
    });
  }
  openFullscreen(imageSrc: string) {
    this.fullscreenImage = imageSrc;
  }

  closeFullscreen() {
    this.fullscreenImage = null;
  }
}
