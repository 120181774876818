<div class="custom-container">
  <app-header-style-seven></app-header-style-seven>
  
  <h2 class="management-header"> DEPARTMENT OF ELECTRICAL & ELECTRONICS ENGINEERING  </h2>
  <div class="marquee-container">
    <marquee vspace="0" onmouseover="this.stop()" onmouseout="this.start()" class="marq-one">
        <a style="color: #757474;" routerLink="/login" target="_blank">
            <span>Admissions open for - 2025 : B.E(CIVIL), B.E(CSE), B.E(CSE- AI & ML), B.E(CSE - CS), B.E(ECE), B.E(EEE), B.E(MECH), MBA, M.E(CSE), M.E(Embedded System Technologies), M.E(Thermal Engineering).</span>
        </a>
     
    </marquee>
  </div>
  <div class="container">
      
      <div class="tab-menu">
        <ul>
            <li [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">About the Department</li>
            <li [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">Vision & Mission</li>
            <li [class.active]="selectedTab === 'tab3'" (click)="selectTab('tab3')">PEO's</li>
            <li [class.active]="selectedTab === 'tab4'" (click)="selectTab('tab4')">POs & PSOs</li>
            <li [class.active]="selectedTab === 'tab14'" (click)="selectTab('tab14')">Programme Offered</li>
            <!--<li [class.active]="selectedTab === 'tab5'" (click)="selectTab('tab5')">Laboratory Facilities</li>-->
            <li [class.active]="selectedTab === 'tab6'" (click)="selectTab('tab6')">Association Activities</li>
            <li [class.active]="selectedTab === 'tab7'" (click)="selectTab('tab7')">MoU's</li>
            <li [class.active]="selectedTab === 'tab8'" (click)="selectTab('tab8')">Placement Record</li>
            <li [class.active]="selectedTab === 'tab9'" (click)="selectTab('tab9')">Industrial Visit</li> 
            <li [class.active]="selectedTab === 'tab11'" (click)="selectTab('tab11')">Value Added Courses</li>
            <li [class.active]="selectedTab === 'tab12'" (click)="selectTab('tab12')">Alumini</li>
            <!--<li [class.active]="selectedTab === 'tab13'" (click)="selectTab('tab13')">Gallery</li>-->
            
  
        </ul>
      </div>
      <div class="tab-content">
         
  
  
  <div *ngIf="selectedTab === 'tab1'">
    <div class="department-about">
      <!-- Full-width Image Box -->
      <div class="image-container">
        <img src="assets/img/page-title/page-title15.jpg"   srcset="high-res.jpg 2x, ultra-res.jpg 3x"  alt="Department Image">
      </div>
  
      <!-- Content Box -->
      <div class="text-container">
        <h1>About the Department</h1>
        <p>
          The department of EEE was established in the year 2011 with an intake of 60 students approved by AICTE. The department accommodate Outcome Based Education which help the students to learn, develop and serve to the society.<br>
The department has experienced and dedicated faculty with a wide range of specialization Power System, Power Electronics, Embedded Systems. The department has well established laboratory facilities to conduct research work on different specialized areas like Renewable Energy, Power Quality and Power Electronics.<br>
The students of the department have received external research funding from Tamil Nadu State Council for Science and Technology in recent years. The Students of the departments have joined in reputed industries through placements and some of them turned to be entrepreneur. The department has a good network of alumni.<br>

Training and Placement Cell is an integral part of our department. The cell is sensitized to function all through the year towards generating training and placement opportunities for the students. Training activities are organized throughout the year in an effort towards preparing the prospective students for the campus selection programmes. It also maintains a constant contact with its students through the coordinators and sends regular mails/notification about the companies that would be visiting thereby encouraging them to apply for the recruitment procedure.
        </p>
      </div>
    </div>
  </div>
  
        <div *ngIf="selectedTab === 'tab2'">
          <div class="vision-mission-wrapper">
              <!-- Vision Box -->
              <div class="vision-box">
                <div class="vision-content">
                  <h2>Our Vision</h2>
                  <p style="text-align: justify;">
                    The Department of Electrical and Elecrtonics Engineering is providing programs of the highest quality to produce globally competent technocrats who can address challenges of the millennium to achieve sustainable socio-economic development.
                  </p>
                </div>
                <span class="vision-icon">
                  <img src="assets/img/vision-icon.png" alt="Vision Icon" />
                </span>
              </div>
          
              <!-- Mission Box -->
              <div class="mission-box">
                <div class="mission-content">
                  <h2>Our Mission</h2>
                  <p style="text-align: justify;">
                    To create graduates possessing sound fundamental knowledge of Electrical Engineering<br><br>
                    To provide technocrats for industry<br><br>
                    To pursue research in Electrical & Electronics Engineering and across disciplines
                  </p>
                </div>
                <span class="mission-icon">
                  <img src="assets/img/mission-icon.png" alt="Mission Icon" />
                </span>
              </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'tab3'">
          <div class="peo-section">
            <h2>Program Educational Objectives (PEO)</h2>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 1</div>
                    <div class="peo-description">Find employment in Core Electrical and Electronics Engineering and service sectors.

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 2</div>
                    <div class="peo-description">Get elevated to technical lead position and lead the organization competitively.

                    </div>
                </div>
            </div>
            <div class="peo-card">
                <div class="peo-card-item">
                    <div class="peo-no"> 3</div>
                    <div class="peo-description">Enter into higher studies leading to post-graduate and research degrees.

                    </div>
                </div>
            </div>
            <div class="peo-card">
              <div class="peo-card-item">
                  <div class="peo-no"> 4</div>
                  <div class="peo-description">Become consultant and provide solutions to the practical problems of core organization. Become an entrepreneur and be part of electrical and electronics product and service industries.

  
                  </div>
              </div>
          </div>
      
        </div>
        
        </div>
        <div *ngIf="selectedTab === 'tab4'">
          <div class="outcomes-section">
            <h2 class="section-title">Program Outcomes (POs)</h2>
        
            <div class="outcomes-list">
                <div class="outcome-item">
                    <h3 class="outcome-title">Engineering Knowledge</h3>
                    <p class="outcome-description">Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Problem Analysis</h3>
                    <p class="outcome-description">Identify, formulate, review research literature, and analyze complex Engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Design/Development of Solutions</h3>
                    <p class="outcome-description">Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and Environmental considerations.
                    </p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Conduct Investigations of Complex Problems</h3>
                    <p class="outcome-description">Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</p>
                </div>
                <div class="outcome-item">
                    <h3 class="outcome-title">Modern Tool Usage</h3>
                    <p class="outcome-description">Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</p>
                </div>
                <div class="outcome-item">
                  <h3 class="outcome-title">The engineer and society</h3>
                  <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                  </p>
              </div>
              <div class="outcome-item">
                <h3 class="outcome-title">The engineer and society</h3>
                <p class="outcome-description">Apply reasoning in formed by the contextual knowledge To assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                </p>
            </div>
            <div class="outcome-item">
              <h3 class="outcome-title">Environment and sustainability</h3>
              <p class="outcome-description">Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
  
              </p>
          </div>
          <div class="outcome-item">
            <h3 class="outcome-title">Ethics</h3>
            <p class="outcome-description">Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
  
            </p>
        </div>
        <div class="outcome-item">
          <h3 class="outcome-title">Individual and teamwork</h3>
          <p class="outcome-description">Function effectively as an individual, and as a member Or leader in diverse teams, and in multidisciplinary settings.
  
  
          </p>
      </div>
      <div class="outcome-item">
        <h3 class="outcome-title">Communication</h3>
        <p class="outcome-description">Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give And receive clear instructions.
  
  
        </p>
    </div>
    <div class="outcome-item">
      <h3 class="outcome-title">Project management and finance</h3>
      <p class="outcome-description">Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member And leader in a team, to manage projects and in multi disciplinary environments.
  
  
      </p>
  </div>
  <div class="outcome-item">
    <h3 class="outcome-title">Life-long learning</h3>
    <p class="outcome-description">Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
  
  
  
    </p>
  </div>
                <!-- Additional POs continue here -->
            </div>
        
            <h2 class="section-title">Program Specific Outcomes (PSOs)</h2>
        
            <div class="outcomes-list">
              <p>On completion of Electrical and Electronics Engineering program, the student will have the following Program Specific Outcomes.


  
              </p>
                <div class="outcome-item">
                
                    
                    <p class="outcome-description">Foundation of Electrical Engineering: Ability to understand the principles and working of electrical components, circuits, systems and control that are forming a part of power generation,transmission,distribution,utilization,conservationand energy saving. Students can assess the power management, auditing, crisis and energy saving aspects.

                    </p>
                </div>
                <div class="outcome-item">
                   
                    <p class="outcome-description">Foundation of Mathematical Concepts: Ability to apply mathematical methodologies to solve problems related with electrical engineering using appropriate engineering tools and algorithms.

                    </p>
                </div>
                <div class="outcome-item">
                    
                    <p class="outcome-description">Computing and Research Ability:Ability to use knowledge in various domains to identify research gaps and hence to provide solution which leads to new ideas and innovations.

  
                    </p>
                </div>
            </div>
        </div>
        
       
        
        
        </div>
        <div *ngIf="selectedTab === 'tab5'">
          <div class="labs-container">
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>ELECTRICAL MACHINES LAB</h3>
                <p>Electrical Machines laboratory equipped with Transformers, DC motors, Induction motors, Synchronous motors, DC generators and Synchronous generators. Students can investigate practically the single-phase and three-phase characteristics and speed torque characteristics.
                  </p>
                  <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Step up and step down transformers,
                    Three phase alternators coupled with dc motors,
                    Shunt motor coupled with DC Thyristor control panel,
                    Synchronous motor coupled with DC generators,
                    Three-phase squirrel cage induction motors,
                    Three phase squirrel cage induction motors coupled with DC shunt motor,
                    Single phase squirrel cage capacitor start capacitor run induction motors,
                    Three and single phase auto transformers of various ratings.
                    </p>
                
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>POWER ELECTRONICS LAB </h3>
            <p >This laboratory is utilized to simulate and practical implementation of the power switching circuits, power converters such as single and three phase half, semi and full converters, single phase and three phase inverter, chopper circuits and ac voltage controller circuits. Students can investigate practically the power switching devices SCR, TRIAC, MOSFET and IGBT characteristics.
              </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> Static characteristics of SCR, MOSFET,IGBT & TRIAC Kit,
              DC-DC buck converter Kit,
              DC-DC boost converter Kit,
              Transient characteristics of SCR & MOSFET Kit,
              Chopper/inverter PWM inverter,
              Three phase IGBT based power module Kit.
              </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/conhigh.jpg" alt="Lab 1 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>POWER SYSTEM SIMULATION LAB</h3>
                <p>This laboratory is utilized to understand and analyze power system operation, stability, control and protection. In addition to that, it can be utilized to gain the basic knowledge on Power system components, load flow analysis, simulation and modeling to solve real world problems</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  MATLAB Software
      
                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>ELECTRICAL CIRCUITS LAB</h3>
            <p>This laboratory will enable the undergraduate students to learn the basic concepts and techniques in the design and operation of common circuits relevant to the field of electrical and electronics. The learning objective in this laboratory is to supplement the theory course of Circuit theory, Electronic Devices and Circuits, Digital and Linear integrated circuits.</p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
              Cathode Ray Oscilloscope (30 MHz),
Function Generator (3 MHz),
DC Dual Power supply (0-30 V/3 A),
Digital Trainer kit,
Decade Resistance, Capacitance, Inductance Box,
Decade Capacitance Box,
Analog Voltmeter (Moving Coil),
Analog Ammeter (Moving Coil),
Digital Multimeter.

  
              
              
              </p>
              </div>
            </div>
            <!-- Add more labs as needed -->
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>CONTROL AND INSTRUMENTATION LABORATORY</h3>
                <p>This Control and Instruction laboratory is well equipped with sufficient number of electronic test benches each consisting of CRO, Digital Storage Oscilloscope, Function Generator, Digital Multimeter, Desktop computer, etc. </p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>
                  Schering Bridge,
Maxwell’s Bridge,
DC Position Controller,
AC Position Controller,
DC Servo Motor,
AC Servo Motor,
3MHZ Function Generator.

                  
                  
                  </p>
              </div>
            </div>
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>  RENEWABLE ENERGY LABORATORY</h3>
                <p>The Renewable Energy laboratory typically includes equipment and instruments to test, measure, and analyze the performance of renewable energy systems. The laboratory may focus on a particular type of renewable energy, such as solar photovoltaic and wind energy.</p>
                <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong>1kWp Solar Inverter System,
                  Wind Energy Training Set Up,
                  Solar Street Light Set Up,
                  Solar MPPT Charge Controller,
                  Current Transformer.
                  
                  
                  </p>
              </div>
            </div>
  
            <div class="lab">
              <img src="assets/img/som.jpg" alt="Lab 2 Photo" class="lab-photo">
              <div class="lab-content">
                <h3>MICROPROCESSOR AND MICROCONTROLLER</h3>
            <p>Microprocessor lab helps the students enhance their knowledge about various processors such as 8085, 8080, 8051 & microcontrollers and also the interfacing of these processors with other equipment. The features and facilities available in this lab help the students do their projects and enhance their analyzing and programming capability. </p>
            <p style="text-align: left; font-size: 10px;"><strong>Equipment:</strong> ADC/DAC control module,
              Stepper motor Interface with stepper motor,
              Key board/Display Interface Board 8279,
              8031/51/52 Microcontroller Trainer,
               8085  microprocessor Trainer,
              PIC Microcontroller Trainer Systems-LCD.
              
              </p>
              </div>
            </div>
     
      
     
  
          
          
   
  
  
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab6'">
          <div class="tab6-table-container">
            <table class="tab6-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Department Association Inauguration</td>
                  <td>06/11/2024</td>
                  <td>An Inauguration for a department association typically involves a formal ceremony that marks the official establishment or re establishment of the association.
                    Chief Guest : Er.A.Kesavan, CEO
                    Quantanics Techserv Pvt.Ltd, Madurai.
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Saturday Activity on “Drone Technology”</td>
                  <td>26/10/2024 </td>
                  <td>Online Presentation: Exploring the future of drone technology.</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Saturday Activity on “Smart farming” </td>
                  <td>19/10/2024 </td>
                  <td>Online Presentation: Explore the innovative technologies and practices that are transforming the agriculture with smart farming.</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Saturday Activity on “Hybrid electric vehicle” </td>
                  <td>28/09/2024 </td>
                  <td>Online Presentation: Provides an in depth look at hybrid EV, their technology, benefits and the role play in shaping sustainable transportation.</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Engineers day celebration - Technical Quiz</td>
                  <td>20/09/2024 </td>
                  <td>Technical Quiz aims to understanding of various technological concepts, innovations and trends.</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Engineers day celebration - Paper Presentation on “ Green Technology – A Future for the New generation” </td>
                  <td>20/09/2024  </td>
                  <td>Paper Presentation is a formal method of sharing research findings, ideas about green technology.</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Elicit Event -Basics of Arduino </td>
                  <td>20/09/2024  </td>
                  <td>Presentation Empowering creativity and innovation in Electronics.</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Saturday Activity on “Internet of Things “</td>
                  <td>14/09/2024  </td>
                  <td>Online Presentation: Provides a comprehensive introduction of IOT, exploring its significance, components and future trends.</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Saturday Activity on “Awareness social; media” </td>
                  <td>07/09/2024  </td>
                  <td>Online presentation is to raise awareness about the impact of social media on individuals, communities, and society as a whole.</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Elicit Event -Solar Panel Installation  </td>
                  <td>06/09/2024  </td>
                  <td>Presentation provides the process, benefits and considerations of installing solar panel for residential or commercial use.</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Saturday Activity on “Bio medical instrument”  </td>
                  <td>09/04/2024  </td>
                  <td>Online presentation focus on explaining the design, working principles, applications and importance of the devices in healthcare.</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Elicit Event -Kudankulam Nuclear Power Plant </td>
                  <td>03/04/2024  </td>
                  <td>The Presentation on a nuclear power plant focuses on explaining the structure, working and significance in energy production.</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Saturday Activity on “Nuclear battery”  </td>
                  <td>16/03/2024  </td>
                  <td>Online presentation on nuclear batteries delves into the technology, functionality and potential applications.</td>
                </tr>
                

              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab7'">
          <div class="tab7-table-wrapper">
            <table class="tab7-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Organization</th>
                  <th>Date of Signing</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>VIRTUAL TECHNOLOGY
                    </td>
                  <td>22.11.2021</td>
                  <td>Partnership for skill training
                    </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>WISE TECH SOURCE PVT, LTD 
                    </td>
                  <td>28.10.2021</td>
                  <td>Collaboration on student exchange programs
                    </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>SMART MAKERS 
                    </td>
                  <td>06.03.2021</td>
                  <td>Partnership for skill training & special lecture on emerging technology and  industry based project development skills
                    
                    </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>LIVE WIRE TECHNOLOGIES 
                    
                    </td>
                  <td>08.01.2019</td>
                  <td>Collaboration on research projects and student exchange programs
                    
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab8'">
    
          <div class="tab8-table-container">
            <table class="tab8-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Student Name</th>
                  <th>Company</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Barathkumar .P</td>
                  <td>Qspider, Chennai </td>
                  <td>16.09.24</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Selvaganesh .P</td>
                  <td>Qspider, Chennai</td>
                  <td>16.09.24</td>
                  
                </tr>
                <tr>
                  <td>3</td>
                  <td>Mathanagopal .P</td>
                  <td>Tcon Engineering PVT LTD</td>
                  <td>21.11.24</td>
               
                </tr>
                <tr>
                  <td>4</td>
                  <td>Hemanth Kumar .S</td>
                  <td>Team Engineers ,Chennai</td>
                  <td>13.02.23</td>
               
                </tr>
                <tr>
                  <td>5</td>
                  <td>Kandeepan.C</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>6</td>
                  <td>Sangeetha.V</td>
                  <td>V.Ilango Engieering Contractor,Madurai</td>
                  <td>06.04.23</td>
               
                </tr>
                <tr>
                  <td>7</td>
                  <td>Sobika.M</td>
                  <td>Threads N Needles,Madurai</td>
                  <td>10.04.23</td>
               
                </tr>
                <tr>
                  <td>8</td>
                  <td>Vigneshwaran.M</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>9</td>
                  <td>Sivasankar K</td>
                  <td>RS Architects And Construction,Sivagangai</td>
                  <td>20.03.23</td>
               
                </tr>
                <tr>
                  <td>10</td>
                  <td>Pushpalatha D</td>
                  <td>Team Engineers ,Chennai</td>
                  <td>13.02.23</td>
               
                </tr>
         
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedTab === 'tab9'">
          <div class="industrial-visit-container">
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv1.jpg" alt="Industrial Visit 1">
              </div>
              <div class="visit-content">
                <h3>VISIT TO JOTHI ELECTRICAL INDUSTRY </h3>
                <p>Jothi Electrical Industries has made significant strides in the electrical industry, especially in offering products like electrical transformers, power transformers, and services related to electrical motors. The visit of 35 students with 2 faculties to their company must have been a valuable experience for the students. It would have provided them with real-world insights into the electrical industry, especially in terms of manufacturing, servicing, and the technical knowledge involved in transformers and motors. </p>
              </div>
            </div>
          
            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv2.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO STK TRANSFORMERS</h3>
                <p>STK TRANSFORMERS is a sub manufacture of electrical power distribution transformers, arc welding transformers, and fluorescent ballasts transmission and distribution voltage regulators. Manufacture of electrical equipments like motor generator, transformer and control apparatus.35 students with 2 faculties visited the industry.
                  </p>
              </div>
            </div>

            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv3.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO KUDANKULAM NUCLEAR POWER PLANT</h3>
                <p>Kudankulam  Nuclear Power Plant Is The Largest Nuclear Power Plant In India Situated In Tirunelveli District Of Tamil Nadu It Has Total 6 Units  4 Units Under Operating Condition And Other 2 Is Kept Reserve Each Unit Generation Capacity Of 1000 Mw .36 Students  along with 2 faculty members  Visited the Reactor Unit, Generators, Cooling System And Protective Measures From Radio Active Rays .
                  </p>
              </div>
            </div>

            <div class="visit-box">
              <div class="visit-photo">
                <img src="assets/img/eee/iv4.jpg" alt="Industrial Visit 2">
              </div>
              <div class="visit-content">
                <h3>VISIT TO MEDC /METRO</h3>
                <p>MEDC/METRO is one of the Prestigious Substation (110k/It Park Elathaikulam) in Tamil Nadu . This gave insight to the student about power distribution system, Indoor & Outdoor units used in power system.Data Monitoring System Using SCADA AND RTU .19 students along with 2 faculties visited the substation. 
                  </p>
              </div>
            </div>

          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab10'">
          <div class="supervisors-container">
            <h2>Anna University Recognized Supervisors</h2>
            <table class="supervisors-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Supervisor Reference No.</th>
                  <th>Supervisor Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Specialization</th>
                  <th>Contact Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2520033</td>
                  <td>Dr.P. Nelson Raja. BE, ME, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Principal & Professor</td>
                  <td>Industrial engineering, Quality engineering, Energy Engineering, Manufacturing Engineering.</td>
                  <td>9942982321</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>2820030</td>
                  <td>Dr.P. Paul Pandian, BE, M.Tech, Ph.D</td>
                  <td>Mechanical Engineering</td>
                  <td>Professor</td>
                  <td>Flexible Manufacturing, scheduling, composite materials, natural fibers.</td>
                  <td>9843481546</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="research-certificate-container">
            <h2 class="research-certificate-heading">Research Certificate</h2>
            <div class="research-certificate-content">
              <img src="assets/img/mech/cr.png" alt="Research Certificate" class="certificate-image">
              
            </div>
          </div>
          
          
          
          
        </div>
        <div *ngIf="selectedTab === 'tab11'">
          <div class="tab12-table-container">
            <table class="tab12-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>COURSE NAME</th>
                  <th>COURSE DETAILS</th>
                  <th>COMMENCE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Electric safety training Program </td>
                  <td>Emphasizing the importance of electrical safety, encouraging active Participation in safety initiatives and promoting awareness about hazards incidents</td>
                  <td>03.09.2024</td>
                </tr>
         
             </tbody>
            </table>
          </div>
          
        </div>
        <div *ngIf="selectedTab === 'tab12'">
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a1.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.V.Alagarsamy</h2>
              <h4 class="alumni-batch">Batch:2011-2015</h4>
              <p class="alumni-description">
                Sr. Automation Engineer
Promho Engineering Pvt. Ltd
Chennai

              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>

          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a2.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er. B. Lilil Kannan</h2>
              <h4 class="alumni-batch">Batch:2017-2021</h4>
              <p class="alumni-description">
                Testing Engineer,
Voltech Engineering Pvt Ltd
Chennai


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          <div class="alumni-highlight">
            <div class="alumni-photo">
              <img src="assets/img/eee/a3.jpg" alt="Distinguished Alumni Photo">
            </div>
            <div class="alumni-writeup">
              <h2 class="alumni-name">Er.G.Anupriya</h2>
              <h4 class="alumni-batch">Batch:2018-2022</h4>
              <p class="alumni-description">
                Design Eng,
Caliber Interconnect Solutions Pvt. Ltd
Coimbatore


              </p>
              <p class="alumni-quote">"The journey at this institution laid the foundation for my professional success and personal growth."</p>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab13'">
          <div class="single-department-gallery">
            <h2 class="department-title">Department of Mechanical Engineering</h2>
            <div class="gallery-grid">
              <div class="photo-item">
                <img src="assets/img/mech/gallery/1.jpg" alt="Lab 1">
                <div class="caption">Lab 1</div>
                <div class="footnote">This is the state-of-the-art lab for software development and programming courses.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/2.jpg" alt="Lab 2">
                <div class="caption">Lab 2</div>
                <div class="footnote">This lab is equipped with high-end machines for machine learning and data science research.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/3.jpg" alt="Lab 3">
                <div class="caption">Lab 3</div>
                <div class="footnote">Our lab for web development and software engineering projects.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/4.jpg" alt="Seminar Hall">
                <div class="caption">Seminar Hall</div>
                <div class="footnote">The seminar hall is used for hosting workshops, guest lectures, and student presentations.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/5.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
              <div class="photo-item">
                <img src="assets/img/mech/gallery/6.jpg" alt="Classroom">
                <div class="caption">Classroom</div>
                <div class="footnote">A fully equipped classroom for interactive lectures and student learning.</div>
              </div>
            </div>
          </div>
          
          
        </div>
        <div *ngIf="selectedTab === 'tab14'">
          <div class="programmes-offered">
            <h2>Programmes Offered</h2>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Programme Name</th>
                  <th>Duration</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>B.E. Electronics & Electrical Engineering</td>
                  <td>4 Years</td>
                 
                </tr>
                <tr>
                  <td>2</td>
                  <td>M.E. Embedded System Technologies</td>
                  <td>2 Years</td>
                
                </tr>
    
              </tbody>
            </table>
          </div>
          
          
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="left-section">
       <h2>Contact</h2>
       
           <p>Head of Department, <br> Department of Electrical & Electronics Engineering,<br> Fatima Michael College of Engineering & Technology,<br> Madurai.  <br>Email: eeehod&#64;fmcet.ac.in</p>
     
      
      </div>
      <div class="right-section">
        <h3>Welcome to Department of Electrical & Electronics Engineering</h3>
        <p>Become a Electrical & Electronics Engineering Engineer</p>
        <a href="https://fmcet.ac.in/enquiry/enquiry.html" target="_blank" class="apply-button">Apply Now</a>
      </div>
    </div>
    
  </div>