<section>
    <div class="container-fluid">
        <div class="section-title">
            <h1>Programme Offered</h1>
        </div>

        <div class="programs-container">
            <!-- Undergraduate Programs -->
            <div class="program-category">
                <h2 class="category-title">Under graduate Courses (UG)</h2>
                <table class="program-table">
                    <tr>
                        <td>B.E. Civil Engineering</td>
                    </tr>
                    <tr>
                        <td>B.E. Computer Science & Engineering</td>
                    </tr>
                    <tr>
                        <td>B.E. Computer Science & Engineering (Artificial Intelligence & Machine Learning)</td>
                    </tr>
                    <tr>
                        <td>B.E. Computer Science & Engineering (Cyber Security)</td>
                    </tr>
                    <tr>
                        <td>B.E. Electronics & Communication Engineering</td>
                    </tr>
                    <tr>
                        <td>B.E. Electrical & Electronics Engineering</td>
                    </tr>
                    <tr>
                        <td>B.E. Mechanical Engineering</td>
                    </tr>
                </table>
            </div>

            <!-- Postgraduate Programs -->
            <div class="program-category">
                <h2 class="category-title">Post graduate Courses (PG)</h2>
                <table class="program-table">
                    <tr>
                        <td>M.E. Computer Science & Engineering</td>
                    </tr>
                    <tr>
                        <td>M.E. Embedded System & Technology</td>
                    </tr>
                    <tr>
                        <td>M.E. Thermal Engineering</td>
                    </tr>
                    <tr>
                        <td>M.B.A Master of Business Administration</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>
