<div class="custom-container">
<app-header-style-seven></app-header-style-seven>
<header >
    <div class="seven">   
         <h1 >Grievance Redressal Committee</h1>
    </div>
    </header>


<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="experience-content">
                    
                    
                    <p>The Grievance Redressal Committee is constituted as per the revised Affiliation format of Anna University, Chennai and based upon the AICTE Regulations 2012. This Committee would meet as and when required to look into the complaints and grievances of the students. The members should prepare a report based upon the findings and submit it to the Principal to forward the same to the Ombudsman and the Affiliating University.
                       
                    
                </div>
            </div>
      
        </div>
    </div>
    
</section>

<div class="container">
<div class="row align-items-center">
    <div class="col-lg-4 col-md-12">
        <div class="about-image">
            <br>
            <a href="assets/img/pdf/MOM-GRC.pdf" target="_blank"><img src="assets/img/pdf/MOM-GRC.jpg" class="shadow" alt="image"></a>
            <br><br>
        </div>
    </div>

    <div class="col-lg-8 col-md-12">
        <div class="about-content">
           
        
            <div class="myDashboard-content">
                          
               
                <h3>Grievance Redressal Committee</h3>
                <div class="recent-orders-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Post</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. P. Nelson Raja</td>
                                <td>Principal</td>
                                <td>Chairperson</td>
                                </tr>
                            <tr>
                                <td>2</td>
                                <td>Mr. Muthu Raman</td>
                                <td>Legal Advisor</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Dr. S.Saravana Kumar</td>
                                <td>HoD/Automobile</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Mr. A. Athimoolam</td>
                                <td>HoD/S&H</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mr. K. Balamurugan</td>
                                <td>AP/EEE</td>
                                <td>Member</td>
                            </tr>
                            
                            <tr>
                                <td>6</td>
                                <td>Mr. TR.Mothilal</td>
                                <td>Lab Technician</td>   
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Ms. G.Periyanayagi</td>
                                <td>Non-Teaching</td>  
                                <td>Member</td> 
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Mr. M.Selvakumar</td>
                                <td>Student</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Mr. B.Nandakumar</td>
                                <td>Student</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Mr. T.G. Hariharan</td>
                                <td>Student</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
      </div>
      
    </div>
</div>
</div>
<!--Feeback Form-->
<div
    class="students-feedback-form-area ptb-100"
    [class.dark-students-feedback-form-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
       
            <div class="col-lg-12 col-md-12">
                <div class="students-feedback-form">
                    <h3>Grievance Redressal Committee Complaint Form</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your name*">
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Department*">
                                    <span class="label-title"><i class='bx bx-home'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Gender*">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Year of Study*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="State your Complaint*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Date on which Incident happened? *">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Write something here (Optional)"></textarea>
                                    <span class="label-title"><i class='bx bx-edit'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <p>I have rechecked the form and confirm that all the details are correct. I know if information is wrong I am liable for a punishable action.</p>
                                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>