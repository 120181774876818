<!--<section style="background-color: #fdfdfd;">

    <div class="section-title">
           <br><br>
  <h2 style="color:#FF6F61" style="font-size: 22px;">Our Recruiters</h2>
        
    </div>
<div 
    class="motivation-featured-area bg-with-black-color"
    [class.dark-motivation-featured-area]="themeService.isDark()"
>
    <div class="container-fluid" >
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                   
                    <img src="../../../../../assets/img/placement/12.png" >
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/2.png" >
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                   
                    <img src="../../../../../assets/img/placement/3.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/1.png" > 
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/5.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/6.png" > 
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/7.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/8.png" > 
                </div>
            </div>    
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/9.png" > 
                </div>
            </div>
      
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/10.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/11.png" > 
                </div>
            </div>
      
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/4.png" > 
                </div>
            </div>
      
      
   
        </div>
    </div>
</div>
</section>-->

<section class="recruiters-section">
    <div class="container">
        <h2 class="section-title">Our Recruiters</h2>
        <div class="logos-container">
            <div class="logo-item">
                
                <img src="../../../../../assets/img/placement/12.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/1.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/2.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/3.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/4.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/5.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/6.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/7.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/8.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/9.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/10.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/11.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/13.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/14.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/15.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/16.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/17.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/18.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/19.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/20.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/21.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/22.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/23.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/24.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/25.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/26.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/27.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
            <div class="logo-item">
                <img src="../../../../../assets/img/placement/28.png" alt="Recruiter 1" class="recruiter-logo">
            </div>
        </div>
    </div>
</section>
